import { FC } from 'react';

import { MultiSelect as MantineMultiSelect, MultiSelectProps } from '@mantine/core';

import styles from './MultiSelect.module.scss';

const MultiSelect: FC<MultiSelectProps> = (props) => (
  <MantineMultiSelect
    classNames={{
      input: styles.input,
      invalid: styles.invalid,
      item: styles.item,
      rightSection: styles.rightSection
    }}
    dropdownPosition="bottom"
    {...props}
  />
);

export default MultiSelect;
