import { FC, useState } from 'react';

import { IconChevronDown } from '@tabler/icons-react';
import { clsx } from 'clsx';
import AnimateHeight from 'react-animate-height';

import styles from './styles.module.scss';
import { TFormAccordionProps } from './types';

const FormAccordion: FC<TFormAccordionProps> = ({ children, title, isError }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className={styles.accordionWrapper}>
      <div
        className={clsx(styles.accordionButton, { [styles.error]: isError })}
        onClick={handleToggle}
      >
        <p className={styles.accordionButtonTitle}>{title}</p>
        <IconChevronDown className={clsx(styles.icon, { [styles.open]: isOpen })} />
      </div>
      <AnimateHeight height={isOpen ? 'auto' : 0} duration={500}>
        <div className={styles.accordionContentWrapper}>
          {children}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default FormAccordion;
