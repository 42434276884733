import { FC } from 'react';

import { Center, Loader } from '@mantine/core';

import { useCompetitionConditionsQuery } from 'src/api/competition-conditions/hooks';
import Container from 'src/components/Container';
import HTMLParser from 'src/components/HTMLParser';
import Title from 'src/components/Title';

import styles from './styles.module.scss';

const CompetitionConditions: FC = () => {
  const { data, isLoading } = useCompetitionConditionsQuery();

  return (
    <div className={styles.wrapper}>
      <Container>
        {isLoading && (
          <Center py={50}>
            <Loader size="lg" />
          </Center>
        )}
        {data && (
          <>
            <Title color="dark" spacing={70}>
              {data.title}
            </Title>
            <HTMLParser content={data.text} />
          </>
        )}
      </Container>
    </div>
  );
};

export default CompetitionConditions;
