import { TTab } from 'src/components/Tabs';
import i18n from 'src/configs/i18n';
import { Paths } from 'src/router/constants';

export const tabs: TTab[] = [
  {
    id: 'news',
    label: i18n.t('pages.mediaCenter.latestNews.title'),
    link: Paths.news
  },
  {
    id: 'photo-gallery',
    label: i18n.t('pages.mediaCenter.photoGallery.title'),
    link: Paths.photoGallery
  },
  {
    id: 'video-gallery',
    label: i18n.t('pages.mediaCenter.videoGallery.title'),
    link: Paths.videoGallery
  }
];
