import { FC, ReactNode, useMemo } from 'react';

import { createEmotionCache, MantineProvider as Provider } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import rtlPlugin from 'stylis-plugin-rtl';

type Props = {
  children: ReactNode;
};

const rtlCache = createEmotionCache({
  key: 'iami',
  stylisPlugins: [rtlPlugin],
  prepend: true
});

const cache = createEmotionCache({
  key: 'iami',
  prepend: true
});

const MantineProvider: FC<Props> = ({ children }) => {
  const { i18n: { language } } = useTranslation();

  const isRtl = useMemo(() => language === 'ar', [language]);

  return (
    <Provider
      emotionCache={isRtl ? rtlCache : cache}
      theme={{
        dir: isRtl ? 'rtl' : 'ltr',
        fontFamily: isRtl ? 'GE SS Two, sans-serif' : 'Inter, sans-serif',
        components: {
          Input: {
            styles: {
              wrapper: { borderRadius: 0, marginBottom: 0 },
              input: { borderRadius: 0, border: '1px solid #26495a', fontSize: 12 },
              invalid: { borderColor: '#a00000', color: '#000' }
            }
          },
          InputWrapper: {
            styles: {
              root: { lineHeight: 1 },
              label: { color: '#26495a', fontSize: 12, marginBottom: 8 },
              required: { color: '#a00000', verticalAlign: isRtl ? 'super' : 'unset' },
              error: { color: '#a00000', fontSize: 11, marginTop: 4 },
              description: { marginTop: 4, fontSize: 11 }
            }
          },
          LoadingOverlay: {
            defaultProps: {
              overlayBlur: 1
            }
          }
        },
        focusRingStyles: {
          inputStyles: () => ({
            outline: 'none'
          })
        },
        cursorType: 'pointer'
      }}
    >
      {children}
    </Provider>
  );
};

export default MantineProvider;
