import { useQuery } from '@tanstack/react-query';

import { getTermsAndConditions } from './api';

export const useTermsAndConditionsQuery = () => {
  return useQuery({
    queryKey: ['terms-and-conditions'],
    queryFn: async () => {
      const { data } = await getTermsAndConditions();
      return data.data;
    }
  });
};
