import { FC, useMemo } from 'react';

import { Image } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { News } from 'src/api/news/types';
import { ReactComponent as CalendarIcon } from 'src/assets/icons/calendar.svg';
import { Paths } from 'src/router/constants';

import styles from './styles.module.scss';

type Props = {
  news: News;
};

const NewsCard: FC<Props> = ({
  news
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const openNewsPage = () => {
    navigate(generatePath(Paths.newsDetails, { newsId: news.id }));
  };

  const newsDate = useMemo(() => {
    return new Date(news.publishing_date).toLocaleDateString();
  }, [news.publishing_date]);

  return (
    <div className={styles.wrapper} onClick={openNewsPage}>
      <Image
        src={news.image_path}
        className={styles.newsImage}
        width="100%"
        fit="cover"
        height={235}
        withPlaceholder
      />
      <div className={styles.contentWrapper}>
        <div className={styles.newsDateWrapper}>
          <p className={styles.newsDate}>{newsDate}</p>
          <CalendarIcon />
        </div>
        <h4 className={styles.newsTitle}>{news.summary}</h4>
        <p className={styles.readMoreButton}>
          {t('common.readMore')}
        </p>
      </div>
    </div>
  );
};

export default NewsCard;
