import { closeModal, openContextModal } from '@mantine/modals';

import { TTicket } from 'src/api/tickets/types';
import {
  CANCEL_TICKET_MODAL,
  FORM_ERROR_MODAL,
  LOGIN_MODAL,
  RATE_TICKET_MODAL,
  INNOVATION_PARTICIPANTS_MODAL,
  INNOVATION_CONTACT_MODAL
} from 'src/constants/modals';
import { TFormErrorModalProps } from 'src/providers/ModalsProvider/FormErrorModal';
import { LoginModalProps } from 'src/providers/ModalsProvider/LoginModal';

export const openLoginModal = (props?: LoginModalProps) => {
  openContextModal({
    modal: LOGIN_MODAL,
    innerProps: props || {},
    centered: true,
    withCloseButton: false,
    padding: 0,
    size: 360
  });
};

export const openRateTicketModal = (ticket: TTicket) => {
  openContextModal({
    modal: RATE_TICKET_MODAL,
    innerProps: { ticket },
    centered: true,
    withCloseButton: false,
    padding: 0,
    size: 630
  });
};

export const closeRateTicketModal = () => {
  closeModal(RATE_TICKET_MODAL);
};

export const openCancelTicketModal = (ticketId: string) => {
  openContextModal({
    modal: CANCEL_TICKET_MODAL,
    innerProps: { ticketId },
    centered: true,
    withCloseButton: false,
    padding: 0,
    size: 510
  });
};

export const closeCancelTicketModal = () => {
  closeModal(CANCEL_TICKET_MODAL);
};

export const openInnovationParticipantsModal = (innovationUsers: string[]) => {
  openContextModal({
    modal: INNOVATION_PARTICIPANTS_MODAL,
    innerProps: { innovationUsers },
    centered: true,
    withCloseButton: true,
    padding: 30,
    size: 600
  });
};

export const closeInnovationParticipantsModal = () => {
  closeModal(INNOVATION_PARTICIPANTS_MODAL);
};

export const openFormErrorModal = (props: TFormErrorModalProps) => {
  openContextModal({
    modal: FORM_ERROR_MODAL,
    innerProps: props,
    centered: true,
    withCloseButton: false,
    padding: 0,
    size: 300
  });
};

export const openInnovationContactModal = (item_id: string) => {
  openContextModal({
    modal: INNOVATION_CONTACT_MODAL,
    innerProps: { item_id },
    centered: true,
    withCloseButton: true,
    padding: 30,
    size: 600
  });
};

export const closeInnovationContactModal = () => {
  closeModal(INNOVATION_CONTACT_MODAL);
};
