import { FC } from 'react';

import { Modal,clsx } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import Navbar from 'src/components/Navbar';

import styles from './ModalPage.module.scss';
import { TModalPageProps } from './types';

const ModalPage: FC<TModalPageProps> = ({
  children,
  isOpen,
  onClose,
  isVideo
}) => {
  const isMobile =  useMediaQuery('(max-width: 600px)');
  const isTablet =  useMediaQuery('(min-width: 601px) and (max-width:1024px');
  return (
    <Modal
      opened={isOpen}
      onClose={onClose ? onClose : () => undefined}
      fullScreen
      withCloseButton={false}
      classNames={{
        modal: styles.modal,
        body: styles.body
      }}
    >
      <Navbar navbarVariant="light" position="fixed" />
      <div className={clsx(styles.content, 
        {
          [styles.isMobileVideo]: isVideo && isMobile,
          [styles.isTabletVideo]: isVideo && isTablet
        }
        )}>
        {children}
      </div>
    </Modal>
  );
};

export default ModalPage;
