import { FC } from 'react';

import { Box, Flex, Input, SimpleGrid, Stack, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Nationality } from 'src/api/nationalities/types';
import { participantDefaultValues } from 'src/components/CompetitionRegistrationForm/constants';
import ParticipantName from 'src/components/CompetitionRegistrationForm/Step2/ParticipantName';
import PercentageInput from 'src/components/PercentageInput';
import Select from 'src/components/Select';
import { getUserFullName } from 'src/helpers/user';
import { TCompetitionRegistrationForm } from 'src/modules/competition/types';
import { useAuthStore } from 'src/store/useAuthStore';

import ParticipantInfo from './ParticipantInfo';
import ParticipantPercentage from './ParticipantPercentage';
import TotalPercentage from './TotalPercentage';

type Props = {
  form: UseFormReturn<TCompetitionRegistrationForm>;
  nationalities: Nationality[];
  isDisabled: boolean;
};

const GroupForm: FC<Props> = ({
  form,
  nationalities,
  isDisabled
}) => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width:1024px');

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'partcipants_data'
  });

  const handleNumberChange = (amount: number) => {
    const oldVal = fields.length;
    if (amount > oldVal) {
      for (let i = oldVal; i < amount; i++) {
        append(participantDefaultValues);
      }
    } else {
      for (let i = oldVal; i > amount; i--) {
        remove(i - 1);
      }
    }
  };

  return (
    <>
      <SimpleGrid cols={isMobile ? 1 : 2} spacing={120} mb={40}>
        <Controller
          control={form.control}
          name="number_of_particepants"
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <Select
              value={value}
              onChange={(value) => {
                if (value) {
                  onChange(value);
                  handleNumberChange((+value) - 1);
                } else {
                  onChange(null);
                }
              }}
              onBlur={onBlur}
              error={error?.message}
              data={['2', '3', '4', '5', '6', '7', '8', '9', '10']}
              label={t('formLabels.participantsNumber')}
              placeholder={t('placeholders.participantsNumber')}
              required
              disabled={isDisabled}
            />
          )}
        />
      </SimpleGrid>

      {fields.length ? (
        <>
          <Box mb={40}>
            <Input.Label mb={6} required>
              {t('formLabels.participantsInformation')}
            </Input.Label>

            <Stack spacing={15}>
              {fields.map((field, index) => (
                <ParticipantInfo
                  key={field.id}
                  participantIndex={index}
                  form={form}
                  nationalities={nationalities}
                  isDisabled={isDisabled}
                />
              ))}
            </Stack>
          </Box>

          <Box mb={40}>
            <Input.Label mb={{ base: 15, lg: 6 }} required>
              {t('formLabels.participantPercentage')}
            </Input.Label>

            <Flex justify="space-between" align={isMobile ? 'end' : 'center'} mb={{ base: 10, lg: 20 }}>
              <Flex direction="column">
                {isMobile ? (
                  <Text align="start" fz={12}>
                    {t('placeholders.participantPercentage')}
                  </Text>
                ) : null}

                <ParticipantName>
                  {getUserFullName(user)}
                </ParticipantName>
              </Flex>

              <Controller
                control={form.control}
                name="participating_percentage"
                render={({
                  field: { value, onChange }
                }) => (
                  <Box pl={isTablet ? 20 : 0}>
                    <PercentageInput
                      label={t('placeholders.participantPercentage')}
                      withLabel={!isMobile}
                      value={value}
                      onChange={onChange}
                      disabled={isDisabled}
                    />
                  </Box>
                )}
              />
            </Flex>

            {fields.map((field, index) => (
              <ParticipantPercentage
                key={`participant_${index}_${field}`}
                form={form}
                participantIndex={index}
                isDisabled={isDisabled}
              />
            ))}

            <TotalPercentage form={form} />
          </Box>
        </>
      ) : null}
    </>
  );
};

export default GroupForm;
