import { FC } from 'react';

import { Box, Checkbox as MantineCheckbox, CheckboxProps, clsx } from '@mantine/core';
import { IconAsterisk } from '@tabler/icons-react';

import styles from './Checkbox.module.scss';

type Props = CheckboxProps & {
  isError?: boolean;
  required?: boolean;
};

const Checkbox: FC<Props> = ({
  isError,
  error,
  required,
  label,
  className,
  ...restProps
}) => (
  <Box pos="relative">
    <MantineCheckbox
      classNames={{
        input: clsx(styles.input, { [styles.error]: isError || error }),
        label: styles.label,
        body: styles.body
      }}
      size="xs"
      label={(
        <>
          {label}

          {required && (
            <Box display="inline-flex" pos="relative" left={3} top={-3}>
              <IconAsterisk color="#a00000" size={7} stroke={4} />
            </Box>
          )}
        </>
      )}
      {...restProps}
    />
  </Box>
);

export default Checkbox;
