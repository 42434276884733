import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'src/components/Button';
import Footer from 'src/components/Footer';
import Navbar from 'src/components/Navbar';
import { Paths } from 'src/router/constants';

import styles from './styles.module.scss';

const ErrorPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.errorPage}>
      <Navbar position="absolute" navbarVariant="transparent" />
      <div className={styles.contentWrapper}>
        <div className={styles.background}>
          <div className={styles.backgroundImage} />
          <div className={styles.backgroundFill} />
        </div>
        <div className={styles.content}>
          <h2 className={styles.title}>{t('pages.errorPage.title')}</h2>
          <p className={styles.description}>{t('pages.errorPage.description')}</p>
          <Link to={Paths.homepage}>
            <Button className={styles.button}>
              {t('pages.errorPage.goToHomepage')}
            </Button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ErrorPage;
