import { useQuery } from '@tanstack/react-query';

import { getSocialMediaAccounts } from './api';
import { TSocialMediaAccount } from './types';

export const useSocialMediaAccountsQuery = () => {
  return useQuery<TSocialMediaAccount[]>({
    queryKey: ['social-media-accounts'],
    queryFn: getSocialMediaAccounts
  });
};
