import { useQuery } from '@tanstack/react-query';

import { getPhotoGallery } from './api';
import { GetPhotoGalleryResponse } from './types';

export const PHOTO_GALLERY_QUERY_KEY = 'media_center_photo_gallery';

export const usePhotoGalleryQuery = () => {
  return useQuery<GetPhotoGalleryResponse>({
    queryKey: [PHOTO_GALLERY_QUERY_KEY],
    queryFn: getPhotoGallery
  });
};
