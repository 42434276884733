import { FC, ReactNode } from 'react';

import { LoadingOverlay } from '@mantine/core';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Form from 'src/components/Form';
import { useStepper } from 'src/hooks/useStepper';
import { TCompetition, TCompetitionRegistrationForm } from 'src/modules/competition/types';

import styles from './CompetitionRegistrationForm.module.scss';
import { competitionRegistrationSteps } from './constants';
import FormStepper from './FormStepper';

type Props = {
  form: UseFormReturn<TCompetitionRegistrationForm>;
  competition: TCompetition | null | undefined;
  isLoading?: boolean;
  isDisabled?: boolean;
  onSaveForm?: () => void;
  onSubmit?: () => void;
  submitText?: string;
  headerStartContent?: ReactNode;
};

const CompetitionRegistrationForm: FC<Props> = ({
  form,
  competition,
  isLoading = false,
  isDisabled = false,
  onSaveForm,
  onSubmit,
  submitText,
  headerStartContent
}) => {
  const { t } = useTranslation();
  const { activeStep, goToPrevious, goToNext, Component } = useStepper(competitionRegistrationSteps);

  return (
    <Form className={styles.wrapper}>
      <LoadingOverlay visible={isLoading} />

      {headerStartContent}

      {!isDisabled ? (
        <p className={styles.saveButton} onClick={onSaveForm}>
          {t('common.saveForm')}
        </p>
      ) : null}

      <h2 className={styles.title}>
        {t('pages.competitionRegistration.title')}
      </h2>

      <div className={styles.stepperWrapper}>
        <FormStepper activeStep={activeStep} form={form} />
      </div>
      <div className={styles.stepWrapper}>
        <Component
          goToPrevious={goToPrevious}
          goToNext={goToNext}
          form={form}
          isLoading={isLoading}
          onSubmit={onSubmit}
          competition={competition}
          isDisabled={isDisabled}
          submitText={submitText}
        />
      </div>
    </Form>
  );
};

export default CompetitionRegistrationForm;
