import { UserInterestedRegisterData, UserRegisterData } from 'src/api/user/types';

export const normalizeSignupData = (data: UserRegisterData): UserRegisterData => ({
  first_name: data.first_name.trim(),
  second_name: data.second_name.trim(),
  last_name: data.last_name.trim(),
  nationality_id: data.nationality_id,
  email: data.email,
  confirm_email: data.confirm_email,
  mobile_number: data.mobile_number,
  password: data.password,
  password_confirmation: data.password_confirmation,
  gender_id: data.gender_id,
  birthday: data.birthday,
  user_identity: data.user_identity
});

export const normalizeInterestedSignupData = (data: UserInterestedRegisterData): UserInterestedRegisterData => ({
  first_name: data.first_name.trim(),
  second_name: data.second_name.trim(),
  last_name: data.last_name.trim(),
  email: data.email,
  confirmed_email: data.confirmed_email,
  mobile_number: data.mobile_number,
  password: data.password,
  confirmed_password: data.confirmed_password,
  entity: data.entity,
  interests: data.interests,
  company_name: data.company_name.trim()
})
