import publicApi from 'src/configs/public-api';

import { GetVideoGalleryResponse } from './types';

export const getVideoGallery = async (page: number): Promise<GetVideoGalleryResponse> => {
  return publicApi.get('/video', {
    params: {
      page_size: 9,
      page_number: page
    }
  }).then((res) => res.data);
};

export const getVideoGalleryById = async (id: string): Promise<GetVideoGalleryResponse> => {
  return publicApi.get('/video', {
    params: { id }
  }).then((res) => res.data);
};
