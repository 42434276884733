import { FC } from 'react';

import { Center, Loader } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { useTermsAndConditionsQuery } from 'src/api/terms-and-conditions/hooks';
import Container from 'src/components/Container';
import HTMLParser from 'src/components/HTMLParser';
import Title from 'src/components/Title';

import styles from './styles.module.scss';

const TermsAndConditions: FC = () => {
  const { data, isLoading } = useTermsAndConditionsQuery();
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <div className={styles.wrapper}>
      <Container>
        {isLoading && (
          <Center py={50}>
            <Loader size="lg" />
          </Center>
        )}
        {data && (
          <>
            <Title color="dark" spacing={70} isMobile={isMobile}>
              {data.title}
            </Title>
            <HTMLParser content={data.text} />
          </>
        )}
      </Container>
    </div>
  );
};

export default TermsAndConditions;
