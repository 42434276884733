import { UseFormReturn } from 'react-hook-form';

import {
  DEFAULT_NUMBER_OF_APPLICATIONS,
  DEFAULT_NUMBER_OF_PARTICIPANTS,
  GROUP_TYPE_ID,
  INDIVIDUAL_TYPE_ID,
  participantDefaultValues
} from 'src/components/CompetitionRegistrationForm/constants';
import i18n from 'src/configs/i18n';
import { parseApiErrors } from 'src/helpers/forms';
import { openFormErrorModal } from 'src/helpers/modals';
import {
  TCompetition,
  TCompetitionParticipant,
  TCompetitionRegistration,
  TCompetitionRegistrationForm,
  TUserCompetitionInfo
} from 'src/modules/competition/types';
import { ErrorResponse } from 'src/types/api';

export const normalizeCompetitionRegistrationRequest = ({
  data,
  competitionId
}: {
  data: TCompetitionRegistrationForm;
  competitionId: string;
}): TCompetitionRegistration => {
  const number_of_participants = data.number_of_particepants ? (+data.number_of_particepants) - 1 : undefined;
  const number_of_applications = data.number_of_applications ? +data.number_of_applications : undefined;

  return {
    employer: data.employer || undefined,
    city_id: data.city_id ? +data.city_id : undefined,
    cv: data.cv,
    innovation_data: {
      innovation_name: data.innovation_data.innovation_name || undefined,
      innovation_type_id: data.innovation_data.innovation_type_id.map((item) => +item),
      competition_id: competitionId,
      path_id: data.innovation_data.path_id ? +data.innovation_data.path_id : undefined,
      description: data.innovation_data.description,
      innovation_attachment: data.innovation_data.innovation_attachment
    },
    participating_role_id: data.participating_role_id ? +data.participating_role_id : undefined,
    number_of_applications,
    application_data: data.application_data
      .map((item) => ({
        description: item.description,
        attachment: item.attachment,
        impact: item.impact,
        application_id: item.application_id,
        economic_impact: item.economic_impact,
        economic_impact_attachment: item.economic_impact_attachment,
        completness: item.completness,
        completness_attachment: item.completness_attachment,
        attached_video: item.attached_video
      }))
      .slice(0, number_of_applications),
    participating_type_id: data.participating_type_id ? +data.participating_type_id : undefined,
    participating_percentage: data.participating_type_id === INDIVIDUAL_TYPE_ID ? 100 : data.participating_percentage,
    number_of_particepants: data.participating_type_id === GROUP_TYPE_ID ? number_of_participants : undefined,
    partcipants_data: data.participating_type_id === GROUP_TYPE_ID
      ? data.partcipants_data
        .map((item) => ({
          first_name: item.first_name,
          second_name: item.second_name,
          last_name: item.last_name,
          nationality_id: item.nationality_id ? +item.nationality_id : undefined,
          user_identity: item.user_identity,
          email: item.email,
          participation_percentage: item.participation_percentage
        }))
        .slice(0, number_of_participants)
      : [],
    is_agreed_work_originality: data.is_agreed_work_originality,
    is_agreed_for_sharing_his_data: data.is_agreed_for_sharing_his_data,
    is_agreed_for_terms_and_conditions: data.is_agreed_for_terms_and_conditions,
    is_agreed_for_regukations_and_rules: data.is_agreed_for_regukations_and_rules
  };
};

export const normalizeUserCompetitionInfoForForm = (
  data: TUserCompetitionInfo
): TCompetitionRegistrationForm => ({
  employer: data.employer || '',
  city_id: data.city_id ? data.city_id.id.toString() : null,
  cv: data.cv || null,
  innovation_data: {
    innovation_name: data.innovation_data.innovation_name || '',
    innovation_type_id: data.innovation_data.innovation_type
      ? data.innovation_data.innovation_type.map((el) => el.id.toString())
      : [],
    path_id: data.innovation_data.path_id
      ? data.innovation_data.path_id.toString()
      : null,
    description: data.innovation_data.description || '',
    innovation_attachment: data.innovation_data.innovation_attachment || null
  },
  participating_role_id: data.participating_role_id?.id.toString() || null,
  participating_type_id: data.participating_type_id?.id.toString() || GROUP_TYPE_ID,
  participating_percentage: data.participating_percentage || 0,
  number_of_particepants: data.number_of_particepants
    ? (data.number_of_particepants + 1).toString()
    : DEFAULT_NUMBER_OF_PARTICIPANTS,
  partcipants_data: data.partcipants_data.length
    ? data.partcipants_data.map((data) => ({
      first_name: data.first_name || '',
      second_name: data.second_name || '',
      last_name: data.last_name || '',
      nationality_id: data.nationality_id?.id?.toString() || null,
      user_identity: data.user_identity || '',
      email: data.email || '',
      participation_percentage: data.participation_percentage || 0
    }))
    : [participantDefaultValues],
  total_percentage: 100,
  number_of_applications: data.number_of_applications
    ? data.number_of_applications.toString()
    : DEFAULT_NUMBER_OF_APPLICATIONS,
  application_data: data.application_data.length
    ? data.application_data.map((data) => ({
      description: data.description || '',
      attachment: data.attachment || null,
      impact: data.impact || '',
      application_id: data.application_id || undefined,
      economic_impact: data.economic_impact || '',
      economic_impact_attachment: data.economic_impact_attachment || null,
      completness: data.completness || '',
      completness_attachment: data.completness_attachment || null,
      attached_video: data.attached_video || null
    }))
    : [],
  is_agreed_work_originality: data.is_agreed_work_originality || false,
  is_agreed_for_sharing_his_data: data.is_agreed_for_sharing_his_data || false,
  is_agreed_for_terms_and_conditions: data.is_agreed_for_terms_and_conditions || false,
  is_agreed_for_regukations_and_rules: data.is_agreed_for_regukations_and_rules || false
});

export const getParticipantFullName = (
  participant: TCompetitionParticipant,
  participantNumber: number
) => {
  if (!participant.first_name && !participant.second_name && !participant.last_name) {
    return i18n.t('formTexts.participant', { number: participantNumber });
  }

  const str = `${participant.first_name} ${participant.second_name} ${participant.last_name}`;
  return str.trim();
};

export const handleApiErrorResponse = (
  error: ErrorResponse,
  form: UseFormReturn<TCompetitionRegistrationForm>
) => {
  form.clearErrors();
  window.scrollTo({ top: 0, behavior: 'smooth' });
  parseApiErrors(error, {
    form,
    onGlobalError: (message) => openFormErrorModal({ message })
  });
};

export const useCurrentCompetitionUserInfo = (
  competition: TCompetition | null | undefined,
  requests: TUserCompetitionInfo[] | undefined
) => {
  if (!competition?.id || !requests) {
    return;
  }

  const info = requests.find((userInfo) => userInfo.innovation_data?.competition_id === competition.id);

  return info || null;
};
