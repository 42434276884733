import { Dispatch, FC, SetStateAction, useState } from 'react';

import { Center, Flex, Group, Loader, Pagination, Table, Text,clsx } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { generatePath, useNavigate } from 'react-router-dom';

import { useGetTicketsQuery } from 'src/api/tickets/hooks';
import { TTicket } from 'src/api/tickets/types';
import Link from 'src/pages/Account/Link';
import Title from 'src/pages/Account/Title';
import { Paths } from 'src/router/constants';

import { TAccountSectionName, TAccountInterestedSectionName } from '../helpers';
import { getTicketOption, getTicketStatusBadge } from './helpers';
import styles from './styles.module.scss';
import { useAuthStore } from 'src/store/useAuthStore';

type Props = {
  setActiveSection: Dispatch<SetStateAction<TAccountSectionName>>;
  setActiveInterestedSection: Dispatch<SetStateAction<TAccountInterestedSectionName>>;
};

const SupportTickets: FC<Props> = ({ setActiveSection, setActiveInterestedSection }) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuthStore();

  const { data, isLoading, isSuccess } = useGetTicketsQuery({ page });

  const isEmpty = !data?.data.length;

  const handleItemClick = (ticket: TTicket) => {
    navigate(generatePath(Paths.ticketDetails, { id: ticket.id }));
  };

  return (
    <InView
      as="div"
      threshold={0.5}
      className={styles.wrapper}
      onChange={(inView) => inView && (
        user?.entity ? setActiveInterestedSection('technical-support') : setActiveSection('technical-support')
      )}
    >
      <Flex
        justify="space-between"
        mb={isEmpty ? 62 : 40}
        className={styles.headerWrapper}
      >
        <Title>{t('pages.account.technicalSupport')}</Title>
        <Link onCLick={() => navigate(Paths.contactUs)}>
          {t('pages.account.openNewTicket')}
        </Link>
      </Flex>
      {isLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {isSuccess && (
        <>
          {isEmpty ? (
            <p className={styles.emptyText}>
              {t('pages.account.ticketsEmpty')}
            </p>
          ) : (
            <>
              {!!isMobile ? (<Flex direction="column">
                {data?.data.map((ticket) => (
                  <Flex key={ticket.id} direction="column" className={clsx(styles.ticketCard, styles.ticketTable)}>
                    <Flex justify="space-between" mb={5}>
                      <Text className={styles.columnText}>{t('feature.tickets.ticketNumber')}</Text>
                      <Text>{ticket.ticket_number}</Text>
                    </Flex>
                    <Flex justify="space-between" mb={5}>
                      <Text className={styles.columnText}>{t('feature.tickets.title')}</Text>
                      <Text>{ticket.title}</Text>
                    </Flex>
                    <Flex justify="space-between" mb={5}>
                      <Text className={styles.columnText}>{t('common.status')}</Text>
                      <Text>{getTicketStatusBadge(ticket)}</Text>
                    </Flex>
                    <Flex justify="space-between" mb={5}>
                      <Text className={styles.columnText}>{t('common.options')}</Text>
                      {getTicketOption(ticket)}
                    </Flex>
                  </Flex>
                ))}
              </Flex>) :
                (<Table
                  className={styles.ticketTable}
                  verticalSpacing={14}
                  horizontalSpacing="sm"
                  fontSize={10}
                >
                  <thead>
                    <tr className={styles.headerRow}>
                      <th className={styles.ticketNumberColumn}>
                        {t('feature.tickets.ticketNumber')}
                      </th>
                      <th>
                        {t('feature.tickets.title')}
                      </th>
                      <th className={styles.centeredColumn}>
                        {t('common.status')}
                      </th>
                      <th className={styles.centeredColumn}>
                        {t('common.options')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data.map((ticket) => (
                      <tr className={styles.tableRow} key={ticket.id}>
                        <td className={styles.ticketNumberCell}>
                          <Group spacing={0}>
                            <Text display="flex" onClick={() => handleItemClick(ticket)} sx={{ cursor: 'pointer' }}>
                              <Text span className={styles.ticketNumberCellHashtag}>
                                #
                              </Text>
                              <Text>
                                {ticket.ticket_number}
                              </Text>
                            </Text>
                          </Group>
                        </td>
                        <td className={styles.titleColumn}>{ticket.title}</td>
                        <td
                          className={clsx(
                            styles.statusColumn,
                            styles.centeredColumn
                          )}
                        >
                          {getTicketStatusBadge(ticket)}
                        </td>
                        <td className={clsx(styles.ticketOptionColumn, styles.centeredColumn)}>
                          {getTicketOption(ticket)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>)
              }
              {(data.meta.hasNext || data.meta.hasPrevious) ? (
                <Center mt={30}>
                  <Pagination
                    page={page}
                    onChange={setPage}
                    total={data.meta.totalPages}
                    size="sm"
                  />
                </Center>
              ) : null}
            </>
          )}
        </>
      )}
    </InView>
  );
};

export default SupportTickets;
