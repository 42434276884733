import { ReactComponent as IconLinkedIn } from 'src/assets/icons/icon-linkedin.svg';
import { ReactComponent as IconTwitter } from 'src/assets/icons/icon-twitter.svg';
import { ReactComponent as IconYoutube } from 'src/assets/icons/icon-youtube.svg';
import { Flex } from '@mantine/core';
import styles from './styles.module.scss'

export const renderIconByType = (type: string) => {
  switch (type) {
    case 'LinkedIn':
      return <IconLinkedIn />;
    case 'X':
      return <Flex w={20} h={20} bg='#fff' p={2} justify='center' align='center' className={styles.xLogo}><IconTwitter /></Flex>;
    case 'Youtube':
      return <IconYoutube />;
    default:
      return null;
  }
};
