import { FC, useEffect, useMemo } from 'react';

import { Box, Center, Group, Image, Stack, Text } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import {
  useParticipatingApproveParticipatingMutation,
  useParticipatingSentOTPCodeMutation,
  useParticipatingVerifyOTPCodeMutation
} from 'src/api/participating/hooks';
import Logo from 'src/assets/svg/logo-column-colored.svg';
import Button from 'src/components/Button';
import Form from 'src/components/Form';
import TextInput from 'src/components/TextInput';
import { codeRegExp } from 'src/constants/regExp';

const ApproveParticipating: FC = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const searchData = useMemo(() => ({
    request_id: searchParams.get('request_id') || '',
    email: searchParams.get('email') || ''
  }), [searchParams]);

  const { mutate: sendOTP, error } = useParticipatingSentOTPCodeMutation();

  useEffect(() => {
    sendOTP(searchData);
  }, [sendOTP, searchData]);

  const {
    mutate: verifyOTP,
    isLoading: isVerifyOTPLoading,
    data: verifyOTPResponse
  } = useParticipatingVerifyOTPCodeMutation();

  const form = useForm({
    defaultValues: { Code: '' }
  });

  const handleSendOTP = (data: { Code: string }) => {
    verifyOTP({ ...searchData, ...data });
  };

  const {
    mutate: approveParticipating,
    isLoading: isApproveParticipatingLoading
  } = useParticipatingApproveParticipatingMutation();

  const handleApprove = (isApproved: boolean) => {
    approveParticipating({
      ...searchData,
      isAprroved: isApproved,
      approve_file: verifyOTPResponse?.data || ''
    });
  };

  return (
    <Box p={40}>
      <Center mb={20}>
        <Image
          src={Logo}
          width={320}
          height={180}
          fit="contain"
          alt="partner"
        />
      </Center>

      {error ? (
        <Center>
          <Text size={20}>
            {error?.status === 422 ? error.errors[0].error : 'Something went wrong'}
          </Text>
        </Center>
      ) : !verifyOTPResponse?.data ? (
        <Form onSubmit={form.handleSubmit(handleSendOTP)}>

          <Center>
            <Stack spacing={20}>
              <Text>
                {t('feature.requests.enterOTPCode')}
              </Text>

              <Stack spacing={30} align="flex-start">
                <Controller
                  control={form.control}
                  name="Code"
                  rules={{
                    required: t('errorMessages.required'),
                    pattern: { value: codeRegExp, message: t('errorMessages.code') }
                  }}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error }
                  }) => (
                    <TextInput
                      w={250}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      label={t('formLabels.verificationCode')}
                      placeholder={t('placeholders.code')}
                      type="email"
                      required
                      disabled={isVerifyOTPLoading}
                    />
                  )}
                />

                <Button type="submit" disabled={isVerifyOTPLoading}>
                  {t('common.send')}
                </Button>
              </Stack>
            </Stack>
          </Center>
        </Form>
      ) : (
        <Stack align="center" spacing={40}>

          <iframe
            src={verifyOTPResponse.data}
            title="PDF"
            allowFullScreen
            style={{ width: '100%', height: 600, border: 'none' }}
          />

          <Group spacing={40}>
            <Button
              variant="secondary"
              onClick={() => handleApprove(true)}
              disabled={isApproveParticipatingLoading}
            >
              {t('common.approve')}
            </Button>
            <Button
              variant="danger"
              onClick={() => handleApprove(false)}
              disabled={isApproveParticipatingLoading}
            >
              {t('common.reject')}
            </Button>
          </Group>
        </Stack>
      )}
    </Box>
  );
};

export default ApproveParticipating;
