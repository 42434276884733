import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/configs/query-client';
import { closeCancelTicketModal, closeRateTicketModal } from 'src/helpers/modals';
import { Paths } from 'src/router/constants';
import { router } from 'src/router/router';
import { ErrorResponse, ValidationErrorResponse } from 'src/types/api';

import {
  cancelTicket,
  getTicketById,
  getTickets,
  postTicket,
  rateTicket,
  updateTicket
} from './api';
import { TICKETS_QUERY_KEY } from './constants';
import {
  TCancelTicketRequest,
  TTicket,
  TTicketRequest,
  TTicketResponse,
  TUpdateTicketRequest,
  TRateTicketRequest,
  TRateTicketResponse, TGetTicketResponse
} from './types';

export const usePostTicketMutation = (args?: {
  onError?: (error: ErrorResponse) => void;
}) => {
  return useMutation<TTicketResponse, ErrorResponse, TTicketRequest>({
    mutationFn: postTicket,
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: [TICKETS_QUERY_KEY] });
      showNotification({
        message: response.message,
        color: 'teal',
        icon: <IconCheck size={20} />
      });
      router.navigate(Paths.account);
    },
    onError: args?.onError
  });
};

export const useGetTicketsQuery = (
  { page }: { page: number }
) => {
  return useQuery<TGetTicketResponse>({
    queryKey: [TICKETS_QUERY_KEY, page],
    queryFn: async () => getTickets(page)
  });
};

export const useGetTicketByIdQuery = (id: string) => {
  return useQuery<TTicket>({
    queryKey: [TICKETS_QUERY_KEY, id],
    queryFn: async () => {
      return getTicketById(id).then((res) => res.data[0]);
    }
  });
};

export const useUpdateTicketMutation = (args?: {
  onSuccess?: (response: TTicketResponse, data: TUpdateTicketRequest) => void;
  onError?: (error: ErrorResponse) => void;
}) => {
  return useMutation<TTicketResponse, ValidationErrorResponse, TUpdateTicketRequest>({
    mutationFn: async (data) => {
      return updateTicket(data);
    },
    onSuccess: async (response, data) => {
      await queryClient.invalidateQueries({ queryKey: [TICKETS_QUERY_KEY] });
      router.navigate(Paths.account);
      showNotification({
        message: response.message,
        color: 'teal',
        icon: <IconCheck size={20} />
      });

      if (args?.onSuccess) {
        args?.onSuccess(response, data);
      }
    },
    onError: args?.onError
  });
};

export const useCancelTicketMutation = () => {
  return useMutation<TTicketResponse, ErrorResponse, TCancelTicketRequest>({
    mutationFn: async ({ id }) => cancelTicket(id),
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: [TICKETS_QUERY_KEY] });
      showNotification({
        message: response.data,
        color: 'teal',
        icon: <IconCheck size={20} />
      });
      closeCancelTicketModal();
    }
  });
};

export const useRateTicketMutation = () => {
  return useMutation<TRateTicketResponse, ErrorResponse, TRateTicketRequest>({
    mutationFn: rateTicket,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [TICKETS_QUERY_KEY] });
      closeRateTicketModal();
    }
  });
};
