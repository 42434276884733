import { FC, useState } from 'react';

import { Flex, Group, Input, Radio, SimpleGrid, Stack, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from 'src/components/Button';
import Form from 'src/components/Form';
import PasswordInput from 'src/components/PasswordInput';
import TextInput from 'src/components/TextInput';
import {
  emailRegExp,
  familyNameRegExp,
  nameRegExp,
  passwordRegExp,
  phoneNumberRegExp
} from 'src/constants/regExp';
import FormError from 'src/pages/Signup/FormError';
import { Paths } from 'src/router/constants';

import styles from './styles.module.scss';
import { UserInterestedRegisterData } from 'src/api/user/types';
import RadioGroup from 'src/components/RadioGroup';
import { IconCheck, IconX } from '@tabler/icons-react';

type TProps = {
  interestedForm: UseFormReturn<UserInterestedRegisterData, any>;
  isLoading: boolean;
  handleInterestedSubmit: (navigateTo: Paths) => void;
};

const InterestedSignupForm: FC<TProps> = ({
  interestedForm,
  isLoading,
  handleInterestedSubmit
}) => {
  const { t } = useTranslation();
  const [companyName, setCompanyName] = useState<string | number>();
  const [interestedText, setInterestedText] = useState('');
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Form>
      <FormError control={interestedForm.control} />
      <div>

        <Stack spacing={isMobile ? 0 : 5}>
          <Input.Label required>
            {t('formLabels.fullName')}
          </Input.Label>

          <SimpleGrid cols={isMobile ? 1 : 3} spacing={isMobile ? 10 : 28} mb={{ base: 10, lg: 40 }}>
            <Controller
              control={interestedForm.control}
              name="first_name"
              rules={{
                required: t('errorMessages.required'),
                pattern: {
                  value: nameRegExp,
                  message: t('errorMessages.onlyName')
                }
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  placeholder={t('placeholders.firstName')}
                  required
                  disabled={isLoading}
                />
              )}
            />

            <Controller
              control={interestedForm.control}
              name="second_name"
              rules={{
                required: t('errorMessages.required'),
                pattern: {
                  value: nameRegExp,
                  message: t('errorMessages.onlyName')
                }
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  placeholder={t('placeholders.middleName')}
                  required
                  disabled={isLoading}
                />
              )}
            />

            <Controller
              control={interestedForm.control}
              name="last_name"
              rules={{
                required: t('errorMessages.required'),
                pattern: {
                  value: familyNameRegExp,
                  message: t('errorMessages.onlyName')
                }
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  placeholder={t('placeholders.lastName')}
                  required
                  disabled={isLoading}
                />
              )}
            />
          </SimpleGrid>
        </Stack>

        <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120}  mb={isMobile ? 20 : 40}>
          <Controller
            control={interestedForm.control}
            name="mobile_number"
            rules={{
              required: t('errorMessages.required'),
              pattern: {
                value: phoneNumberRegExp,
                message: t('errorMessages.invalidPhoneNumber')
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.phoneNumber')}
                placeholder={t('placeholders.phoneNumber')}
                type="tel"
                required
                disabled={isLoading}
              />
            )}
          />
        </SimpleGrid>

        <SimpleGrid cols={2} spacing={isMobile ? 10 : 120} mb={isMobile ? 20 : 40}>
          <Controller
            control={interestedForm.control}
            name="email"
            rules={{
              required: t('errorMessages.required'),
              pattern: {
                value: emailRegExp,
                message: t('errorMessages.invalidEmail')
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
              formState: { isSubmitted }
            }) => (
              <TextInput
                value={value}
                onChange={(value) => {
                  onChange(value);

                  if (isSubmitted && interestedForm.getValues('confirmed_email')) {
                    interestedForm.trigger('confirmed_email');
                  }
                }}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.email')}
                placeholder={t('placeholders.email')}
                required
                type="email"
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={interestedForm.control}
            name="confirmed_email"
            rules={{
              required: t('errorMessages.required'),
              validate: (value) => {
                if (interestedForm.getValues('email') !== value) {
                  return t('errorMessages.emailsNotMatch');
                }

                return true;
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.confirmEmail')}
                placeholder={t('placeholders.email')}
                required
                type="email"
                disabled={isLoading}
              />
            )}
          />
        </SimpleGrid>

        <SimpleGrid cols={2} spacing={isMobile ? 10 : 120} mb={isMobile ? 20 : 40}>
          <Controller
            control={interestedForm.control}
            name="password"
            rules={{
              required: t('errorMessages.required'),
              pattern: {
                value: passwordRegExp,
                message: t('errorMessages.invalidPassword')
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
              formState: { isSubmitted }
            }) => (
              <PasswordInput
                value={value}
                onChange={(value) => {
                  onChange(value);

                  if (isSubmitted && interestedForm.getValues('confirmed_password')) {
                    interestedForm.trigger('confirmed_password');
                  }
                }}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.password')}
                placeholder={t('placeholders.password')}
                required
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={interestedForm.control}
            name="confirmed_password"
            rules={{
              required: t('errorMessages.required'),
              validate: (value) => {
                if (interestedForm.getValues('password') !== value) {
                  return t('errorMessages.passwordsNotMatch');
                }

                return true;
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <PasswordInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.confirmPassword')}
                placeholder={t('placeholders.password')}
                required
                disabled={isLoading}
              />
            )}
          />
        </SimpleGrid>

        <Input.Label required>
          الإهتمامات
        </Input.Label>
        <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120}  mb={isMobile ? 20 : 40}>
          
          <Controller
            control={interestedForm.control}
            name="interests"
            rules={{
              required: t('errorMessages.required'),
              pattern: {
                value: nameRegExp,
                message: t('errorMessages.onlyName')
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <Flex direction='column' gap={12}>
                <Group pos='relative'>
                  <TextInput
                    value={interestedText}
                    onChange={(e) => setInterestedText(e.target.value)}
                    onBlur={onBlur}
                    error={error?.message}
                    placeholder={t('placeholders.interests')}
                    w='100%'
                    className={styles.interestedInput}
                    required
                    disabled={isLoading}
                  />
                  {interestedText.length && (<IconCheck
                    cursor='pointer'
                    className={styles.interestedIcon}
                    onClick={() => {
                      onChange([...value, interestedText]);
                      setInterestedText('');
                    }}
                  />)}
                </Group>

                <Flex gap={8} wrap='wrap'>
                  {interestedForm.getValues('interests').map((item: string, id: number) => (
                    <Flex key={id} className={styles.iterestedTags} align='center'>
                      <Text size={10}>{item}</Text>
                      <IconX
                        size={14}
                        cursor='pointer'
                        onClick={() => {
                          interestedForm.setValue(
                            'interests',
                            interestedForm
                              .getValues('interests')
                              .filter((el, i) => i !== id)
                          );
                        }}
                      />
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            )}
          />
        </SimpleGrid>

        <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120}  mb={isMobile ? 20 : 40}>
          <Controller
            control={interestedForm.control}
            name="entity"
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => {
              setCompanyName(value)

              return (
                <RadioGroup
                  value={value.toString()}
                  error={error?.message}
                  onChange={(e) => onChange(+e)}
                  label={t('formLabels.entity')}
                  required
                >
                  <Radio
                    value={'1'}
                    label={t('pages.signup.interested.individual')}
                  />
                  <Radio
                    value={'2'}
                    label={t('pages.signup.interested.company')}
                  />
                </RadioGroup>
              )
            }}
          />
        </SimpleGrid>

        {companyName == 2 && (
          <>
            <Input.Label required>
              اسم الشركة
            </Input.Label>
            <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120}  mb={isMobile ? 20 :40}>
              <Controller
                control={interestedForm.control}
                name="company_name"
                rules={{
                  required: t('errorMessages.required'),
                }}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error }
                }) => (
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error?.message}
                    placeholder={t('placeholders.companyName')}
                    required
                    disabled={isLoading}
                  />
                )}
              />
            </SimpleGrid>
          </>
        )}
      </div>
      <div className={styles.formActions}>
        <Button
          className={styles.submitButton}
          type="submit"
          variant="secondary"
          onClick={() => {
            interestedForm.setValue('entity', +interestedForm.getValues('entity'));
            handleInterestedSubmit(Paths.homepage);
          }}
          disabled={isLoading}
        >
          {t('pages.signup.buttons.registerAndReturnHome')}
        </Button>
      </div>
    </Form>
  );
};

export default InterestedSignupForm;
