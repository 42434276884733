import { FC, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useCurrentCompetitionQuery, useUserParticipatingRequestsQuery } from 'src/api/competition/hooks';
import CompetitionRegistrationForm, {
  competitionRegistrationFormDefaultValues,
  competitionRegistrationSchema
} from 'src/components/CompetitionRegistrationForm';
import { normalizeUserCompetitionInfoForForm, useCurrentCompetitionUserInfo } from 'src/helpers/competition';
import { isDateAfterCurrent } from 'src/helpers/dates';
import { RequestEvaluationStage, RequestStatus } from 'src/modules/competition/constants';
import { Paths } from 'src/router/constants';

import EvaluationStageForm from './EvaluationStageForm';
import InitialForm from './InitialForm';

const CompetitionRegistration: FC = () => {
  const navigate = useNavigate();
  const isTablet =  useMediaQuery('(min-width: 601px) and (max-width:1200px');

  const { data: competition, isLoading: isCurrentCompetitionLoading } = useCurrentCompetitionQuery();
  const { data: requests, isLoading: isUserInfoLoading } = useUserParticipatingRequestsQuery();
  const userRequestInfo = useCurrentCompetitionUserInfo(competition, requests);

  useEffect(() => {
    if (competition === null) {
      navigate(Paths.homepage);
    }

    if (competition) {
      const isExpired = isDateAfterCurrent(competition.end_date);

      if (userRequestInfo === null && isExpired) {
        navigate(Paths.homepage);
      }
    }
  }, [navigate, competition, userRequestInfo]);

  const form = useForm({
    defaultValues: competitionRegistrationFormDefaultValues,
    resolver: yupResolver(competitionRegistrationSchema)
  });

  useEffect(() => {
    if (userRequestInfo) {
      const normalized = normalizeUserCompetitionInfoForForm(userRequestInfo);

      form.reset(normalized);
    }
  }, [form, userRequestInfo]);

  const isInitialForm = useMemo(() => {
    if (!competition || userRequestInfo === undefined) {
      return false;
    }

    if (userRequestInfo === null) {
      return true;
    }

    return (
      userRequestInfo.evaluation_stage?.id === RequestEvaluationStage.New
      || userRequestInfo.status?.id === RequestStatus.New
      || userRequestInfo.status?.id === RequestStatus.Submitted
    );
  }, [competition, userRequestInfo]);

  const isFiltrationStage = useMemo(() => {
    if (!competition || !userRequestInfo) {
      return false;
    }

    return (
      userRequestInfo.evaluation_stage?.id === RequestEvaluationStage.Filtering
      || userRequestInfo.evaluation_stage?.id === RequestEvaluationStage.Authorizing
      || userRequestInfo.evaluation_stage?.id === RequestEvaluationStage.Approval
      || userRequestInfo.evaluation_stage?.id === RequestEvaluationStage.Arbitration
    );
  }, [competition, userRequestInfo]);

  const isLoading = isCurrentCompetitionLoading || isUserInfoLoading;

  return (
    <Box px={{ lg:200 }} pt={40} pb={70} pl={isTablet ? 50 : 0} pr={isTablet ? 50 : 0}>
      {isInitialForm ? (
        <InitialForm
          competition={competition!}
          userRequestInfo={userRequestInfo}
          form={form}
          isInfoLoading={isLoading}
        />
      ) : isFiltrationStage ? (
        <EvaluationStageForm
          competition={competition!}
          userRequestInfo={userRequestInfo!}
          form={form}
          isInfoLoading={isLoading}
        />
      ) : (
        <CompetitionRegistrationForm
          form={form}
          competition={competition}
          isDisabled
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};

export default CompetitionRegistration;
