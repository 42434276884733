import { useQuery } from '@tanstack/react-query';

import { getAllWinnerList, getTopWinnerList, getWinnerListByYear } from './api';
import { FullWinnersInfo, TopWinnersPayload } from './types';

export const useTopWinnerListQuery = () => {
  return useQuery<TopWinnersPayload[]>({
    queryKey: ['winner-list'],
    queryFn: getTopWinnerList
  });
};

export const useAllWinnerListQuery = () => {
  return useQuery<FullWinnersInfo>({
    queryKey: ['all-winner-list'],
    queryFn: getAllWinnerList
  });
};

export const useWinnerListByYearQuery = (year: string) => {
  return useQuery<FullWinnersInfo>({
    queryKey: ['winner-list-by-year'],
    queryFn: () => getWinnerListByYear(year),
    onError: () => console.error('something went wrong')
  });
};
