import { useQuery } from '@tanstack/react-query';

import { getAboutPrize } from './api';
import { TAboutPrize } from './types';

export const useAboutPrizeQuery = () => {
  return useQuery<TAboutPrize>({
    queryKey: ['about-prize'],
    queryFn: async () => getAboutPrize().then((res) => res.data)
  });
};
