import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { I18N_LANG_TOKEN } from 'src/constants/i18n';
import translationAr from 'src/locales/ar/translation.json';
import translationEn from 'src/locales/en/translation.json';

const resources = {
  en: {
    translation: translationEn
  },
  ar: {
    translation: translationAr
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem(I18N_LANG_TOKEN) || 'ar',
    fallbackLng: localStorage.getItem(I18N_LANG_TOKEN) || 'ar',
    debug: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
