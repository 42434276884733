import { FC } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { Paths } from 'src/router/constants';
import { useAuthStore } from 'src/store/useAuthStore';

const NoAuthWrapper: FC = () => {
  const { user } = useAuthStore();

  if (user) {
    return <Navigate to={Paths.homepage} replace />;
  }

  return <Outlet />;
};

export default NoAuthWrapper;
