import { Flex, Group, Text } from '@mantine/core';
import { IconTrophy, IconUsers } from '@tabler/icons-react';
import { FC, useEffect } from 'react';

import styles from '../WinnersData/styles.module.scss';
import { useTranslation } from 'react-i18next';
import { WinnersInfo } from 'src/api/arbitrating/types';
import { router } from 'src/router/router';

type TProps = {
  item: WinnersInfo;
  productRef?: any;
}

const WinnersPageItem:FC<TProps> = ({ item, productRef }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!router.state.location.hash.substring(1)) {
      localStorage.removeItem('searchedText');
    }
  }, [])

  const highlightText = (text: string) => {
    if (localStorage.getItem('searchedText')) {
      return (
        <Text>
          {text.toLowerCase().split(new RegExp(`(${localStorage.getItem('searchedText')?.toLowerCase()})`, 'gi')).map((part: string, index: number) => 
          part.toLowerCase() === localStorage.getItem('searchedText')?.toLowerCase() ? 
            <span key={index} style={{background: '#1E93CD99'}}>{part}</span> : part
          )}
        </Text>
      )
    }
  };

  return (
    <Group
      className={styles.item}
      ref={item.request_id === router.state.location.hash.substring(1) ? productRef : null}
    >
      <h3 className={styles.white}>{item.rankInWord}</h3>

      <Flex gap={36} direction='row' className={styles.content}>
        <Flex gap={16} direction='column'>
          <div className={styles.participants}>
            <IconUsers size={24} color='#52BB97' fill='#52BB97' />
            <h3 className={styles.green}>{t('pages.winner.participantsName')}</h3>
          </div>
          <Flex direction='column' gap={12}>
            {item.participantsNames.map((el, id) => (
              <div className={styles.names} key={id}>
                <p>{item.request_id === router.state.location.hash.substring(1) ? highlightText(el) : el}</p>
              </div>
            ))}
          </Flex>
        </Flex>

        <Flex className={styles.contentData} justify='space-between' direction='column' gap={24}>

          <div>
            <Flex justify='space-between'>
              <h3 className={styles.green}>{item.request_id === router.state.location.hash.substring(1) ? highlightText(item.innovationName_ar) : item.innovationName_ar}</h3>
              <Flex
                className={`
                  ${styles.category}
                  ${item.category.id === 1 && styles.gold}
                  ${item.category.id === 2 && styles.silver}
                  ${item.category.id === 3 && styles.bronze}
                  ${item.category.id === 4 && styles.motivational}
                  ${item.category.id === 5 && styles.notassigned}
                `}
                gap={8}
                align='center'
              >
                <IconTrophy size={16}  />
                <p>{item.request_id === router.state.location.hash.substring(1) ? highlightText(item.category.name) : item.category.name}</p>
              </Flex>
            </Flex>
            <h3 className={styles.defaultTitle}>{item.request_id === router.state.location.hash.substring(1) ? highlightText(item.competitionPath) : item.competitionPath}</h3>
          </div>

          <div>
            <h3 className={`${styles.blue} ${styles.brief}`}>{t('pages.winner.brief')}</h3>
            <p className={styles.briefText}>{item.request_id === router.state.location.hash.substring(1) ? highlightText(item.briefOnInnovation) : item.briefOnInnovation}</p>
          </div>

        </Flex>
      </Flex>
      
    </Group>
  );
};

export default WinnersPageItem;
