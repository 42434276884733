import { useQuery } from '@tanstack/react-query';

import { TRegulations } from 'src/modules/regulations/types';

import { getRegulations } from './api';
import { REGULATIONS_QUERY_KEY } from './constants';

export const useRegulationsQuery = () => {
  return useQuery<TRegulations>({
    queryKey: [REGULATIONS_QUERY_KEY],
    queryFn: getRegulations
  });
};
