import { FC } from 'react';

import { Box } from '@mantine/core';
import { MIME_TYPES } from '@mantine/dropzone';
import { Controller, UseFormReturn, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FileUpload from 'src/components/FileUpload';
import FormAccordion from 'src/components/FormAccordion';
import Textarea from 'src/components/Textarea';
import { TCompetitionRegistrationForm } from 'src/modules/competition/types';

type Props = {
  applicationIndex: number;
  form: UseFormReturn<TCompetitionRegistrationForm>;
  isDisabled: boolean;
};

const ApplicationInfo: FC<Props> = ({
  applicationIndex,
  form,
  isDisabled
}) => {
  const { t } = useTranslation();

  const formState = useFormState({
    control: form.control
  });

  const applicationState = form.getFieldState(
    `application_data.${applicationIndex}`,
    formState
  );

  return (
    <FormAccordion
      title={t('formTexts.applicationData', { number: applicationIndex + 1 })}
      isError={!!applicationState.error}
    >
      <Box mb={40}>
        <Controller
          control={form.control}
          name={`application_data.${applicationIndex}.description`}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <Textarea
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              label={t('formLabels.applicationDescription')}
              placeholder={t('placeholders.applicationDescription')}
              required
              rows={4}
              disabled={isDisabled}
              info={t('feature.requests.helperInfo.applicationDescription')}
            />
          )}
        />
      </Box>

      <Box mb={40}>
        <Controller
          control={form.control}
          name={`application_data.${applicationIndex}.impact`}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <Textarea
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              label={t('formLabels.directImpactOfTheApplication')}
              placeholder={t('placeholders.directImpactOfTheApplication')}
              required
              rows={4}
              disabled={isDisabled}
              info={t('feature.requests.helperInfo.applicationDirectImpact')}
            />
          )}
        />
      </Box>

      <Box mb={40}>
        <Controller
          control={form.control}
          name={`application_data.${applicationIndex}.attachment`}
          rules={{ required: t('errorMessages.required') }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <FileUpload
              value={value}
              onChange={onChange}
              label={t('formLabels.innovationAttachment')}
              required
              isError={!!error}
              helperText={t('formTexts.allowedFormat', { format: '(PDF, PPT)', size: '25MB' })}
              accept={[MIME_TYPES.pdf, MIME_TYPES.ppt, MIME_TYPES.pptx]}
              maxSize={25 * 1000 * 1000}
              disabled={isDisabled}
            />
          )}
        />
      </Box>

      <Box mb={40}>
        <Controller
          control={form.control}
          name={`application_data.${applicationIndex}.economic_impact`}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <Textarea
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              label={t('formLabels.innovationEconomicImpact')}
              placeholder={t('placeholders.innovationEconomicImpact')}
              required
              rows={4}
              disabled={isDisabled}
              info={t('feature.requests.helperInfo.applicationEconomicImpact')}
            />
          )}
        />
      </Box>

      <Box mb={40}>
        <Controller
          control={form.control}
          name={`application_data.${applicationIndex}.economic_impact_attachment`}
          rules={{ required: t('errorMessages.required') }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <FileUpload
              value={value}
              onChange={onChange}
              label={t('formLabels.innovationAttachment')}
              required
              isError={!!error}
              helperText={t('formTexts.allowedFormat', { format: '(PDF, PPT)', size: '25MB' })}
              accept={[MIME_TYPES.pdf, MIME_TYPES.ppt, MIME_TYPES.pptx]}
              maxSize={25 * 1000 * 1000}
              disabled={isDisabled}
            />
          )}
        />
      </Box>

      <Box mb={40}>
        <Controller
          control={form.control}
          name={`application_data.${applicationIndex}.completness`}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <Textarea
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              label={t('formLabels.innovationCompleteness')}
              placeholder={t('placeholders.innovationCompleteness')}
              required
              rows={4}
              disabled={isDisabled}
              info={t('feature.requests.helperInfo.applicationInnovationCompleteness')}
            />
          )}
        />
      </Box>

      <Box mb={40}>
        <Controller
          control={form.control}
          name={`application_data.${applicationIndex}.completness_attachment`}
          rules={{ required: t('errorMessages.required') }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <FileUpload
              value={value}
              onChange={onChange}
              label={t('formLabels.innovationAttachment')}
              required
              isError={!!error}
              helperText={t('formTexts.allowedFormat', { format: '(PDF, PPT)', size: '25MB' })}
              accept={[MIME_TYPES.pdf, MIME_TYPES.ppt, MIME_TYPES.pptx]}
              maxSize={25 * 1000 * 1000}
              disabled={isDisabled}
            />
          )}
        />
      </Box>

      <Box>
        <Controller
          control={form.control}
          name={`application_data.${applicationIndex}.attached_video`}
          rules={{ required: t('errorMessages.required') }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <FileUpload
              value={value}
              onChange={onChange}
              label={t('formLabels.attachVideoOfInnovation')}
              isError={!!error}
              helperText={t('formTexts.allowedFormat', { format: '(MP4)', size: '250MB' })}
              accept={[MIME_TYPES.mp4]}
              maxSize={250 * 1000 * 1000}
              disabled={isDisabled}
            />
          )}
        />
      </Box>
    </FormAccordion>
  );
};

export default ApplicationInfo;
