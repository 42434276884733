import privateApi from "src/configs/private-api";
import { TInterestedUser, TInterestedUserContact } from "./types";
import publicApi from "src/configs/public-api";
import { UserInterestedRegisterData } from "../user/types";
import { SuccessResponse } from "src/types/api";

export const userInterestedRegister = async (data: UserInterestedRegisterData): Promise<SuccessResponse> => {
  return publicApi.post('/interested-user', data).then((res) => res.data);
};

export const updateInterestedUser = async (data: TInterestedUser): Promise<SuccessResponse> => {
  return privateApi.put('/interested-user', data).then((res) => res.data);
};

export const userInterestedContact = async (data: TInterestedUserContact): Promise<SuccessResponse> => {
  return privateApi.post('/interested-user/contact-participant', data).then((res) => res.data);
};
