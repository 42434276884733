import { Box, LoadingOverlay } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { usePostTicketMutation } from 'src/api/tickets/hooks';
import { TTicketForm } from 'src/api/tickets/types';
import LinesBackground from 'src/components/LinesBackground';
import ManageTicket from 'src/components/ManageTicket';
import Title from 'src/components/Title';
import { parseApiErrors } from 'src/helpers/forms';
import { openFormErrorModal } from 'src/helpers/modals';
import { normalizeTicket } from 'src/helpers/ticket';

import styles from './styles.module.scss';

const ContactUs = () => {
  const { t } = useTranslation();
  const isTablet =  useMediaQuery('(min-width: 601px) and (max-width:1024px');

  const form = useForm<TTicketForm>({
    defaultValues: {
      ticketType_id: null,
      title: '',
      description: '',
      attachemt: null
    }
  });

  const { isLoading, mutate } = usePostTicketMutation({
    onError: (error) => parseApiErrors(error, {
      form,
      onGlobalError: (message) => openFormErrorModal({ message })
    })
  });

  const onSubmit: SubmitHandler<TTicketForm> = (data) => {
    const postTicketData = normalizeTicket(data);
    mutate(postTicketData);
  };

  return (
    <LinesBackground>
      <Box w={isTablet ? '100%' : { lg: 700 }} pr={isTablet ? 50 : 0}  pl={isTablet ? 50 : 0}>
        <Box mb={30}>
          <Title color="dark" fontSize="small" underlineTopSpacing={10}>
            {t('pages.contactUs.title')}
          </Title>
        </Box>
        <Box className={styles.contactUsBox} pos="relative">
          <LoadingOverlay visible={isLoading} />

          <ManageTicket
            form={form}
            onSubmit={onSubmit}
            disabled={isLoading}
            submitText={t('common.sendSuggestion')}
          />
        </Box>
      </Box>
    </LinesBackground>
  );
};

export default ContactUs;
