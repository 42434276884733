import { FC, ReactNode } from 'react';

import { Box, LoadingOverlay, ActionIcon } from '@mantine/core';
import { clsx } from 'clsx';

import { ReactComponent as CloseIcon } from 'src/assets/svg/close-icon.svg';

import styles from './ModalWrapper.module.scss';

type Props = {
  children: ReactNode;
  onClose: () => void;
  classNames?: {
    wrapper?: string;
    content?: string;
  };
  closeIconPosition?: {
    top?: number;
    end?: number;
  };
  loading?: boolean;
};

const ModalWrapper: FC<Props> = ({
  children,
  classNames,
  onClose,
  closeIconPosition,
  loading = false
}) => (
  <Box pos="relative">
    <LoadingOverlay visible={loading} />

    <div className={clsx(styles.wrapper, classNames?.wrapper)}>
      <ActionIcon
        pos="absolute"
        right={closeIconPosition?.end || 15}
        top={closeIconPosition?.top || 15}
        onClick={onClose}
      >
        <CloseIcon />
      </ActionIcon>
      <div className={clsx(styles.content, classNames?.content)}>
        {children}
      </div>
    </div>
  </Box>
);

export default ModalWrapper;
