import i18n from 'src/configs/i18n';

export type TAccountSectionName = 'personal-info' | 'competition-status' | 'technical-support';
export type TAccountInterestedSectionName = 'personal-info' | 'technical-support';

type TAccountSection = {
  id: TAccountSectionName;
  title: string;
};

type TAccountInterestedSection = {
  id: TAccountInterestedSectionName;
  title: string;
};

export const accountSections: TAccountSection[] = [
  {
    id: 'personal-info',
    title: i18n.t('pages.account.personalInfo')
  },
  {
    id: 'competition-status',
    title: i18n.t('pages.account.competitionStatus')
  },
  {
    id: 'technical-support',
    title: i18n.t('pages.account.technicalSupport')
  }
];

export const accountInterestedSections: TAccountInterestedSection[] = [
  {
    id: 'personal-info',
    title: i18n.t('pages.account.personalInfo')
  },
  {
    id: 'technical-support',
    title: i18n.t('pages.account.technicalSupport')
  }
];
