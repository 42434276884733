import { useQuery } from '@tanstack/react-query';

import { getCompetitionConditionsInfo } from './api';

export const useCompetitionConditionsQuery = () => {
  return useQuery({
    queryKey: ['competition-conditions'],
    queryFn: getCompetitionConditionsInfo
  });
};
