import { FC } from 'react';

import { clsx } from 'clsx';
import { Outlet } from 'react-router-dom';

import { useCurrentCompetitionQuery } from 'src/api/competition/hooks';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import TimerBanner from 'src/components/TimerBanner';

import styles from './styles.module.scss';

const TimerLayout: FC = () => {
  const { data: currentCompetition } = useCurrentCompetitionQuery();

  return (
    <div className={styles.wrapper}>
      <Header navbarCheckpoint={525} />
      <div className={styles.bannerWrapper}>
        <TimerBanner competition={currentCompetition} />
      </div>
      <div className={clsx(styles.content, { [styles.contentWithoutTimer]: !currentCompetition })}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default TimerLayout;
