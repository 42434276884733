import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import queryClient from 'src/configs/query-client';

import { getVideoGallery, getVideoGalleryById } from './api';
import { GetVideoGalleryResponse, VideoGallery } from './types';

export const VIDEO_GALLERY_QUERY_KEY = 'media_center_video_gallery';
export const VIDEO_GALLERY_DETAILS_QUERY_KEY = 'media_center_video_gallery_details';

export const useVideoGalleryQuery = (
  { page }: { page: number }
) => {
  return useQuery<GetVideoGalleryResponse>({
    queryKey: [VIDEO_GALLERY_QUERY_KEY, page],
    queryFn: () => getVideoGallery(page),
    keepPreviousData: true,
    staleTime: 5000
  });
};

export const usePrefetchNextVideoGalleryPage = (
  data: GetVideoGalleryResponse | undefined,
  isPreviousData: boolean,
  page: number
) => {
  useEffect(() => {
    if (!isPreviousData && data?.meta.hasNext) {
      queryClient.prefetchQuery({
        queryKey: [VIDEO_GALLERY_QUERY_KEY, page + 1],
        queryFn: () => getVideoGallery(page + 1)
      });
    }
  }, [data, isPreviousData, page]);
};

export const useVideoGalleryItemQuery = (id: string) => {
  return useQuery<VideoGallery | null>({
    queryKey: [VIDEO_GALLERY_DETAILS_QUERY_KEY, id],
    queryFn: async () => {
      const response = await getVideoGalleryById(id);
      const item = response.data[0];

      return item || null;
    }
  });
};
