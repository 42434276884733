import { FC, useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

export type TStepperConfig<StepProps> = Array<{
  component: FC<StepProps>;
}>;

export function useStepper<StepProps>(steps: TStepperConfig<StepProps>) {
  const { state, pathname } = useLocation();
  const { activeStep } = state || { activeStep: 1 };
  const navigate = useNavigate();

  const goToPrevious = () => {
    if (activeStep > 1) {
      navigate(pathname, { state: { activeStep: activeStep - 1 } });
    }
  };

  const goToNext = () => {
    if (activeStep < steps.length) {
      navigate(pathname, { state: { activeStep: activeStep + 1 } });
    }
  };

  const Component = useMemo(() => {
    return steps[activeStep - 1].component;
  }, [steps, activeStep]);

  return {
    activeStep,
    goToPrevious,
    goToNext,
    Component
  };
}
