import { FC, ReactNode } from 'react';

import { ModalsProvider as Provider } from '@mantine/modals';

import {
  CANCEL_TICKET_MODAL,
  FORM_ERROR_MODAL,
  INNOVATION_PARTICIPANTS_MODAL,
  LOGIN_MODAL,
  RATE_TICKET_MODAL,
  INNOVATION_CONTACT_MODAL
} from 'src/constants/modals';

import CancelTicketModal from './CancelTicketModal';
import FormErrorModal from './FormErrorModal';
import LoginModal from './LoginModal';
import RateTicketModal from './RateTicketModal';
import InnovationPatricipantsModal from './InnovationPatricipantsModal';
import InnovationContactModal from './InnovationContactModal';

type Props = {
  children: ReactNode;
};

const ModalsManager: FC<Props> = ({ children }) => (
  <Provider
    modals={{
      [LOGIN_MODAL]: LoginModal,
      [RATE_TICKET_MODAL]: RateTicketModal,
      [CANCEL_TICKET_MODAL]: CancelTicketModal,
      [FORM_ERROR_MODAL]: FormErrorModal,
      [INNOVATION_PARTICIPANTS_MODAL]: InnovationPatricipantsModal,
      [INNOVATION_CONTACT_MODAL]: InnovationContactModal,
    }}
    modalProps={{
      exitTransitionDuration: 300,
      overlayBlur: 0.4,
      styles: {
        header: { position: 'relative', height: 28 },
        title: { width: '100%', textAlign: 'center', marginRight: 0, fontWeight: 500 },
        close: { position: 'absolute', right: 0, top: 0 }
      }
    }}
  >
    {children}
  </Provider>
);

export default ModalsManager;
