import { useQuery } from '@tanstack/react-query';

import { getInnovationTypes } from './api';
import { InnovationType } from './types';

export const useInnovationTypesQuery = () => {
  return useQuery<InnovationType[]>({
    queryKey: ['innovation-types'],
    queryFn: async () => getInnovationTypes().then((res) => res.data)
  });
};
