import { Button, Flex, SimpleGrid, TextInput, Text, Textarea } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useUserInterestedContact } from "src/api/interested-profile/hooks";
import Form from "src/components/Form";
import { phoneNumberRegExp } from "src/constants/regExp";
import { useAuthStore } from "src/store/useAuthStore";

export type TInnovationContactModalProps = {
  item_id: string;
};

const InnovationContactModal: FC<ContextModalProps<TInnovationContactModalProps>> = ({
  context,
  id,
  innerProps: { item_id }
}) => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const { mutate: sendMessage } = useUserInterestedContact();

  const form = useForm<any>({
    defaultValues: {
      entity_name: '',
      mobile_number: user?.mobile_number,
      email: user?.email,
      contact_purpose: ''
    }
  });

  const handleSubmit = () => {
    form.handleSubmit(async (data) => {
      await sendMessage({innovation_request_id: item_id, ...data});
    })();
    context.closeModal(id);
  };

  return (
    <Form>
      <Text mb={24} pos='absolute' top={30} color='blue'>{t('modals.innovationContact.contactViaParticipants')}</Text>

      <Flex direction="column" mb={24}>
        <Controller
          control={form.control}
          name="entity_name"
          rules={{
            required: t('errorMessages.required'),
          }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <TextInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              label={t('modals.innovationContact.entityName')}
              placeholder={t('modals.innovationContact.entityName')}
            />
          )}
        />
      </Flex>

      <SimpleGrid cols={2} spacing={120} mb={24}>
        <Controller
          control={form.control}
          name="email"
          rules={{
            required: t('errorMessages.required'),
          }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <TextInput
              value={value}
              onChange={(value) => onChange(value)}
              onBlur={onBlur}
              error={error?.message}
              label={t('modals.innovationContact.email')}
              placeholder={t('modals.innovationContact.email')}
              type="email"
              disabled
            />
          )}
        />

        <Controller
          control={form.control}
          name="mobile_number"
          rules={{
            required: t('errorMessages.required'),
            pattern: {
              value: phoneNumberRegExp,
              message: t('errorMessages.invalidPhoneNumber')
            }
          }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <TextInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              label={t('modals.innovationContact.mobile')}
              placeholder={t('placeholders.phoneNumber')}
              type="tel"
            />
          )}
        />
      </SimpleGrid>

      <Flex direction="column" mb={24}>
        <Controller
          control={form.control}
          name="contact_purpose"
          rules={{
            required: t('errorMessages.required'),
          }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <Textarea
              value={value}
              minRows={5}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              label={t('modals.innovationContact.contactPurpose')}
              placeholder={t('modals.innovationContact.purposeHere')}
            />
          )}
        />
      </Flex>

      <Flex justify='flex-end'>
        <Button onClick={handleSubmit}>{t('common.send')}</Button>
      </Flex>
    </Form>
  )
};

export default InnovationContactModal;
