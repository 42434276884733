import { FC } from 'react';

import { Box, Flex, Input, LoadingOverlay, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useNationalitiesQuery } from 'src/api/nationalities/hooks';
import { useParticipatingTypesQuery } from 'src/api/participating-types/hooks';
import Button from 'src/components/Button';
import { StepProps } from 'src/components/CompetitionRegistrationForm/types';
import PercentageInput from 'src/components/PercentageInput';
import Radio from 'src/components/Radio';
import RadioGroup from 'src/components/RadioGroup';
import { getUserFullName } from 'src/helpers/user';
import { useNormalizedItemsData } from 'src/hooks/useNormalizedItemsData';
import { useAuthStore } from 'src/store/useAuthStore';

import GroupForm from './GroupForm';
import ParticipantName from './ParticipantName';

const Step2: FC<StepProps> = ({
  goToPrevious,
  goToNext,
  form,
  isLoading,
  isDisabled
}) => {
  const { t } = useTranslation();
  const { user } = useAuthStore();

  const participantType = useWatch({
    control: form.control,
    name: 'participating_type_id'
  });

  const { data: participatingTypes, isLoading: isParticipatingTypesLoading } = useParticipatingTypesQuery();

  const { data: nationalities, isLoading: isNationalitiesLoading } = useNationalitiesQuery();

  const participatingTypesData = useNormalizedItemsData(participatingTypes);

  const isDataLoading = isParticipatingTypesLoading || isNationalitiesLoading;
  const isStepLoading = isLoading || isDataLoading;
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width:1024px');

  return (
    <>
      <LoadingOverlay visible={isDataLoading} />

      <Box mb={40}>
        <Controller
          control={form.control}
          name="participating_type_id"
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <RadioGroup
              value={value}
              error={error?.message}
              onChange={onChange}
              label={t('formLabels.participantType')}
              required
            >
              {participatingTypesData.map((item) => (
                <Radio
                  key={item.value}
                  value={item.value}
                  label={item.label}
                  disabled={isStepLoading || isDisabled}
                />
              ))}
            </RadioGroup>
          )}
        />
      </Box>

      {participantType === '1' ? (
        <Box mb={40}>
          <Input.Label required mb={6}>
            {t('formLabels.participantPercentage')}
          </Input.Label>

          <Flex justify="space-between" align={isMobile ? 'end' : 'center'} mb={{ base: 10, lg: 20 }}>
            <Flex direction="column">
              {isMobile ? <Text align="start" fz={12}>{t('placeholders.participantPercentage')}</Text> : null}
              <ParticipantName>
                {getUserFullName(user)}
              </ParticipantName>
            </Flex>
            <Box pl={isTablet ? 20 : 0}>
              <PercentageInput
                label={t('placeholders.participantPercentage')}
                value={100}
                withLabel={!isMobile}
                disabled
              />
            </Box>
          </Flex>
        </Box>
      ) : null}

      {participantType === '2' ? (
        <GroupForm
          form={form}
          nationalities={nationalities || []}
          isDisabled={isDisabled}
        />
      ) : null}

      <Flex justify="space-between" h={40}>
        <Button
          onClick={goToPrevious}
          variant="secondary"
        >
          {t('common.previous')}
        </Button>

        <Button onClick={goToNext}>
          {t('common.next')}
        </Button>
      </Flex>
    </>
  );
};


export default Step2;
