import { useQuery } from '@tanstack/react-query';

import { PrivacyPolicy } from 'src/api/privacy-policy/types';

import { getPrivacyPolicy } from './api';

export const usePrivacyPolicyQuery = () => {
  return useQuery<PrivacyPolicy>({
    queryKey: ['privacy-policy'],
    queryFn: async () => {
      const { data } = await getPrivacyPolicy();
      return data.data;
    }
  });
};
