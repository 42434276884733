import { FC } from 'react';

import { Alert, Group, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { IconAlertCircle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import styles from './FormErrorModal.module.scss';

export type TFormErrorModalProps = {
  message: string;
};

const FormErrorModal: FC<ContextModalProps<TFormErrorModalProps>> = ({
  context,
  id,
  innerProps: { message }
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    context.closeModal(id);
  };

  return (
    <Alert
      title={(
        <Group spacing={5}>
          <IconAlertCircle size={20} />
          <Text>{t('common.notification')}</Text>
        </Group>
      )}
      classNames={{
        title: styles.title,
        body: styles.body
      }}
      color="red"
      withCloseButton
      onClose={handleClose}
      py={20}
      pl={20}
      pr={30}
    >
      {message}
    </Alert>
  );
};

export default FormErrorModal;
