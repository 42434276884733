import dayjs from 'dayjs';

export const isDateBeforeCurrent = (date: Date | string) => {
  return dayjs().isBefore(date);
};

export const isDateAfterCurrent = (date: Date | string) => {
  return dayjs().isAfter(date);
};

export const formatNumberToTime = (time: number) => {
  return time.toString().padStart(2, '0');
};
