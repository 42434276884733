import { useQuery } from '@tanstack/react-query';

import { getNationalities } from './api';
import { Nationality } from './types';

export const useNationalitiesQuery = () => {
  return useQuery<Nationality[]>({
    queryKey: ['nationalities'],
    queryFn: async () => getNationalities().then((res) => res.data)
  });
};
