import { FC, useMemo } from 'react';

import { DatePicker as MantineDatePicker, DatePickerProps } from '@mantine/dates';
import { IconCalendarEvent } from '@tabler/icons-react';
import 'dayjs/locale/ar-sa';
import { useTranslation } from 'react-i18next';

import styles from './DatePicker.module.scss';

const DatePicker: FC<DatePickerProps> = ({
  onChange,
  ...restProps
}) => {
  const { i18n: { language } } = useTranslation();

  const locale = useMemo(() => language === 'ar' ? 'ar-sa' : 'en', [language]);

  return (
    <MantineDatePicker
      locale={locale}
      dropdownPosition="bottom-start"
      icon={<IconCalendarEvent color="#436170" size={20} />}
      inputFormat="YYYY-DD-MM"
      onChange={(value) => {
        if (!onChange) {
          return;
        }

        if (!value) {
          return null;
        }

        const offset = value.getTimezoneOffset();
        const newDate = new Date(value.getTime() - (offset * 60 * 1000));

        onChange(newDate);
      }}
      classNames={{
        input: styles.input,
        invalid: styles.invalid
      }}
      {...restProps}
    />
  );
};

export default DatePicker;
