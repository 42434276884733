import { FC, useEffect, useMemo } from 'react';

import { Center, Image, Loader } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';

import { useLatestNewsQuery, useNewsItemQuery } from 'src/api/news/helpers';
import { ReactComponent as CalendarIcon } from 'src/assets/icons/calendar.svg';
import Container from 'src/components/Container';
import HTMLParser from 'src/components/HTMLParser';
import LatestNews from 'src/components/LatestNews';
import { Paths } from 'src/router/constants';

import styles from './styles.module.scss';

const NewsDetails: FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ newsId: string }>();

  const { data: newsDetails, isLoading: isNewsDetailsLoading } = useNewsItemQuery(params.newsId || '');
  const { data: latestNews, isPreviousData: isLatestNewsLoading } = useLatestNewsQuery();

  const newsDate = useMemo(() => {
    return newsDetails ? new Date(newsDetails.publishing_date).toLocaleDateString() : null;
  }, [newsDetails]);

  useEffect(() => {
    if (newsDetails === null) {
      navigate(Paths.news);
    }
  }, [newsDetails, navigate]);

  const isLoading = isNewsDetailsLoading || isLatestNewsLoading;
  const renderCondition = newsDetails && latestNews;

  return (
    <div className={styles.wrapper}>
      {isLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {renderCondition && (
        <>
          <Container variant="xs">
            <div className={styles.newsWrapper}>
              <h3 className={styles.newsTitle}>{newsDetails.summary}</h3>
              <div className={styles.newsDateWrapper}>
                <CalendarIcon />
                <p className={styles.newsDate}>{newsDate}</p>
              </div>
              <Image
                src={newsDetails.image_path}
                className={styles.newsPhoto}
                width="100%"
                fit="cover"
                height={328}
                withPlaceholder
              />
              <HTMLParser content={newsDetails.news_body} />
            </div>
          </Container>
          <LatestNews news={latestNews} />
        </>
      )}
    </div>
  );
};

export default NewsDetails;
