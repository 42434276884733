import { FC, useEffect } from 'react';

import { Flex } from '@mantine/core';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import PercentageInput from 'src/components/PercentageInput';
import { TCompetitionRegistrationForm } from 'src/modules/competition/types';

type Props = {
  form: UseFormReturn<TCompetitionRegistrationForm>;
};

const TotalPercentage: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const applicantsPercentage = useWatch({
    control: form.control,
    name: 'participating_percentage'
  });

  const participants = useWatch({
    control: form.control,
    name: 'partcipants_data'
  });

  useEffect(() => {
    const totalParticipantsPercent = participants.reduce((acc, curr) => {
      return acc + (curr.participation_percentage || 0);
    }, 0);
    form.setValue('total_percentage', applicantsPercentage + totalParticipantsPercent);
    form.trigger('total_percentage');
  }, [form, applicantsPercentage, participants]);

  return (
    <Flex justify="flex-end">
      <Controller
        control={form.control}
        name="total_percentage"
        render={({
          field: { value },
          fieldState: { error }
        }) => (
          <PercentageInput
            label={t('formLabels.totalParticipantPercentage')}
            value={value}
            min={1}
            max={100}
            error={error?.message}
            disabled
          />
        )}
      />
    </Flex>
  );
};

export default TotalPercentage;
