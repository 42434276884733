import { Center, Loader, Flex, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useGetResultsQuery } from 'src/api/results/hooks';
import Container from 'src/components/Container';
import styles from './styles.module.scss';
import Title from 'src/components/Title';
import { router } from 'src/router/router';
import { Link } from 'react-router-dom';
import { useInnovationListQuery } from 'src/api/innovations/hooks';
import ResultsItem from 'src/components/ResultsItem';
import { useAllWinnerListQuery } from 'src/api/arbitrating/hooks';
import { useEffect } from 'react';

const Results = () => {
  const { t } = useTranslation();
  const searchedText = decodeURIComponent(router.state.location.search.split('=')[1]);
  const {data, isLoading} = useGetResultsQuery(searchedText);
  const { data: innovationList, isLoading: isInnovationListLoading } = useInnovationListQuery();
  const { data: allWinners, isLoading: isAllWinnersLoading } = useAllWinnerListQuery();

  const filteredSearchData = () => {
    const arr: string[] = [];

    if (
      (!isInnovationListLoading && !isLoading && innovationList && data) ||
      (!isAllWinnersLoading && !isLoading && allWinners && data)
    ) {
      data.forEach((item: any) => {
        innovationList?.forEach((el: any) => {
          if (
            item.requestId === el.id && (
            item.innovationName_ar?.toLowerCase()?.includes(searchedText) ||
            item.competition_path_name_ar.toLowerCase()?.includes(searchedText) ||
            item.innovation_description?.toLowerCase()?.includes(searchedText) ||
            item.innovation_description_en?.toLowerCase()?.includes(searchedText) ||
            `${item.mamber_first_name} ${item.mamber_second_name} ${item.mamber_last_name}`.toLowerCase()?.includes(searchedText) ||
            item.participating_memberss.find((p: any) => `${p.first_name} ${p.second_name} ${p.last_name}`.toLowerCase()?.includes(searchedText))
          )) {
            arr.push({...item, linkTo: 'innovation'});
          }
        });

        allWinners?.winners.forEach((el: any) => {
          if (
            item.requestId === el.request_id && (
            item.participatingApplications.length.toString().includes(searchedText) ||
            `${item.mamber_first_name} ${item.mamber_second_name} ${item.mamber_last_name}`.toLowerCase()?.includes(searchedText) ||
            item.competition_path_name_ar.toLowerCase()?.includes(searchedText) ||
            item.innovation_description?.toLowerCase()?.includes(searchedText) ||
            item.innovation_description_en?.toLowerCase()?.includes(searchedText)
          )) {
            arr.push({...item, linkTo: 'winners'});
          }
        });
      });
    }

    return arr;
  }

  useEffect(() => {
    localStorage.setItem('searchedText', searchedText);
  }, [searchedText])

  return (
    <Container variant='lg'>
      {isLoading && (
        <Center py={50}>
          <Loader size='lg' />
        </Center>
      )}
      {filteredSearchData().filter((item: any) => item.member_email || item.id)?.length ? (
        <Flex direction='column' gap={48} my={90}>
          <div>
            <Title color='darkGreen' fontSize={24} spacing='small' underlineTopSpacing={10}>{t('pages.result.searchResult')}</Title>
            <Text color='#B9B9B9'>{filteredSearchData().filter((item: any) => item.member_email || item.id)?.length} {t('pages.result.searchResult')}</Text>
          </div>

          <Flex wrap='wrap' gap='24px 64px'>
            {filteredSearchData().filter((item: any) => item.member_email || item.id).map((item: any) => (
              <Link
                to={`/${item.linkTo}#${item.requestId}`}
                className={styles.item}
                key={item.requestId}
              >
                <Flex direction='column' w='100%' gap={24}>
                  {item.linkTo === 'innovation' ? (
                    <Flex direction='column' className={styles.searchedText}>
                      <ResultsItem item={item.innovationName_ar?.toLowerCase()?.includes(searchedText) ? item.innovationName_ar : item.innovation_name} searchedText={searchedText} />
                      <ResultsItem item={item.competition_path_name_ar} searchedText={searchedText} />
                      <ResultsItem item={item.innovation_description?.toLowerCase()?.includes(searchedText) ? item.innovation_description : item.innovation_description_en} searchedText={searchedText} />
                      <ResultsItem item={`${item.mamber_first_name} ${item.mamber_second_name} ${item.mamber_last_name}`} searchedText={searchedText} />
                      {item.participating_memberss.map((p: any, id: number) => (
                        <ResultsItem
                          key={id}
                          item={`${p.first_name} ${p.second_name} ${p.last_name}`}
                          searchedText={searchedText}
                        />
                      ))}
                    </Flex>
                  ) : (
                    <Flex direction='column' className={styles.searchedText}>
                      <ResultsItem item={`${item.participatingApplications.length}`} searchedText={searchedText} />
                      <ResultsItem item={`${item.mamber_first_name} ${item.mamber_second_name} ${item.mamber_last_name}`} searchedText={searchedText} />
                      <ResultsItem item={item.competition_path_name_ar} searchedText={searchedText} />
                      <ResultsItem item={item.innovation_description?.toLowerCase()?.includes(searchedText) ? item.innovation_description : item.innovation_description_en} searchedText={searchedText} />
                    </Flex>
                  )}
                </Flex>
              </Link>
            ))}
          </Flex>
        </Flex>
      ) : (
        <Flex direction='column' my={90}>
          <Title color='darkGreen' fontSize={24} spacing='small' underlineTopSpacing={10}>{t('pages.result.searchResult')}</Title>
          <Text color='#B9B9B9'>{t('pages.result.noResult')}</Text>
        </Flex>
      )}
    </Container>
  );
};

export default Results;
