import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { Box, Flex, Input, LoadingOverlay, SimpleGrid, Stack, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import isEqual from 'lodash/isEqual';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import { useCompetitionPathsQuery } from 'src/api/competition-paths/hooks';
import { useGendersQuery } from 'src/api/genders/hooks';
import { useNationalitiesQuery } from 'src/api/nationalities/hooks';
import { useUpdateUserMutation } from 'src/api/user/hooks';
import { UserEditData } from 'src/api/user/types';
import { ReactComponent as Sight } from 'src/assets/svg/small_logo_icon.svg';
import DatePicker from 'src/components/DatePicker';
import Form from 'src/components/Form';
import MultiSelect from 'src/components/MultiSelect';
import Select from 'src/components/Select';
import TextInput from 'src/components/TextInput';
import { emailRegExp, idRegExp, nameRegExp, phoneNumberRegExp } from 'src/constants/regExp';
import { USER_MAX_AGE, USER_MIN_AGE } from 'src/constants/shared';
import { parseApiErrors } from 'src/helpers/forms';
import { openFormErrorModal } from 'src/helpers/modals';
import { normalizeUserForForm } from 'src/helpers/user';
import { useNormalizedItemsData } from 'src/hooks/useNormalizedItemsData';
import { useAuthStore } from 'src/store/useAuthStore';

import { TAccountSectionName } from '../helpers';
import Title from '../Title';
import styles from './styles.module.scss';

type TForm = UserEditData & { confirm_email: string };

type Props = {
  setActiveSection: Dispatch<SetStateAction<TAccountSectionName>>;
};

const PersonalInfo: FC<Props> = ({ setActiveSection }) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { t } = useTranslation();
  const { user } = useAuthStore();

  const { data: genders } = useGendersQuery();
  const { data: nationalities } = useNationalitiesQuery();
  const { data: paths } = useCompetitionPathsQuery();

  const nationalitiesData = useNormalizedItemsData(nationalities);
  const gendersData = useNormalizedItemsData(genders);
  const pathsData = useNormalizedItemsData(paths, 'title');

  const form = useForm<TForm>({
    defaultValues: {
      first_name: '',
      second_name: '',
      last_name: '',
      nationality_id: null,
      mobile_number: '',
      gender_id: null,
      email: '',
      confirm_email: '',
      birthday: null,
      user_identity: '',
      interested_competition_path: []
    }
  });

  const { isLoading: isUpdateUserLoading, mutate: updateUser } = useUpdateUserMutation({
    onSuccess: () => setIsEditing(false),
    onError: (error) => parseApiErrors(error, {
      form,
      onGlobalError: (message) => openFormErrorModal({ message })
    })
  });

  useEffect(() => {
    if (user) {
      form.reset(normalizeUserForForm(user));
    }
  }, [form, user]);

  const handleSubmit = () => {
    form.handleSubmit((data) => {

      if (user && !isEqual(data, normalizeUserForForm(user))) {
        updateUser(data);
      } else {
        setIsEditing(false);
      }
    })();
  };

  const isLoading = !user || !genders || !nationalities;

  return (
    <InView
      as="div"
      threshold={0.5}
      onChange={(inView) => inView && setActiveSection('personal-info')}
    >
      <Form className={styles.wrapper}>
        <LoadingOverlay visible={isLoading || isUpdateUserLoading} />

        <Flex justify="space-between" mb={isMobile ? 10 : 40}>
          <Title>{t('pages.account.personalInfo')}</Title>
          {isEditing ? (
            <Text
              component="button"
              size={14}
              color="blue"
              td="underline"
              onClick={handleSubmit}
            >
              {t('common.save')}
            </Text>
          ) : (
            <Text
              component="button"
              size={14}
              color="blue"
              td="underline"
              onClick={() => setIsEditing(true)}
            >
              {t('common.edit')}
            </Text>
          )}
        </Flex>

        <Box>
          <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120} mb={isMobile ? 10 : 40}>
            <Controller
              control={form.control}
              name="user_identity"
              rules={{
                required: t('errorMessages.required'),
                pattern: {
                  value: idRegExp,
                  message: t('errorMessages.idOrIqamaNumber')
                }
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  label={t('formLabels.idOrIqamaNumber')}
                  placeholder={t('placeholders.idOrIqamaNumber')}
                  disabled={!isEditing}
                />
              )}
            />

            <Controller
              control={form.control}
              name="nationality_id"
              rules={{ required: t('errorMessages.required') }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error }
              }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  data={nationalitiesData}
                  label={t('formLabels.nationality')}
                  placeholder={t('placeholders.nationality')}
                  searchable
                  clearable
                  disabled={!isEditing}
                />
              )}
            />
          </SimpleGrid>

          <Stack spacing={5}>
            <Input.Label>
              {isMobile ? t('formLabels.firstName') : t('formLabels.fullName')}
            </Input.Label>

            <SimpleGrid cols={isMobile ? 1 : 3} spacing={isMobile ? 15 : 28} mb={isMobile ? 10 : 40}>
              <Controller
                control={form.control}
                name="first_name"
                rules={{
                  required: t('errorMessages.required'),
                  pattern: {
                    value: nameRegExp,
                    message: t('errorMessages.onlyName')
                  }
                }}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error }
                }) => (
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error?.message}
                    placeholder={t('placeholders.firstName')}
                    disabled={!isEditing}
                  />
                )}
              />

              <Flex direction="column">
                {isMobile ? (
                  <Input.Label>{t('formLabels.middleName')}</Input.Label>
                ) : null}

                <Controller
                  control={form.control}
                  name="second_name"
                  rules={{
                    required: t('errorMessages.required'),
                    pattern: {
                      value: nameRegExp,
                      message: t('errorMessages.onlyName')
                    }
                  }}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error }
                  }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      placeholder={t('placeholders.middleName')}
                      disabled={!isEditing}
                    />
                  )}
                />
              </Flex>

              <Flex direction="column">
                {isMobile ? (
                  <Input.Label>{t('formLabels.lastName')}</Input.Label>
                ) : null}

                <Controller
                  control={form.control}
                  name="last_name"
                  rules={{
                    required: t('errorMessages.required'),
                    pattern: {
                      value: nameRegExp,
                      message: t('errorMessages.onlyName')
                    }
                  }}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error }
                  }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      placeholder={t('placeholders.lastName')}
                      disabled={!isEditing}
                    />
                  )}
                />
              </Flex>
            </SimpleGrid>
          </Stack>

          <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120} mb={isMobile ? 10 : 40}>
            <Controller
              control={form.control}
              name="mobile_number"
              rules={{
                required: t('errorMessages.required'),
                pattern: {
                  value: phoneNumberRegExp,
                  message: t('errorMessages.invalidPhoneNumber')
                }
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  label={t('formLabels.phoneNumber')}
                  placeholder={t('placeholders.phoneNumber')}
                  type="tel"
                  disabled={!isEditing}
                />
              )}
            />
          </SimpleGrid>

          <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120} mb={isMobile ? 10 : 40}>
            <Controller
              control={form.control}
              name="email"
              rules={{
                required: t('errorMessages.required'),
                pattern: {
                  value: emailRegExp,
                  message: t('errorMessages.invalidEmail')
                }
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
                formState: { isSubmitted }
              }) => (
                <TextInput
                  value={value}
                  onChange={(value) => {
                    onChange(value);

                    if (isSubmitted && form.getValues('confirm_email')) {
                      form.trigger('confirm_email');
                    }
                  }}
                  onBlur={onBlur}
                  error={error?.message}
                  label={t('formLabels.email')}
                  placeholder={t('placeholders.email')}
                  type="email"
                  disabled={!isEditing}
                />
              )}
            />

            <Controller
              control={form.control}
              name="confirm_email"
              rules={{
                required: t('errorMessages.required'),
                pattern: {
                  value: emailRegExp,
                  message: t('errorMessages.invalidEmail')
                },
                validate: (value) => {
                  if (form.getValues('email') !== value) {
                    return t('errorMessages.emailsNotMatch');
                  }

                  return true;
                }
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  label={t('formLabels.confirmEmail')}
                  placeholder={t('placeholders.email')}
                  type="email"
                  disabled={!isEditing}
                />
              )}
            />
          </SimpleGrid>

          <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120}>
            <Controller
              control={form.control}
              name="gender_id"
              rules={{
                required: t('errorMessages.required')
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error }
              }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  data={gendersData}
                  label={t('formLabels.gender')}
                  placeholder={t('placeholders.gender')}
                  disabled={!isEditing}
                />
              )}
            />

            <Controller
              control={form.control}
              name="birthday"
              rules={{
                required: t('errorMessages.required')
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error }
              }) => (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  label={t('formLabels.birthDate')}
                  placeholder={t('placeholders.birthDate')}
                  minDate={USER_MAX_AGE}
                  maxDate={USER_MIN_AGE}
                  initialLevel="year"
                  disabled={!isEditing}
                />
              )}
            />
          </SimpleGrid>
        </Box>

        <Box mt={user?.preferedPath?.length || isEditing ? 50 : 0}>
          {user?.preferedPath?.length || isEditing ? (
            <Flex justify="space-between" mb={isMobile ? 10 : 20}>
              <Title>{t('pages.account.interests')}</Title>
            </Flex>
          ) : null}

          {isEditing ? (
            <Box>
              <Controller
                control={form.control}
                name="interested_competition_path"
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error }
                }) => (
                  <MultiSelect
                    data={pathsData}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error?.message}
                    placeholder={t('placeholders.paths')}
                    disabled={!isEditing}
                  />
                )}
              />
            </Box>
          ) : (
            <Flex className={styles.userPaths}>
              {user?.preferedPath?.map((path) => (
                <Flex align="center" gap={5} key={path.id}>
                  <Sight className={styles.sight} />
                  <Text className={styles.pathText}>{path.name}</Text>
                </Flex>
              ))}
            </Flex>
          )}
        </Box>
      </Form>
    </InView>
  );
};

export default PersonalInfo;
