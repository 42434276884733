import { FC, ReactNode } from 'react';

import styles from './ParticipantName.module.scss';

type Props = {
  children: ReactNode;
};

const ParticipantName: FC<Props> = ({ children }) => (
  <div className={styles.root}>
    <div className={styles.text}>
      {children}
    </div>
  </div>
);

export default ParticipantName;
