import {
  GetCurrentCompetitionResponse,
  GetUserCompetitionInfoResponse,
  TCompetitionRegistrationResponse,
  TCompetitionSubmitRequest,
  TCompetitionSubmitResponse,
  TCompetitionUpdateRequest,
  TCompetitionUpdateResponse
} from 'src/api/competition/types';
import privateApi from 'src/configs/private-api';
import { getFormData } from 'src/helpers/forms';
import { TCompetitionRegistration, TParticipantsApprovalInfo } from 'src/modules/competition/types';

export const getCurrentCompetition = async (): Promise<GetCurrentCompetitionResponse> => {
  return privateApi.get('/competition').then((res) => res.data);
};

export const createParticipantRequest = async (data: TCompetitionRegistration): Promise<TCompetitionRegistrationResponse> => {
  return privateApi.post('participating/request', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const submitParticipantRequest = async (data: TCompetitionSubmitRequest): Promise<TCompetitionSubmitResponse> => {
  return privateApi.post('/participating/request/submit', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const updateParticipantRequest = async (data: TCompetitionUpdateRequest): Promise<TCompetitionUpdateResponse> => {
  return privateApi.put('/participating/request', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const updateParticipantRequestMissingInfo = async (data: TCompetitionSubmitRequest): Promise<TCompetitionSubmitResponse> => {
  return privateApi.put('/participating/request/missing-requirements', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const getUserParticipatingRequestsInfo = async (): Promise<GetUserCompetitionInfoResponse> => {
  return privateApi.get('/participating/request').then((res) => res.data);
};

export const getParticipantRequestApprovesInfo = async (requestId: string): Promise<TParticipantsApprovalInfo> => {
  return privateApi.get('/participating/requestApproves', { params: { requestId } }).then((res) => res.data);
};
