import { FC, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import Tabs from 'src/components/Tabs';

import { tabs } from './constants';

const NavTabs: FC = () => {
  const { pathname } = useLocation();

  const activeTab = useMemo(() => {
    return pathname.split('/')[2];
  }, [pathname]);

  return (
    <Tabs active={activeTab} tabs={tabs} />
  );
};

export default NavTabs;
