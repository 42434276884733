import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type TForgetPasswordStore = {
  timestamp: number | null;
  setTimestamp: (timestamp: number | null) => void;
};

export const useForgetPasswordStore = create(
  persist<TForgetPasswordStore>(
    (set) => ({
      timestamp: null,
      setTimestamp: (timestamp) => set(() => ({ timestamp }))
    }),
    {
      name: 'forget-password',
      storage: createJSONStorage(() => localStorage)
    }
  )
);

