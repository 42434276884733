import { Image } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { useHomePageInfoQuery } from 'src/api/home-info/hooks';
import homepageGovernorBg from 'src/assets/svg/homepage-governor-bg.svg';
import homepageLinesBg from 'src/assets/svg/homepage-lines-bg.svg';
import kingAndPrinceMessagesBg from 'src/assets/svg/king-and-prince-bg.svg';
import Container from 'src/components/Container';
import LatestNews from 'src/components/LatestNews';
import Title from 'src/components/Title';
import WinnerBlock from 'src/components/WinnerBlock';

import Message from './Message';
import styles from './styles.module.scss';
import { useShowHidePageListQuery } from 'src/api/content-management/hooks';

const Homepage = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { data: Content, isLoading: ContentLoading } = useShowHidePageListQuery();
  const { data: homepageData } = useHomePageInfoQuery();

  const isShow = !ContentLoading && Content?.data.find((item) => item.name === '3 Top Winners');

  const {
    kingMessage,
    crownPrinceMessage,
    governorOfGAMIMessage,
    _latestnews
  } = homepageData || {};

  return (
    <div className={styles.wrapper}>
      <div className={styles.kingAndPrinceMessagesWrapper}>
        <img src={kingAndPrinceMessagesBg} alt="" className={styles.kingAndPrinceMessagesBackground} />
        <Container variant="md">
          <div className={styles.kingAndPrinceMessagesContent}>
            <Message
              photoPosition="start"
              photo={kingMessage?.image_path || ''}
              message={kingMessage?.message || ''}
              messageAuthor={kingMessage?.title || ''}
            />
            <div className={styles.divider} />
            <Message
              photoPosition="end"
              photo={crownPrinceMessage?.image_path || ''}
              message={crownPrinceMessage?.message || ''}
              messageAuthor={crownPrinceMessage?.title || ''}
            />
          </div>
        </Container>
      </div>
      <div className={styles.governorMessageWrapper}>
        {!isMobile && <img src={homepageLinesBg} alt="" className={styles.governorMessageLinesBg} />}
        <img src={homepageGovernorBg} alt="" className={styles.governorMessageOrnamentBg} />
        <Container>
          <div className={styles.governorMessageContentWrapper}>
            <div className={styles.governorMessageImageWrapper}>
              <div className={styles.governorImageBg} />
              <Image
                src={governorOfGAMIMessage?.image_path}
                className={styles.governorImage}
                fit="cover"
                height={250}
                width={250}
                withPlaceholder
              />
            </div>
            <div className={styles.governorMessageContent}>
              <Title color="green">
                {governorOfGAMIMessage?.title}
              </Title>
              <p className={styles.governorMessageText}>
                {governorOfGAMIMessage?.message}
              </p>
            </div>
          </div>
        </Container>
      </div>
      {isShow && isShow.is_show && (<WinnerBlock />)}
      <LatestNews news={_latestnews || []} />
    </div>
  );
};

export default Homepage;
