import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

export const useModalPage = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleClose = () => {
    setIsOpen(false);
    const timeout = setTimeout(() => {
      navigate(-1);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  };

  return {
    isOpen,
    handleClose
  };
};
