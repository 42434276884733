import publicApi from 'src/configs/public-api';

import { GetNewsResponse } from './types';

export const getNews = async (page: number): Promise<GetNewsResponse> => {
  return publicApi.get('/news', {
    params: {
      page_size: 9,
      page_number: page
    }
  }).then((res) => res.data);
};

export const getLatestNews = async (): Promise<GetNewsResponse> => {
  return publicApi.get('/news', {
    params: {
      page_size: 3,
      page_number: 1
    }
  }).then((res) => res.data);
};

export const getNewsById = async (id: string): Promise<GetNewsResponse> => {
  return publicApi.get('/news', {
    params: { id }
  }).then((res) => res.data);
};
