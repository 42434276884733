import { FC } from 'react';

import { Select as MantineSelect, SelectProps } from '@mantine/core';

import styles from './Select.module.scss';

const Select: FC<SelectProps> = (props) => (
  <MantineSelect
    classNames={{
      input: styles.input,
      invalid: styles.invalid,
      item: styles.item,
      rightSection: styles.rightSection
    }}
    dropdownPosition="bottom"
    {...props}
  />
);

export default Select;
