export enum RequestStatus {
  New = 1,
  Submitted = 2,
  MissingRequirements = 3,
  Approved = 4,
  Rejected = 5,
  Edited = 6,
  UnderReview = 7,
  FinalApproved = 8,
  FinalRejected = 9,
  NotUpdated = 10
}

export enum RequestEvaluationStage {
  New = 1,
  Filtering = 2,
  Authorizing = 3,
  Approval = 4,
  Arbitration = 5,
}

export const colors = {
  [RequestStatus.New]: 'gray',
  [RequestStatus.Submitted]: 'blue',
  [RequestStatus.MissingRequirements]: 'orange',
  [RequestStatus.Approved]: '#3b3',
  [RequestStatus.Rejected]: 'red',
  [RequestStatus.Edited]: '#ff78f1',
  [RequestStatus.UnderReview]: 'purple',
  [RequestStatus.FinalApproved]: '#009933',
  [RequestStatus.FinalRejected]: 'darkgray',
  [RequestStatus.NotUpdated]: '#6600cc'
};
