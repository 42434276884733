import { FC } from 'react';

import { Group } from '@mantine/core';

import styles from './styles.module.scss';

type TProps = {
  children: string;
};

const Title: FC<TProps> = ({
  children
}) => (
  <Group spacing={10}>
    <div className={styles.line} />
    <h3 className={styles.title}>
      {children}
    </h3>
  </Group>
);

export default Title;
