import { FC } from 'react';

import { Image } from '@mantine/core';

import styles from './styles.module.scss';

type Props = {
  imageSrc: string;
  name: string;
  position: string;
};

const MainPersonCard: FC<Props> = ({
  imageSrc,
  name,
  position
}) => (
  <div className={styles.cardWrapper}>
    <div className={styles.imageWrapper}>
      <Image
        src={imageSrc}
        width="100%"
        height={332}
        fit="cover"
        withPlaceholder
      />
    </div>
    <div className={styles.textBox}>
      <h3 className={styles.title}>{name}</h3>
      <p className={styles.subtitle}>{position}</p>
    </div>
  </div>
);

export default MainPersonCard;
