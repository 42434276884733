import { FC, useEffect, useRef, useState } from 'react';

import Navbar from 'src/components/Navbar';

import styles from './styles.module.scss';
import { THeaderProps } from './types';

const Header: FC<THeaderProps> = ({
  navbarCheckpoint
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      setIsFixed(!entry.isIntersecting);
    });
    observer.observe(ref.current!);
  }, []);

  return (
    <>
      <div ref={ref} className={styles.headerCheckpoint} style={{ height: navbarCheckpoint }} />

      <div style={{ zIndex: isFixed ? 40 : 60 }}>
        {isFixed ? (
          <Navbar
            navbarVariant="light"
            position="fixed"
          />
        ) : (
          <Navbar
            navbarVariant="transparent"
            position="absolute"
          />
        )}
      </div>
    </>
  );

};

export default Header;
