import axios, { AxiosError } from 'axios';

import { API_BASE_URL, API_TOKEN_KEY } from 'src/constants/api';
import { I18N_LANG_TOKEN } from 'src/constants/i18n';
import { openLoginModal } from 'src/helpers/modals';
import { Paths } from 'src/router/constants';
import { router } from 'src/router/router';
import { useAuthStore } from 'src/store/useAuthStore';

const privateApi = axios.create({
  baseURL: API_BASE_URL
});

const languageKeys = {
  'en': 'en',
  'ar': 'ar-SA'
};

privateApi.interceptors.request.use((config) => {
  const localLang = localStorage.getItem(I18N_LANG_TOKEN);
  const apiToken = localStorage.getItem(API_TOKEN_KEY);

  return {
    ...config,
    headers: {
      'Accept-Language': localLang ? languageKeys[localLang as 'en' | 'ar'] : languageKeys.ar,
      'x-api-key': process.env.REACT_APP_API_KEY || '',
      'Authorization': apiToken
    }
  };
});

privateApi.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<any>) => {
    if (!error.isAxiosError) {
      throw 'Unknown error';
    }

    if (error.response?.status === 401) {
      // todo: refresh token, refetch and return original request
      // todo: invalidate all data
      localStorage.removeItem(API_TOKEN_KEY);
      useAuthStore.setState({ user: null });

      throw {
        type: 'Unauthorized',
        status: 401,
        message: 'Forbidden access'
      };
    }

    if (error.response?.status === 403) {
      localStorage.removeItem(API_TOKEN_KEY);
      useAuthStore.setState({ user: null });
      openLoginModal();

      throw {
        type: 'Forbidden',
        status: 403,
        message: 'Forbidden access'
      };
    }

    if (error.response?.status === 500) {
      router.navigate(Paths.error);

      throw {
        type: 'Server error',
        status: 500,
        message: 'Server error'
      };
    }

    if (error.response?.status === 422) {
      throw {
        type: 'Validation error',
        status: 422,
        errors: error.response.data?.errors
      };
    }

    throw 'Uncaught error';
  }
);

export default privateApi;
