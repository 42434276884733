import { FC } from 'react';

import { clsx } from 'clsx';

import styles from './styles.module.scss';
import { TContainerProps } from './types';

const Container: FC<TContainerProps> = ({
  children,
  variant = 'md',
  fullHeight
}) => (
  <div className={clsx(styles.container, variant, { [styles.fullHeight]: fullHeight })}>
    {children}
  </div>
);

export default Container;
