import { Center, Loader } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useCompetitionPathsQuery } from 'src/api/competition-paths/hooks';
import competitionsLines from 'src/assets/svg/competitions-lines.svg';
import Container from 'src/components/Container';
import Title from 'src/components/Title';

import CompetitionCard from './CompetitonCard';
import styles from './styles.module.scss';

const CompetitionPaths = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useCompetitionPathsQuery();

  return (
    <div className={styles.wrapper}>
      <img src={competitionsLines} alt="" className={styles.lines} />
      <Container variant="lg">
        {isLoading && (
          <Center py={50}>
            <Loader size="lg" />
          </Center>
        )}
        {data && (
          <>
            <Title color="dark" fontSize="large" spacing={64}>
              {t('pages.competitionPaths.title')}
            </Title>
            <div className={styles.cardSection}>
              {data.map((item) => (
                <CompetitionCard key={item.id} item={item} />
              ))}
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default CompetitionPaths;
