import privateApi from 'src/configs/private-api';
import { SuccessResponse } from 'src/types/api';

import {
  ParticipatingSendOTPCodeParams,
  ParticipatingVerifyOTPCodePayload,
  ParticipatingApproveParticipatingPayload
} from './types';

const sendOTPCode = async (
  params: ParticipatingSendOTPCodeParams
): Promise<SuccessResponse> => {
  return privateApi.get('/participating/sendParticipantOTP', { params }).then((res) => res.data);
};

const verifyOTPCode = async (
  payload: ParticipatingVerifyOTPCodePayload
): Promise<SuccessResponse> => {
  return privateApi.post('/participating/participantOTPVerify', payload).then((res) => res.data);
};

const approveParticipating = async (
  payload: ParticipatingApproveParticipatingPayload
): Promise<SuccessResponse> => {
  return privateApi.put('/participating/participantAprrove', payload).then((res) => res.data);
};

export const participatingApi = {
  sendOTPCode,
  verifyOTPCode,
  approveParticipating
} as const;
