import { useQuery } from '@tanstack/react-query';

import { getResults } from './api';
import { RESULTS_QUERY_KEY } from './constants';

export const useGetResultsQuery = (search_term: string) => {
  return useQuery<any>({
    queryKey: [RESULTS_QUERY_KEY],
    queryFn: () => getResults(search_term)
  });
};
