import { useQuery } from '@tanstack/react-query';

import { getHomePageInfo } from './api';
import { THomepageInfo } from './types';


export const useHomePageInfoQuery = () => {
  return useQuery<THomepageInfo>({
    queryKey: ['home'],
    queryFn: async () => getHomePageInfo().then((res) => res.data)
  });
};