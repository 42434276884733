import { FC } from 'react';

import styles from './styles.module.scss';
import { TTimeSegment } from './types';

const TimeSegment: FC<TTimeSegment> = ({ time, label }) => (
  <div className={styles.wrapper}>
    <span className={styles.time}>{time}</span>
    <span className={styles.label}>{label}</span>
  </div>
);

export default TimeSegment;
