import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { Box, Flex, Group, Input, LoadingOverlay, Radio, SimpleGrid, Stack, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import isEqual from 'lodash/isEqual';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';


import { useUpdateInterestedUserMutation } from 'src/api/user/hooks';
import { UpdateInterestedUserData } from 'src/api/user/types';
import Form from 'src/components/Form';
import TextInput from 'src/components/TextInput';
import { emailRegExp, nameRegExp, phoneNumberRegExp } from 'src/constants/regExp';
import { parseApiErrors } from 'src/helpers/forms';
import { openFormErrorModal } from 'src/helpers/modals';
import { normalizeInterestedUserForForm, normalizeUserForForm } from 'src/helpers/user';
import { useAuthStore } from 'src/store/useAuthStore';

import { TAccountInterestedSectionName } from '../helpers';
import Title from '../Title';
import styles from '../PersonalInfo/styles.module.scss';
import { IconCheck, IconX } from '@tabler/icons-react';
import RadioGroup from 'src/components/RadioGroup';

type TForm = UpdateInterestedUserData & {email: string} & {confirmed_email: string};

type Props = {
  setActiveSection: Dispatch<SetStateAction<TAccountInterestedSectionName>>;
};

const InterestedPersonalInfo: FC<Props> = ({ setActiveSection }) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isEditingInterests, setIsEditingInterests] = useState<boolean>(false);
  const [interestedText, setInterestedText] = useState('');
  const [companyName, setCompanyName] = useState<string | number>();
  const { t } = useTranslation();
  const { user } = useAuthStore();

  const form = useForm<TForm>({
    defaultValues: {
      first_name: '',
      second_name: '',
      last_name: '',
      mobile_number: '',
      email: '',
      confirmed_email: '',
      interests: [],
      entity: 1,
      company_name: ''
    }
  });

  const { isLoading: isUpdateUserLoading, mutate: updateUser } = useUpdateInterestedUserMutation({
    onSuccess: () => {
      setIsEditing(false);
      setIsEditingInterests(false);
    },
    onError: (error) => parseApiErrors(error, {
      form,
      onGlobalError: (message) => openFormErrorModal({ message })
    })
  });

  useEffect(() => {
    if (user) {
      form.reset(normalizeInterestedUserForForm(user));
    }
  }, [form, user]);

  const handleSubmit = () => {
    form.handleSubmit((data) => {

      if (user && !isEqual(data, normalizeUserForForm(user))) {
        updateUser(data);
      } else {
        setIsEditing(false);
        setIsEditingInterests(false);
      }
    })();
  };

  const isLoading = !user;

  return (
    <InView
      as="div"
      threshold={0.5}
      onChange={(inView) => inView && setActiveSection('personal-info')}
    >
      <Form className={styles.wrapper}>
        <LoadingOverlay visible={isLoading || isUpdateUserLoading} />

        <Flex justify="space-between" mb={isMobile ? 10 : 40}>
          <Title>{t('pages.account.personalInfo')}</Title>
          {isEditing ? (
            <Text
              component="button"
              size={14}
              color="blue"
              td="underline"
              onClick={handleSubmit}
            >
              {t('common.save')}
            </Text>
          ) : (
            <Text
              component="button"
              size={14}
              color="blue"
              td="underline"
              onClick={() => setIsEditing(true)}
            >
              {t('common.edit')}
            </Text>
          )}
        </Flex>

        <Box>
          <Stack spacing={5}>
            <Input.Label>
              {isMobile ? t('formLabels.firstName') : t('formLabels.fullName')}
            </Input.Label>

            <SimpleGrid cols={isMobile ? 1 : 3} spacing={isMobile ? 15 : 28} mb={isMobile ? 10 : 40}>
              <Controller
                control={form.control}
                name="first_name"
                rules={{
                  required: t('errorMessages.required'),
                  pattern: {
                    value: nameRegExp,
                    message: t('errorMessages.onlyName')
                  }
                }}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error }
                }) => (
                  <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error?.message}
                    placeholder={t('placeholders.firstName')}
                    disabled={!isEditing}
                  />
                )}
              />

              <Flex direction="column">
                {isMobile ? (
                  <Input.Label>{t('formLabels.middleName')}</Input.Label>
                ) : null}

                <Controller
                  control={form.control}
                  name="second_name"
                  rules={{
                    required: t('errorMessages.required'),
                    pattern: {
                      value: nameRegExp,
                      message: t('errorMessages.onlyName')
                    }
                  }}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error }
                  }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      placeholder={t('placeholders.middleName')}
                      disabled={!isEditing}
                    />
                  )}
                />
              </Flex>

              <Flex direction="column">
                {isMobile ? (
                  <Input.Label>{t('formLabels.lastName')}</Input.Label>
                ) : null}

                <Controller
                  control={form.control}
                  name="last_name"
                  rules={{
                    required: t('errorMessages.required'),
                    pattern: {
                      value: nameRegExp,
                      message: t('errorMessages.onlyName')
                    }
                  }}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error }
                  }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      placeholder={t('placeholders.lastName')}
                      disabled={!isEditing}
                    />
                  )}
                />
              </Flex>
            </SimpleGrid>
          </Stack>

          <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120} mb={isMobile ? 10 : 40}>
            <Controller
              control={form.control}
              name="mobile_number"
              rules={{
                required: t('errorMessages.required'),
                pattern: {
                  value: phoneNumberRegExp,
                  message: t('errorMessages.invalidPhoneNumber')
                }
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  label={t('formLabels.phoneNumber')}
                  placeholder={t('placeholders.phoneNumber')}
                  type="tel"
                  disabled={!isEditing}
                />
              )}
            />
          </SimpleGrid>

          <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120} mb={isMobile ? 10 : 40}>
            <Controller
              control={form.control}
              name="email"
              rules={{
                required: t('errorMessages.required'),
                pattern: {
                  value: emailRegExp,
                  message: t('errorMessages.invalidEmail')
                }
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
                formState: { isSubmitted }
              }) => (
                <TextInput
                  value={value}
                  onChange={(value) => {
                    onChange(value);

                    if (isSubmitted && form.getValues('confirmed_email')) {
                      form.trigger('confirmed_email');
                    }
                  }}
                  onBlur={onBlur}
                  error={error?.message}
                  label={t('formLabels.email')}
                  placeholder={t('placeholders.email')}
                  type="email"
                  disabled
                />
              )}
            />

            <Controller
              control={form.control}
              name="confirmed_email"
              rules={{
                required: t('errorMessages.required'),
                pattern: {
                  value: emailRegExp,
                  message: t('errorMessages.invalidEmail')
                },
                validate: (value) => {
                  if (form.getValues('email') !== value) {
                    return t('errorMessages.emailsNotMatch');
                  }

                  return true;
                }
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  label={t('formLabels.confirmEmail')}
                  placeholder={t('placeholders.email')}
                  type="email"
                  disabled
                />
              )}
            />
          </SimpleGrid>
        </Box>

        <Box mt={user?.preferedPath?.length || isEditing ? 50 : 0} mb={40}>
            <Flex justify="space-between" mb={isMobile ? 10 : 20}>
              <Title>{t('pages.account.interests')}</Title>
              {isEditing ? null : (
                <>
                  {isEditingInterests ? (
                    <Text
                      component="button"
                      size={14}
                      color="blue"
                      td="underline"
                      onClick={handleSubmit}
                    >
                      {t('common.save')}
                    </Text>
                  ) : (
                    <Text
                      component="button"
                      size={14}
                      color="blue"
                      td="underline"
                      onClick={() => setIsEditingInterests(true)}
                    >
                      {t('pages.account.addNewInterest')}
                    </Text>
                  )}
                </>
              )}
            </Flex>

          {isEditing || isEditingInterests ? (
            <Box>
              <Controller
                control={form.control}
                name="interests"
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error }
                }) => (
                  <Flex direction='column' gap={12}>
                    <Group pos='relative' w={240}>
                      <TextInput
                        value={interestedText}
                        onChange={(e) => setInterestedText(e.target.value)}
                        onBlur={onBlur}
                        error={error?.message}
                        placeholder={t('placeholders.interests')}
                        w='100%'
                        className={styles.interestedInput}
                        required
                        disabled={isLoading}
                      />
                      {interestedText.length && (<IconCheck
                        cursor='pointer'
                        className={styles.interestedIcon}
                        onClick={() => {
                          onChange([...value, interestedText]);
                          setInterestedText('');
                        }}
                      />)}
                    </Group>

                    <Flex gap={8} wrap='wrap'>
                      {form.getValues('interests')?.map((item: string, id: number) => (
                        <Flex key={id} className={styles.iterestedTags} align='center'>
                          <Text size={10}>{item}</Text>
                          <IconX
                            size={14}
                            cursor='pointer'
                            onClick={() => {
                              form.setValue('interests',
                                form.getValues('interests').filter((el, i) => i !== id)
                              );
                            }}
                          />
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                )}
              />
            </Box>
          ) : (
            <Flex fz={12} gap={12}>
              {user?.tags?.map((item, id) => (
                <Flex align="center" gap={5} key={id}>
                  <IconCheck size={5} className={styles.interestsCheckIcon} />
                  <Text className={styles.pathText}>{item}</Text>
                </Flex>
              ))}
            </Flex>
          )}
        </Box>

        <Box mt={isEditing ? 50 : 0} mb={16}>
            <Flex justify="space-between" mb={isMobile ? 10 : 20}>
              <Title>{t('formLabels.entity')}</Title>
            </Flex>

          {isEditing ? (
            <Box>
              <Controller
                control={form.control}
                name="entity"
                render={({
                  field: { value, onChange },
                  fieldState: { error }
                }) => {
                  setCompanyName(value)

                  return (
                    <RadioGroup
                      value={value.toString()}
                      error={error?.message}
                      onChange={(e) => onChange(+e)}
                      label={t('formLabels.entity')}
                      required
                    >
                      <Radio
                        value={'1'}
                        label={t('pages.signup.interested.individual')}
                      />
                      <Radio
                        value={'2'}
                        label={t('pages.signup.interested.company')}
                      />
                    </RadioGroup>
                  )
                }}
              />
            </Box>
          ) : (
            <Flex gap={5} align='center'>
              <IconCheck size={12} className={styles.entityCheckIcon} />
              <Text fz={12} className={styles.pathText}>{t(`pages.signup.interested.${user?.entity?.toLowerCase()}`)}</Text>
            </Flex>
          )}
        </Box>

        <Box>
          {isEditing && companyName == 2 ? (
            <>
              <Input.Label required>
                اسم الشركة
              </Input.Label>
              <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120}  mb={isMobile ? 20 : 40}>
                <Controller
                  control={form.control}
                  name="company_name"
                  rules={{
                    required: t('errorMessages.required'),
                  }}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error }
                  }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      placeholder={t('placeholders.companyName')}
                      required
                      disabled={isLoading}
                    />
                  )}
                />
              </SimpleGrid>
            </>
          ) : (
            <>
              {user?.entity_id === 2 && form.getValues('entity') === 2 && (<Text fz={12}>{user?.company_name}</Text>)}
            </>
          )}
        </Box>
      </Form>
    </InView>
  );
};

export default InterestedPersonalInfo;
