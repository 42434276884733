import * as Yup from 'yup';

import i18n from 'src/configs/i18n';
import { idRegExp } from 'src/constants/regExp';
import { TStepperConfig } from 'src/hooks/useStepper';
import {
  TCompetitionApplication,
  TCompetitionParticipant,
  TCompetitionRegistrationForm
} from 'src/modules/competition/types';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import { StepProps } from './types';

export const competitionRegistrationSteps: TStepperConfig<StepProps> = [
  { component: Step1 },
  { component: Step2 },
  { component: Step3 },
  { component: Step4 }
];

export const competitionRegistrationSchema = Yup.object({
  employer: Yup.string()
    .required(i18n.t('errorMessages.required')),
  city_id: Yup.mixed()
    .required(i18n.t('errorMessages.required')),
  cv: Yup.mixed()
    .required(i18n.t('errorMessages.required')),
  innovation_data: Yup.object({
    innovation_name: Yup.string()
      .required(i18n.t('errorMessages.required')),
    innovation_type_id: Yup.array()
      .of(Yup.string())
      .min(1, i18n.t('errorMessages.required')),
    path_id: Yup.mixed()
      .required(i18n.t('errorMessages.required')),
    description: Yup.string()
      .required(i18n.t('errorMessages.required'))
      .min(200, i18n.t('formTexts.innovationDescription'))
      .max(500, i18n.t('formTexts.innovationDescription')),
    innovation_attachment: Yup.string()
      .required(i18n.t('errorMessages.required'))
  }).required(),
  participating_role_id: Yup.mixed()
    .required(i18n.t('errorMessages.required')),
  participating_type_id: Yup.string()
    .required(i18n.t('errorMessages.required')),
  participating_percentage: Yup.mixed()
    .when('participating_type_id', {
      is: '2',
      then: Yup.number()
        .required(i18n.t('errorMessages.required'))
    }),
  total_percentage: Yup.mixed()
    .when('participating_type_id', {
      is: '2',
      then: Yup.number()
        .required(i18n.t('errorMessages.required'))
        .test({
          name: 'totalPercentage-100',
          test(value, ctx) {
            if (value !== 100) {
              return ctx.createError({ message: i18n.t('errorMessages.totalPercentage') });
            }
            return true;
          }
        })
    }),
  number_of_particepants: Yup.mixed()
    .when('participating_type_id', {
      is: '2',
      then: Yup.mixed()
        .required(i18n.t('errorMessages.required'))
    }),
  partcipants_data: Yup.mixed()
    .when('participating_type_id', {
      is: '2',
      then: Yup.array().of(
        Yup.object({
          first_name: Yup.string()
            .required(i18n.t('errorMessages.required')),
          second_name: Yup.string()
            .required(i18n.t('errorMessages.required')),
          last_name: Yup.string()
            .required(i18n.t('errorMessages.required')),
          nationality_id: Yup.mixed()
            .required(i18n.t('errorMessages.required')),
          user_identity: Yup.string()
            .required(i18n.t('errorMessages.required'))
            .matches(idRegExp, i18n.t('errorMessages.idOrIqamaNumber')),
          email: Yup.string()
            .required(i18n.t('errorMessages.required'))
            .email(i18n.t('errorMessages.invalidEmail')),
          participation_percentage: Yup.number()
            .required(i18n.t('errorMessages.required'))
        }).required()
      )
    }),
  number_of_applications: Yup.mixed()
    .required(i18n.t('errorMessages.required')),
  application_data: Yup.array().of(
    Yup.object({
      description: Yup.string()
        .required(i18n.t('errorMessages.required')),
      attachment: Yup.mixed()
        .required(i18n.t('errorMessages.required')),
      impact: Yup.string()
        .required(i18n.t('errorMessages.required')),
      economic_impact: Yup.string()
        .required(i18n.t('errorMessages.required')),
      economic_impact_attachment: Yup.mixed()
        .required(i18n.t('errorMessages.required')),
      completness: Yup.string()
        .required(i18n.t('errorMessages.required')),
      completness_attachment: Yup.mixed()
        .required(i18n.t('errorMessages.required')),
      attached_video: Yup.mixed()
    }).required()
  ),
  is_agreed_work_originality: Yup.boolean()
    .required(i18n.t('errorMessages.required'))
    .oneOf([true], i18n.t('errorMessages.required')),
  is_agreed_for_sharing_his_data: Yup.boolean()
    .required(i18n.t('errorMessages.required'))
    .oneOf([true], i18n.t('errorMessages.required')),
  is_agreed_for_terms_and_conditions: Yup.boolean()
    .required(i18n.t('errorMessages.required'))
    .oneOf([true], i18n.t('errorMessages.required')),
  is_agreed_for_regukations_and_rules: Yup.boolean()
    .required(i18n.t('errorMessages.required'))
    .oneOf([true], i18n.t('errorMessages.required'))
}).required();

export const DEFAULT_NUMBER_OF_APPLICATIONS = '1';
export const DEFAULT_NUMBER_OF_PARTICIPANTS = '2';
export const GROUP_TYPE_ID = '2';
export const INDIVIDUAL_TYPE_ID = '1';

export const participantDefaultValues: TCompetitionParticipant = {
  first_name: '',
  second_name: '',
  last_name: '',
  nationality_id: null,
  user_identity: '',
  email: '',
  participation_percentage: 50
};

export const applicationDefaultValues: TCompetitionApplication = {
  application_id: undefined,
  description: '',
  attachment: null,
  impact: '',
  economic_impact: '',
  economic_impact_attachment: null,
  completness: '',
  completness_attachment: null,
  attached_video: null
};

export const competitionRegistrationFormDefaultValues: TCompetitionRegistrationForm = {
  employer: '',
  city_id: null,
  cv: null,
  innovation_data: {
    innovation_name: '',
    innovation_type_id: [],
    path_id: null,
    description: '',
    innovation_attachment: null
  },
  participating_role_id: null,
  participating_type_id: INDIVIDUAL_TYPE_ID,
  participating_percentage: 50,
  number_of_particepants: DEFAULT_NUMBER_OF_PARTICIPANTS,
  partcipants_data: [participantDefaultValues],
  total_percentage: 100,
  number_of_applications: DEFAULT_NUMBER_OF_APPLICATIONS,
  application_data: [applicationDefaultValues],
  is_agreed_work_originality: false,
  is_agreed_for_sharing_his_data: false,
  is_agreed_for_terms_and_conditions: false,
  is_agreed_for_regukations_and_rules: false
};
