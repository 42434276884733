import { FC } from 'react';

import { Input, Rating as MantineRating } from '@mantine/core';
import { IconStar } from '@tabler/icons-react';

type Props = {
  label?: string;
  error?: string;
  required?: boolean;
  value?: number;
  onChange?: (value: number) => void;
};

const Rating: FC<Props> = ({
  label,
  error,
  required,
  value,
  onChange
}) => (
  <Input.Wrapper
    label={label}
    error={error}
    required={required}
  >
    <MantineRating
      value={value}
      onChange={onChange}
      emptySymbol={<IconStar color="#0F4439" size={20} />}
      fullSymbol={<IconStar fill="#0F4439" color="#0F4439" size={20} />}
    />
  </Input.Wrapper>
);

export default Rating;
