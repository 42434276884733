import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  TCompetitionRegistrationResponse,
  TCompetitionSubmitRequest,
  TCompetitionSubmitResponse,
  TCompetitionUpdateRequest,
  TCompetitionUpdateResponse
} from 'src/api/competition/types';
import queryClient from 'src/configs/query-client';
import {
  TCompetition,
  TCompetitionRegistration,
  TParticipantsApprovalInfo,
  TUserCompetitionInfo
} from 'src/modules/competition/types';
import { Paths } from 'src/router/constants';
import { router } from 'src/router/router';
import { ErrorResponse } from 'src/types/api';

import {
  createParticipantRequest,
  getCurrentCompetition, getParticipantRequestApprovesInfo,
  getUserParticipatingRequestsInfo,
  submitParticipantRequest,
  updateParticipantRequest,
  updateParticipantRequestMissingInfo
} from './api';
import { USER_PARTICIPATING_REQUESTS_QUERY_KEY, USER_PARTICIPANT_REQUEST_APPROVAL_QUERY_KEY } from './constants';

export const useCurrentCompetitionQuery = () => {
  return useQuery<TCompetition | null>({
    queryKey: ['competition'],
    queryFn: async () => {
      return getCurrentCompetition().then((res) => {
        const active = res.data.find((competition) => competition.isActive);
        return active || null;
      });
    }
  });
};

export const useUserParticipatingRequestsQuery = () => {
  return useQuery<TUserCompetitionInfo[]>({
    queryKey: [USER_PARTICIPATING_REQUESTS_QUERY_KEY],
    queryFn: async () => {
      return getUserParticipatingRequestsInfo().then((res) => res.data);
    }
  });
};

export const useCreateParticipatingRequestMutation = (args?: {
  onError?: (error: ErrorResponse) => void;
}) => {
  return useMutation<TCompetitionRegistrationResponse, ErrorResponse, TCompetitionRegistration>({
    mutationFn: createParticipantRequest,
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([USER_PARTICIPATING_REQUESTS_QUERY_KEY]);
      showNotification({
        message: response.message,
        color: 'teal',
        icon: <IconCheck size={20} />
      });
    },
    onError: args?.onError
  });
};

export const useUpdateParticipatingRequestMutation = (args?: {
  onError?: (error: ErrorResponse) => void;
}) => {
  return useMutation<TCompetitionUpdateResponse, ErrorResponse, TCompetitionUpdateRequest>({
    mutationFn: updateParticipantRequest,
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([USER_PARTICIPATING_REQUESTS_QUERY_KEY]);
      showNotification({
        message: response.message,
        color: 'teal',
        icon: <IconCheck size={20} />
      });
    },
    onError: args?.onError
  });
};

export const useSubmitParticipatingRequestMutation = (args?: {
  onError?: (error: ErrorResponse) => void;
}) => {
  return useMutation<TCompetitionSubmitResponse, ErrorResponse, TCompetitionSubmitRequest>({
    mutationFn: submitParticipantRequest,
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([USER_PARTICIPATING_REQUESTS_QUERY_KEY]);
      showNotification({
        message: response.message,
        color: 'teal',
        icon: <IconCheck size={20} />
      });
      router.navigate(Paths.account);
    },
    onError: args?.onError
  });
};

export const useUpdateParticipatingRequestMissingInfoMutation = (args?: {
  onError?: (error: ErrorResponse) => void;
}) => {
  return useMutation<TCompetitionSubmitResponse, ErrorResponse, TCompetitionSubmitRequest>({
    mutationFn: updateParticipantRequestMissingInfo,
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([USER_PARTICIPATING_REQUESTS_QUERY_KEY]);
      showNotification({
        message: response.message,
        color: 'teal',
        icon: <IconCheck size={20} />
      });
      router.navigate(Paths.account);
    },
    onError: args?.onError
  });
};

export const useParticipantRequestApprovesQuery = ({
  requestId,
  enabled
}: {
  requestId: string;
  enabled: boolean;
}) => {
  return useQuery<TParticipantsApprovalInfo>({
    queryKey: [USER_PARTICIPANT_REQUEST_APPROVAL_QUERY_KEY, requestId],
    queryFn: () => getParticipantRequestApprovesInfo(requestId),
    enabled
  });
};
