import { FC, useEffect, useState } from 'react';

import { Carousel, Embla } from '@mantine/carousel';
import { ActionIcon, Image } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';

import { GetPhotoGalleryResponse } from 'src/api/photo-gallery/types';
import ModalPage from 'src/components/ModalPage';

import styles from './styles.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  data: GetPhotoGalleryResponse;
  openIndex: number;
};

const PhotoAlbumDetails: FC<Props> = ({
  isOpen,
  onClose,
  data,
  openIndex
}) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(openIndex);
  const [carouselRef, setCarouselRef] = useState<Embla | null>(null);
  const [thumbRef, setThumbRef] = useState<Embla | null>(null);
  const isTablet =  useMediaQuery('(min-width: 601px) and (max-width:1024px');

  useEffect(() => {
    if (!thumbRef || !carouselRef) {
      return;
    }
    carouselRef.scrollTo(selectedIndex);
    thumbRef.scrollTo(selectedIndex);
  }, [selectedIndex, openIndex, thumbRef, carouselRef]);

  const handleSlideChange = (index: number) => {
    setSelectedIndex(index);
  };

  return (
    <ModalPage
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={styles.wrapper}>
        <ActionIcon
          className={styles.closeIcon}
          onClick={onClose}
          pos="absolute"
          right="10%"
          top={-40}
        >
          <IconX />
        </ActionIcon>

        <Carousel
          getEmblaApi={setCarouselRef}
          onSlideChange={handleSlideChange}
          initialSlide={openIndex}
          mb={40}
          classNames={{ controls: styles.controls }}
          className={styles.imagesCarousel}
        >
          {data.data.map((item, index) => (
            <Carousel.Slide
              key={`slide_${index}`}
              className={styles.slideWrapper}
            >
              <div className={styles.slide}>
                <Image
                  src={item.image_path}
                  width="100%"
                  mb={20}
                  height={isTablet ? 'auto' : '55vh'}
                  fit="contain"
                  className={styles.image}
                />
                <div className={styles.previewSlideText}>
                  <p>{item.summary}</p>
                  <p className={styles.amountImages}>
                    {t('pages.mediaCenter.photoGallery.pictureNumber', {
                      number: index + 1,
                      total: data.meta.total
                    })}
                  </p>
                </div>
              </div>
            </Carousel.Slide>
          ))}
        </Carousel>

        <Carousel
          getEmblaApi={setThumbRef}
          withControls={false}
          containScroll="keepSnaps"
          dragFree
          slideGap={20}
          className={styles.thumbsCarousel}
        >
          {data.data.map((image, index) => (
            <Carousel.Slide
              key={`thumb_${index}`}
              className={clsx(styles.thumbsSlideWrapper, {
                [styles.selectedThumb]: index === selectedIndex
              })}
              onClick={() => handleSlideChange(index)}
              size={200}
            >
              <Image
                src={image.image_path}
                width="100%"
                height={150}
                fit="cover"
              />
            </Carousel.Slide>
          ))}
        </Carousel>
      </div>
    </ModalPage>
  );
};

export default PhotoAlbumDetails;
