import { FC, useEffect } from 'react';

import { Box } from '@mantine/core';
import { NavigationProgress } from '@mantine/nprogress';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';

import { useUserQuery } from 'src/api/user/hooks';
import { I18N_LANG_TOKEN } from 'src/constants/i18n';
import MantineProvider from 'src/providers/MantineProvider';
import ModalsProvider from 'src/providers/ModalsProvider';
import NotificationsProvider from 'src/providers/NotificationsProvider';
import QueryClientProvider from 'src/providers/QueryClientProvider';
import { Paths } from 'src/router/constants';
import PreviousCompetition from './components/PreviousCompetition';

const Root: FC = () => {
  const { isLoading } = useUserQuery();
  const { pathname } = useLocation();

  useEffect(() => {
    const lang = localStorage.getItem(I18N_LANG_TOKEN) || 'ar';
    localStorage.setItem(I18N_LANG_TOKEN, lang);
    document.documentElement.lang = lang;
    document.documentElement.dir = lang === 'ar' ? 'rtl' : 'ltr';
  }, []);

  useEffect(() => {
    // todo: temporary solution, remove it in future
    const lang = localStorage.getItem(I18N_LANG_TOKEN) || 'ar';

    if (lang !== 'ar' && pathname !== Paths.homepage) {
      localStorage.setItem(I18N_LANG_TOKEN, 'ar');
      window.location.reload();
    }
  }, [pathname]);

  if (isLoading) {
    return <Box mih="100vh" />;
  }

  return (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  );
};

const App: FC = () => (
  <QueryClientProvider>
    <MantineProvider>
      <ModalsProvider>
        <NotificationsProvider>
          <NavigationProgress autoReset size={4} />
          <Root />
          <PreviousCompetition />
        </NotificationsProvider>
      </ModalsProvider>
    </MantineProvider>
  </QueryClientProvider>
);

export default App;
