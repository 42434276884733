import { useQuery } from '@tanstack/react-query';

import { getCompetitionPaths } from './api';
import { CompetitionPath } from './types';

export const useCompetitionPathsQuery = () => {
  return useQuery<CompetitionPath[]>({
    queryKey: ['competition-paths'],
    queryFn: async () => getCompetitionPaths().then((res) => res.data)
  });
};
