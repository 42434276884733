import { createBrowserRouter, Navigate } from 'react-router-dom';

import App from 'src/App';
import FormLayout from 'src/layouts/FormLayout';
import MainLayout from 'src/layouts/MainLayout';
import TimerLayout from 'src/layouts/TimerLayout';
import WinnerLayout from 'src/layouts/WinnerLayout';
import AboutPrize from 'src/pages/AboutPrize';
import Account from 'src/pages/Account';
import ApproveParticipating from 'src/pages/ApproveParticipating';
import CompetitionConditions from 'src/pages/CompetitionConditions';
import CompetitionOfTheYear from 'src/pages/CompetitionOfTheYear';
import CompetitionPaths from 'src/pages/CompetitionPaths';
import CompetitionRegistration from 'src/pages/CompetitionRegistration';
import ContactUs from 'src/pages/ContactUs';
import ErrorPage from 'src/pages/ErrorPage';
import EvaluationCriteria from 'src/pages/EvaluationCriteria';
import FAQ from 'src/pages/FAQ';
import GeneralSecretariat from 'src/pages/GeneralSecretariat';
import HomePage from 'src/pages/Homepage/Homepage';
import Innovation from 'src/pages/Innovation';
import MediaCenter from 'src/pages/MediaCenter';
import News from 'src/pages/News';
import NewsDetails from 'src/pages/NewsDetails';
import PhotoGallery from 'src/pages/PhotoGallery';
import PrivacyPolicy from 'src/pages/PrivacyPolicy';
import ResetPassword from 'src/pages/ResetPassword';
import Results from 'src/pages/Results';
import Signup from 'src/pages/Signup';
import SupervisoryBoard from 'src/pages/SupervisoryBoard';
import TermsAndConditions from 'src/pages/TermsAndConditions';
import TicketDetails from 'src/pages/TicketDetails';
import VideoDetails from 'src/pages/VideoDetails';
import VideoGallery from 'src/pages/VideoGallery';
import Winners from 'src/pages/Winners';
import AuthWrapper from 'src/router/AuthWrapper';
import NoAuthWrapper from 'src/router/NoAuthWrapper';

import { Paths } from './constants';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: Paths.error,
        element: <ErrorPage />
      },
      {
        element: <TimerLayout />,
        children: [
          {
            path: Paths.homepage,
            element: <HomePage />
          },
          {
            path: Paths.aboutPrize,
            element: <AboutPrize />
          },
          {
            path: Paths.competitionPaths,
            element: <CompetitionPaths />
          }
        ]
      },
      {
        element: <MainLayout />,
        children: [
          {
            path: Paths.faq,
            element: <FAQ />
          },
          {
            path: Paths.termsAndConditions,
            element: <TermsAndConditions />
          },
          {
            path: Paths.privacyPolicy,
            element: <PrivacyPolicy />
          },
          {
            path: Paths.supervisoryBoard,
            element: <SupervisoryBoard />
          },
          {
            path: Paths.generalSecretariat,
            element: <GeneralSecretariat />
          },
          {
            path: Paths.mediaCenter,
            element: <MediaCenter />,
            children: [
              {
                index: true,
                element: <Navigate to={Paths.news} replace />
              },
              {
                path: Paths.news,
                element: <News />
              },
              {
                path: Paths.photoGallery,
                element: <PhotoGallery />
              },
              {
                path: Paths.videoGallery,
                element: <VideoGallery />,
                children: [
                  {
                    path: Paths.videoDetails,
                    element: <VideoDetails />
                  }
                ]
              }
            ]
          },
          {
            path: Paths.newsDetails,
            element: <NewsDetails />
          },
          {
            path: Paths.competitionConditions,
            element: <CompetitionConditions />
          },
          {
            path: Paths.evaluationCriteria,
            element: <EvaluationCriteria />
          }
        ]
      },
      {
        element: <WinnerLayout />,
        children: [
          {
            path: Paths.winnersCompetition,
            element: <CompetitionOfTheYear />
          }
        ]
      },
      {
        element: <MainLayout />,
        children: [
          {
            path: Paths.winners,
            element: <Winners />
          },
          {
            path: Paths.results,
            element: <Results />
          },
          {
            path: Paths.innovation,
            element: <Innovation />
          }
        ]
      },
      {
        element: <FormLayout />,
        children: [
          {
            path: Paths.approveParticipating,
            element: <ApproveParticipating />
          }
        ]
      },

      {
        element: <AuthWrapper />,
        children: [
          {
            element: <MainLayout />,
            children: [
              {
                path: Paths.account,
                element: <Account />
              },
              {
                path: Paths.contactUs,
                element: <ContactUs />
              },
              {
                path: Paths.ticketDetails,
                element: <TicketDetails />
              }
            ]
          },
          {
            element: <FormLayout />,
            children: [
              {
                path: Paths.competitionRegistration,
                element: <CompetitionRegistration />
              }
            ]
          }
        ]
      },

      {
        element: <NoAuthWrapper />,
        children: [
          {
            path: Paths.resetPassword,
            element: <ResetPassword />
          },
          {
            element: <MainLayout />,
            children: [
              {
                path: Paths.signup,
                element: <Signup />
              }
            ]
          },
          {
            element: <MainLayout />,
            children: [
              {
                path: Paths.interestedSignup,
                element: <Signup isInterested />
              }
            ]
          }
        ]
      },

      {
        path: '*',
        element: <Navigate to={Paths.homepage} />
      }
    ]
  }
]);
