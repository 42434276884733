import { FC } from 'react';

import { Flex, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ParticipantName from 'src/components/CompetitionRegistrationForm/Step2/ParticipantName';
import PercentageInput from 'src/components/PercentageInput';
import { getParticipantFullName } from 'src/helpers/competition';
import { TCompetitionRegistrationForm } from 'src/modules/competition/types';

type Props = {
  form: UseFormReturn<TCompetitionRegistrationForm>;
  participantIndex: number;
  isDisabled: boolean;
};

const ParticipantPercentage: FC<Props> = ({
  form,
  participantIndex,
  isDisabled
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 600px)');

  const participantInfo = useWatch({
    control: form.control,
    name: `partcipants_data.${participantIndex}`
  });

  return (
    <Flex
      justify="space-between"
      align={isMobile ? 'end' : 'center'}
      mb={20}
    >
      <Flex direction="column">
        {isMobile ? <Text align="start" fz={12}>{t('placeholders.participantPercentage')}</Text> : null}
        <ParticipantName>
          {getParticipantFullName(participantInfo, participantIndex + 2)}
        </ParticipantName>
      </Flex>

      <Controller
        control={form.control}
        name={`partcipants_data.${participantIndex}.participation_percentage`}
        render={({
          field: { value, onChange }
        }) => (
          <PercentageInput
            label={t('placeholders.participantPercentage')}
            withLabel={!isMobile}
            value={value}
            onChange={onChange}
            disabled={isDisabled}
          />
        )}
      />
    </Flex>
  );
};

export default ParticipantPercentage;
