import { FC, useMemo } from 'react';

import { Button, Image, LoadingOverlay } from '@mantine/core';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useResetPasswordMutation } from 'src/api/user/hooks';
import logo from 'src/assets/svg/logo-row.svg';
import Form from 'src/components/Form';
import PasswordInput from 'src/components/PasswordInput';
import TextInput from 'src/components/TextInput';
import { passwordRegExp } from 'src/constants/regExp';
import { parseApiErrors } from 'src/helpers/forms';
import { openFormErrorModal } from 'src/helpers/modals';

import styles from './ResetPassword.module.scss';

type TResetPasswordForm = {
  password: string;
  password_confirmation: string;
};

const ResetPassword: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const searchData = useMemo(() => ({
    email: searchParams.get('email') || '',
    token: searchParams.get('token') || ''
  }), [searchParams]);

  const form = useForm<TResetPasswordForm>({
    defaultValues: {
      password: '',
      password_confirmation: ''
    }
  });

  const { mutate: resetPassword, isLoading: isResetPasswordLoading } = useResetPasswordMutation({
    onError: (error) => parseApiErrors(error, {
      form,
      onGlobalError: (message) => openFormErrorModal({ message })
    })
  });

  const handleSubmit: SubmitHandler<TResetPasswordForm> = (data) => {
    resetPassword({
      ...data,
      ...searchData
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.paper}>
        <LoadingOverlay visible={isResetPasswordLoading} />

        <Image src={logo} width={300} height={58} withPlaceholder fit="contain" />
        <Form className={styles.form} onSubmit={form.handleSubmit(handleSubmit)}>
          <TextInput
            value={searchData.email}
            label={t('formLabels.email')}
            placeholder={t('placeholders.email')}
            disabled
          />

          <Controller
            control={form.control}
            name="password"
            rules={{
              required: t('errorMessages.required'),
              pattern: {
                value: passwordRegExp,
                message: t('errorMessages.invalidPassword')
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
              formState: { isSubmitted }
            }) => (
              <PasswordInput
                value={value}
                onChange={(value) => {
                  onChange(value);

                  if (isSubmitted && form.getValues('password_confirmation')) {
                    form.trigger('password_confirmation');
                  }
                }}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.password')}
                placeholder={t('placeholders.password')}
                required
              />
            )}
          />

          <Controller
            control={form.control}
            name="password_confirmation"
            rules={{
              required: t('errorMessages.required'),
              validate: (value) => {
                if (form.getValues('password') !== value) {
                  return t('errorMessages.passwordsNotMatch');
                }

                return true;
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <PasswordInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.confirmPassword')}
                placeholder={t('placeholders.password')}
                required
              />
            )}
          />
          <Button color="dark" type="submit" size="md" fz={14}>
            {t('common.resetPassword')}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
