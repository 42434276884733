import { FC } from 'react';

import { Image } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { PhotoGallery } from 'src/api/photo-gallery/types';

import styles from './styles.module.scss';

type Props = {
  item: PhotoGallery;
  onClick: (id: string) => void;
};

const PhotoCard: FC<Props> = ({
  item,
  onClick
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper} onClick={() => onClick(item.id)}>
      <Image
        src={item.image_path}
        className={styles.cardPhoto}
        width="100%"
        fit="cover"
        height={235}
        withPlaceholder
      />
      <div className={styles.contentWrapper}>
        <h4 className={styles.cardTitle}>{item.summary}</h4>
        <p className={styles.openAlbumButton}>
          {t('common.openAlbum')}
        </p>
      </div>
    </div>
  );
};

export default PhotoCard;
