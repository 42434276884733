import { create } from 'zustand';

import { User } from 'src/api/user/types';

type AuthState = {
  isLoggedIn: boolean;
  user: User | null;
  setUser: (user: User | null) => void;
};

export const useAuthStore = create<AuthState>((set) => ({
  isLoggedIn: false,
  user: null,

  setUser: (user: User | null) => set(() => ({ user }))
}));
