import { FC } from 'react';

import { Text, Flex, Divider } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { IconUsers } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import React from 'react';

export type TInnovationPatricipantsModalProps = {
  innovationUsers: string[];
};

const InnovationPatricipantsModal: FC<ContextModalProps<TInnovationPatricipantsModalProps>> = ({
  innerProps: { innovationUsers }
}) => {
  const { t } = useTranslation();

  return (
    <Flex direction='column' gap={24}>
      <Flex gap={4} align='center'>
        <IconUsers size={18} color='#3ca1d4' fill='#3ca1d4' />
        <Text color='#3ca1d4'>{t('pages.winner.participantsName')}</Text>
      </Flex>

      <Flex direction='column' gap={6}>
        {innovationUsers.map((user, id) => (
          <React.Fragment key={id}>
            <Text>{user}</Text>
            <Divider />
          </React.Fragment>
        ))}
      </Flex>
    </Flex>
  );
};

export default InnovationPatricipantsModal;
