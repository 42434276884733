import { useQuery } from '@tanstack/react-query';

import { getGenders } from './api';
import { Gender } from './types';

export const useGendersQuery = () => {
  return useQuery<Gender[]>({
    queryKey: ['genders'],
    queryFn: async () => getGenders().then((res) => res.data)
  });
};
