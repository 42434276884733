import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useTimer } from 'src/hooks/useTimer';

import styles from './styles.module.scss';
import TimeSegment from './TimeSegment';

type Props = {
  endTime: string | undefined;
};

const Timer: FC<Props> = ({ endTime }) => {
  const { t } = useTranslation();

  const { days, hours, minutes, seconds } = useTimer(new Date(endTime ?? ''));

  return (
    <div className={styles.wrapper}>
      <TimeSegment
        time={days > 0 ? days : 0}
        label={days === 1 ? t('common.day') : t('common.days')}
      />
      <TimeSegment
        time={hours > 0 ? hours : 0}
        label={hours === 1 ? t('common.hour') : t('common.hours')}
      />
      <TimeSegment
        time={minutes > 0 ? minutes : 0}
        label={minutes === 1 ? t('common.minute') : t('common.minutes')}
      />
      <TimeSegment
        time={seconds > 0 ? seconds : 0}
        label={seconds === 1 ? t('common.second') : t('common.seconds')}
      />
    </div>
  );
};

export default Timer;
