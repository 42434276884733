import { showNotification } from '@mantine/notifications';
import { completeNavigationProgress, startNavigationProgress } from '@mantine/nprogress';
import { IconCheck } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from 'src/configs/query-client';
import { API_TOKEN_KEY, IS_INTERESTED_USER, IS_USER } from 'src/constants/api';
import { openLoginModal } from 'src/helpers/modals';
import { showErrorNotification, showSuccessNotification } from 'src/helpers/notifications';
import { normalizeSignupData, normalizeInterestedSignupData } from 'src/modules/auth/normalizers';
import { Paths } from 'src/router/constants';
import { router } from 'src/router/router';
import { useAuthStore } from 'src/store/useAuthStore';
import { ErrorResponse, SuccessResponse } from 'src/types/api';

import { forgetPassword, getInterestedUserData, getUserData, resetPassword, updateInterestedUser, updateUser, userInterestedRegister, userLogin, userLogout, userRegister } from './api';
import {
  TForgetPasswordRequest,
  TForgetPasswordResponse, TResetPasswordRequest, TResetPasswordResponse,
  UpdateInterestedUserData,
  User, UserEditData,
  UserInterestedRegisterData,
  UserLoginData,
  UserLoginResponse,
  UserRegisterData
} from './types';
import { useNavigate } from 'react-router-dom';

export const useUserQuery = () => {
  const { setUser } = useAuthStore();

  return useQuery<User | null>({
    queryKey: ['user'],
    queryFn: async () => {
      startNavigationProgress();
      const apiToken = localStorage.getItem(API_TOKEN_KEY);
      const isInterested = localStorage.getItem(IS_INTERESTED_USER);
      const userID = localStorage.getItem(IS_USER);

      if (!apiToken) {
        setUser(null);
        return null;
      }

      if (!userID && isInterested === 'false') {
        setUser(null);
        return null;
      }

      if (isInterested === 'false') {
        const user = await getUserData();
        setUser(user);
        return user;
      } else {
        const user = await getInterestedUserData();
        setUser(user);
        return user;
      }
    },
    onSettled: () => {
      completeNavigationProgress();
    }
  });
};

export const useRegisterUserMutation = (props?: {
  onSuccess?: (response: SuccessResponse, data: UserRegisterData) => void;
  onError?: (error: ErrorResponse) => void;
}) => {

  return useMutation<SuccessResponse, ErrorResponse, UserRegisterData>({
    mutationFn: async (data) => userRegister(normalizeSignupData(data)),
    onSuccess: async (response, data) => {
      showNotification({
        message: response.message,
        color: 'teal',
        icon: <IconCheck size={20} />
      });
      if (props?.onSuccess) {
        props.onSuccess(response, data);
      }
    },
    onError: props?.onError
  });
};

export const useRegisterInterestedUserMutation = (props?: {
  onSuccess?: (response: SuccessResponse, data: UserInterestedRegisterData) => void;
  onError?: (error: ErrorResponse) => void;
}) => {
  const { mutateAsync: login } = useLoginUserMutation();
  const navigate = useNavigate();

  return useMutation<SuccessResponse, ErrorResponse, UserInterestedRegisterData>({
    mutationFn: async (data) => userInterestedRegister(normalizeInterestedSignupData(data)),
    onSuccess: async (response, data) => {
      navigate(Paths.homepage);
      await login({ email: data.email, password: data.password });

      if (props?.onSuccess) {
        props.onSuccess(response, data);
      }
    },
    onError: props?.onError
  });
};

export const useLoginUserMutation = (args?: {
  onSuccess?: (response: UserLoginResponse) => void;
  onError?: (error: ErrorResponse) => void;
  onMutate?: () => void;
}) => {
  const { setUser } = useAuthStore();
  const navigate = useNavigate();

  return useMutation<UserLoginResponse, ErrorResponse, UserLoginData>({
    mutationFn: async (data) => {
      const response = await userLogin(data);
      localStorage.setItem(API_TOKEN_KEY, `${response.data.token_type} ${response.data.access_token}`);
      localStorage.setItem(IS_INTERESTED_USER, `${response.data.user.is_interested_user}`);
      localStorage.setItem(IS_USER, `${response.data.user.id}`)
      if (!response.data.user.is_interested_user) {
        const user = await getUserData();
        setUser(user);
        navigate(Paths.homepage);
      } else {
        const user = await getInterestedUserData();
        setUser(user);
        navigate(Paths.homepage);
      };

      return response;
    },
    onSuccess: async (response) => {
      if (args?.onSuccess) {
        args.onSuccess(response);
      }
    },
    onMutate: args?.onMutate,
    onError: args?.onError
  });
};

export const useUpdateInterestedUserMutation = (props?: {
  onSuccess?: (response: SuccessResponse, data: UpdateInterestedUserData) => void;
  onError?: (error: ErrorResponse) => void;
}) => {
  const { setUser } = useAuthStore();

  return useMutation<SuccessResponse, ErrorResponse, UpdateInterestedUserData>({
    mutationFn: async (data) => updateInterestedUser(data),
    onSuccess: async (response, data) => {
      showSuccessNotification({ message: 'Profile info changed succesfully' });
      const user = await getInterestedUserData();
      setUser(user);

      if (props?.onSuccess) {
        props.onSuccess(response, data);
      }
    },
    onError: props?.onError
  });
}

export const useLogoutUserMutation = () => {
  const { setUser } = useAuthStore();

  return useMutation<SuccessResponse>({
    mutationFn: () => {
      startNavigationProgress();
      return userLogout();
    },
    onSuccess: async () => {
      localStorage.removeItem(API_TOKEN_KEY);
      localStorage.removeItem(IS_INTERESTED_USER);
      localStorage.removeItem(IS_USER);
      setUser(null);
      router.navigate(Paths.homepage);
      await queryClient.clear();
      completeNavigationProgress();
    },
    onError: (err) => {
      showErrorNotification({ message: 'Something went wrong' })
    }
  });
};

export const useUpdateUserMutation = (args?: {
  onSuccess?: (response: SuccessResponse) => void;
  onError?: (error: ErrorResponse) => void;
}) => {
  return useMutation<SuccessResponse, ErrorResponse, UserEditData>({
    mutationFn: async (data) => {
      return updateUser(data);
    },
    onSuccess: async (response) => {
      showNotification({
        message: response.message,
        color: 'teal',
        icon: <IconCheck size={20} />
      });
      await queryClient.invalidateQueries({ queryKey: ['user'] });

      if (args?.onSuccess) {
        args.onSuccess(response);
      }
    },
    onError: args?.onError
  });
};

export const useForgetPasswordMutation = (args?: {
  onSuccess?: (response: TForgetPasswordResponse) => void;
  onError?: (error: ErrorResponse) => void;
}) => {
  return useMutation<TForgetPasswordResponse, ErrorResponse, TForgetPasswordRequest>({
    mutationFn: forgetPassword,
    onSuccess: (response) => {
      showSuccessNotification({ message: response.data?.message || '' });
      if (args?.onSuccess) {
        args.onSuccess(response);
      }
    },
    onError: args?.onError
  });
};

export const useResetPasswordMutation = (args?: {
  onError?: (error: ErrorResponse) => void;
}) => {
  return useMutation<TResetPasswordResponse, ErrorResponse, TResetPasswordRequest>({
    mutationFn: resetPassword,
    onSuccess: (response) => {
      showSuccessNotification({ message: response.data || '' });
      router.navigate(Paths.homepage);
      openLoginModal();
    },
    onError: args?.onError
  });
};

