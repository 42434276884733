import { FC } from 'react';

import { Center, Loader } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import { usePrivacyPolicyQuery } from 'src/api/privacy-policy/hooks';
import Container from 'src/components/Container';
import HTMLParser from 'src/components/HTMLParser';
import Title from 'src/components/Title';

import styles from './styles.module.scss';

const PrivacyPolicy: FC = () => {
  const { t } = useTranslation();
  const { data, isLoading } = usePrivacyPolicyQuery();
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <div className={styles.wrapper}>
      <Container>
        <Title color="dark" spacing={70} isMobile={isMobile}>
          {t('common.privacyPolicy')}
        </Title>
        {isLoading && (
          <Center py={50}>
            <Loader size="lg" />
          </Center>
        )}
        {data && (
          <HTMLParser content={data.text} />
        )}
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
