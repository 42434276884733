import { FC } from 'react';

import { Radio as MantineRadio, RadioProps } from '@mantine/core';

import styles from './Radio.module.scss';

const Radio: FC<RadioProps> = (props) => (
  <MantineRadio
    size="xs"
    classNames={{
      radio: styles.radio,
      body: styles.body,
      icon: styles.icon
    }}
    {...props}
  />
);

export default Radio;
