import { useAllWinnerListQuery } from 'src/api/arbitrating/hooks';
import { Center, Loader } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { useShowHidePageListQuery } from 'src/api/content-management/hooks';
import { router } from 'src/router/router';
import { Paths } from 'src/router/constants';
import WinnersData from 'src/components/WinnersData/WinnersData';

const Winners = () => {
  const [page, setPage] = useState<number>(1);
  const { data: allWinners, isLoading: isAllWinnersLoading } = useAllWinnerListQuery();
  const { data: Content, isLoading: ContentLoading } = useShowHidePageListQuery();
  const productRef = useRef<any>(null);

  const winnersByPage = allWinners?.winners.slice((page - 1) * 5, page * 5);
  const isShow = !ContentLoading && Content?.data.find((item) => item.name === 'Winners in Latest Comp');

  useEffect(() => {
    if (isShow && isShow.is_show === false) {
      router.navigate(Paths.homepage);
    }
  }, [isShow]);

  const scrollFunction = () => {
    if (productRef.current) {
      setTimeout(() => {
        productRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    }
  }

  useEffect(() => {
    const index = allWinners && allWinners.winners.findIndex((item) => item.request_id === router.state.location.hash.substring(1));
    index && index > 0 && setPage(Math.ceil((index + 1) / 5));
    scrollFunction();
  }, [isAllWinnersLoading]);

  useEffect(() => {
    const index = allWinners && allWinners.winners.findIndex((item) => item.request_id === router.state.location.hash.substring(1));
    index && index > 0 && setPage(Math.ceil((index + 1) / 5));
    scrollFunction();
  }, []);

  useEffect(() => {
    scrollFunction();
  }, [page]);

  return (
    <>
      {isAllWinnersLoading ? (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      ) : (
        <WinnersData
          productRef={productRef}
          data={allWinners}
          winnersByPage={winnersByPage}
          page={page}
          setPage={setPage}
        />
      )}
    </>
  );
};

export default Winners;
