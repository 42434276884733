import { useQuery } from '@tanstack/react-query';

import { getEvaluationCriteria } from './api';

export const useEvaluationCriteriaQuery = () => {
  return useQuery({
    queryKey: ['evaluation-criteria'],
    queryFn: getEvaluationCriteria
  });
};
