import { FC, useCallback, useEffect, useMemo } from 'react';

import { ActionIcon, Box, Tooltip } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconAlertCircle } from '@tabler/icons-react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useUpdateParticipatingRequestMissingInfoMutation,
  useUpdateParticipatingRequestMutation
} from 'src/api/competition/hooks';
import CompetitionRegistrationForm from 'src/components/CompetitionRegistrationForm';
import { handleApiErrorResponse, normalizeCompetitionRegistrationRequest } from 'src/helpers/competition';
import { RequestStatus } from 'src/modules/competition/constants';
import { openMissingRequirementsModal } from 'src/modules/competition/helpers';
import { TCompetition, TCompetitionRegistrationForm, TUserCompetitionInfo } from 'src/modules/competition/types';

type Props = {
  competition: TCompetition;
  userRequestInfo: TUserCompetitionInfo;
  form: UseFormReturn<TCompetitionRegistrationForm>;
  isInfoLoading: boolean;
};

const EvaluationStageForm: FC<Props> = ({
  competition,
  userRequestInfo,
  form,
  isInfoLoading
}) => {
  const { t } = useTranslation();
  const isMobile =  useMediaQuery('(max-width: 600px)');
  const isTablet =  useMediaQuery('(min-width: 601px) and (max-width:1024px');

  const { mutate: updateRequest, isLoading: isUpdateRequestLoading } = useUpdateParticipatingRequestMutation({
    onError: (error) => handleApiErrorResponse(error, form)
  });

  useEffect(() => {
    if (
      userRequestInfo.status?.id === RequestStatus.MissingRequirements
      && userRequestInfo.request_comments.fieldCommentList.length
    ) {
      openMissingRequirementsModal(userRequestInfo);
    }
  }, [userRequestInfo]);

  const {
    mutate: updateMissingRequirements,
    isLoading: isSubmitRequestLoading
  } = useUpdateParticipatingRequestMissingInfoMutation({
    onError: (error) => handleApiErrorResponse(error, form)
  });

  const handleSaveForm = useCallback(async () => {
    if (userRequestInfo) {
      const values = form.getValues();
      const normalizedData = normalizeCompetitionRegistrationRequest({
        data: values,
        competitionId: competition.id || ''
      });

      updateRequest({ ...normalizedData, request_id: userRequestInfo.id });
    }
  }, [form, competition.id, userRequestInfo, updateRequest]);

  const handleSubmit = useCallback(() => {
    form.handleSubmit(
      async () => {
        if (userRequestInfo) {
          const values = form.getValues();

          const normalizedData = normalizeCompetitionRegistrationRequest({
            data: values,
            competitionId: competition.id || ''
          });

          updateMissingRequirements({ ...normalizedData, request_id: userRequestInfo.id });
        }
      },
      () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })();
  }, [form, competition.id, userRequestInfo, updateMissingRequirements]);

  const headerStartContent = useMemo(() => {
    if (
      userRequestInfo.status?.id === RequestStatus.MissingRequirements
      && userRequestInfo.request_comments.fieldCommentList.length
    ) {
      return (
        <Box pos="absolute" sx={{ left: isMobile ? 33 : isTablet ?  50 : 0, top: isMobile || isTablet ?  25 :  0 }}>
          <Tooltip label={t('common.missingRequirements')} position="bottom" withArrow>
            <ActionIcon onClick={() => openMissingRequirementsModal(userRequestInfo)}>
              <IconAlertCircle size={22} color="red" />
            </ActionIcon>
          </Tooltip>
        </Box>
      );
    }

    return null;
  }, [userRequestInfo, t,isMobile,isTablet]);

  return (
    <CompetitionRegistrationForm
      form={form}
      competition={competition}
      isLoading={isInfoLoading || isUpdateRequestLoading || isSubmitRequestLoading}
      isDisabled={userRequestInfo.status?.id !== RequestStatus.MissingRequirements}
      onSaveForm={handleSaveForm}
      onSubmit={handleSubmit}
      submitText={t('common.edit')}
      headerStartContent={headerStartContent}
    />
  );
};

export default EvaluationStageForm;
