import { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import Dropdown from 'src/components/Navbar/Dropdown';
import { MenuItem } from 'src/components/Navbar/Dropdown/Dropdown';
import { TNavbarProps } from 'src/components/Navbar/types';
import { I18N_LANG_TOKEN } from 'src/constants/i18n';

import styles from './styles.module.scss';

type TProps = Pick<TNavbarProps, 'navbarVariant'>;

const LanguageDropdown: FC<TProps> = ({ navbarVariant }) => {
  const { t, i18n: { language } } = useTranslation();

  const changeLanguage = (code: string) => {
    localStorage.setItem(I18N_LANG_TOKEN, code);
    window.location.reload();
  };

  const items: MenuItem[] = useMemo(() => {
    const languages: MenuItem[] = [
      {
        id: 'en',
        title: 'English',
        onClick: () => changeLanguage('en')
      },
      {
        id: 'ar',
        title: <span className={styles.arLangText}>العربية</span>,
        onClick: () => changeLanguage('ar')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ];

    return languages.filter((item) => item.id !== language);
  }, [language]);


  return (
    <Dropdown
      title={t('common.language')}
      position="bottom-end"
      navbarVariant={navbarVariant}
      items={items}
    />
  );
};

export default LanguageDropdown;
