import { FC, useEffect, useState } from 'react';

import { Group, Stack, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import dayjs from 'dayjs';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';

import { useForgetPasswordMutation, useLoginUserMutation } from 'src/api/user/hooks';
import { UserLoginData } from 'src/api/user/types';
import { ReactComponent as PasswordIcon } from 'src/assets/svg/password-icon.svg';
import { ReactComponent as UserIcon } from 'src/assets/svg/user-icon.svg';
import Button from 'src/components/Button';
import Form from 'src/components/Form';
import ModalWrapper from 'src/components/ModalWrapper';
import PasswordInput from 'src/components/PasswordInput';
import TextInput from 'src/components/TextInput';
import { emailRegExp } from 'src/constants/regExp';
import { formatNumberToTime } from 'src/helpers/dates';
import { parseApiErrors } from 'src/helpers/forms';
import { openFormErrorModal } from 'src/helpers/modals';
import { Paths } from 'src/router/constants';
import { useForgetPasswordStore } from 'src/store/useForgetPasswordStore';

import styles from './LoginModal.module.scss';

export type LoginModalProps = {
  navigateAfterSuccessPath?: string;
};

const LoginModal: FC<ContextModalProps<LoginModalProps>> = ({
  context,
  id,
  innerProps: { navigateAfterSuccessPath }
}) => {
  const [revalidateOnChange, setRevalidateOnChange] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>();
  const { timestamp, setTimestamp } = useForgetPasswordStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const form = useForm<UserLoginData>({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const { mutate: login, isLoading: isLoginLoading } = useLoginUserMutation({
    onMutate: () => {
      setFormError(undefined);
    },
    onSuccess: () => {
      handleClose();
      if (navigateAfterSuccessPath) {
        navigate(navigateAfterSuccessPath);
      }
    },
    onError: (error) => parseApiErrors(error, {
      form,
      onGlobalError: (message) => setFormError(message)
    })
  });

  const { mutate: forgetPassword, isLoading: isForgetPasswordLoading } = useForgetPasswordMutation({
    onSuccess: () => {
      const timestamp = dayjs().add(2, 'minutes').valueOf();

      setTimestamp(timestamp);
      restart(dayjs(timestamp).toDate());
    },
    onError: (error) => parseApiErrors(error, {
      form,
      onGlobalError: (message) => openFormErrorModal({ message })
    })
  });

  const handleResetPassword = async () => {
    const isEmailValid = await form.trigger('email');
    if (!revalidateOnChange) {
      setRevalidateOnChange(true);
    }
    if (isEmailValid) {
      const email = form.getValues('email');
      forgetPassword({ email });
    }
  };

  useEffect(() => {
    if (timestamp && timestamp <= dayjs().valueOf()) {
      setTimestamp(null);
    }
  }, [timestamp, setTimestamp]);

  const {
    seconds,
    minutes,
    isRunning,
    restart
  } = useTimer({
    expiryTimestamp: timestamp ? dayjs(timestamp).toDate() : dayjs().toDate(),
    autoStart: !!timestamp && timestamp > dayjs().valueOf(),
    onExpire: () => setTimestamp(null)
  });

  const handleClose = () => {
    context.closeModal(id);
    form.reset();
  };

  const goToSignup = () => {
    context.closeModal(id);
    navigate(Paths.signup);
  };

  const goToInterestedSignup = () => {
    context.closeModal(id);
    navigate(Paths.interestedSignup);
  };

  const handleSubmit: SubmitHandler<UserLoginData> = (data) => {
    login(data);
  };

  const isLoading = isLoginLoading || isForgetPasswordLoading;

  return (
    <ModalWrapper
      onClose={handleClose}
      loading={isLoading}
      classNames={{
        wrapper: styles.wrapper,
        content: styles.content
      }}
    >
      <Form className={styles.form} onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack w="100%" mb={formError ? 15 : 35}>
          <Controller
            control={form.control}
            name="email"
            rules={{
              required: t('errorMessages.required'),
              pattern: {
                value: emailRegExp,
                message: t('errorMessages.invalidEmail')
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={(value) => {
                  onChange(value);
                  if (revalidateOnChange) {
                    form.trigger('email');
                  }
                }}
                onBlur={onBlur}
                error={error?.message || !!formError}
                label={t('formLabels.email')}
                placeholder={t('placeholders.email')}
                icon={<UserIcon style={{ width: 15, height: 15 }} />}
                type="email"
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={form.control}
            name="password"
            rules={{
              required: t('errorMessages.required'),
              minLength: {
                value: 8,
                message: t('errorMessages.passwordMinLength')
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <PasswordInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message || !!formError}
                label={t('formLabels.password')}
                placeholder={t('placeholders.password')}
                icon={<PasswordIcon style={{ width: 16, height: 16 }} />}
                disabled={isLoading}
              />
            )}
          />
        </Stack>
        {formError && (
          <Text fz={12} mb={15} color="#a00000">
            {formError}
          </Text>
        )}
        <Button className={styles.logInButton} type="submit">
          {t('modals.login.logIn')}
        </Button>
      </Form>
      <Stack align="center">
        <div className={styles.linkWrapper}>
          <p className={styles.linkText}>{t('modals.login.noAccount')}</p>
          <button className={styles.link} onClick={goToSignup}>
            {t('common.registerNow')}
          </button>
        </div>

        <Group spacing={5} className={styles.linkWrapper}>
          <p>{t('modals.login.forgetPassword')}</p>
          {isRunning ? (
            <Text color="#a00000">
              {formatNumberToTime(minutes)}:{formatNumberToTime(seconds)}
            </Text>
          ) : (
            <button className={styles.link} onClick={handleResetPassword}>
              {t('common.clickHere')}
            </button>
          )}
        </Group>

        <Button onClick={goToInterestedSignup} variant='secondary'>
          {t('modals.login.registerAsInterested')}
        </Button>
      </Stack>
    </ModalWrapper>
  );
};

export default LoginModal;
