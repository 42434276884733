import { useTranslation } from 'react-i18next';
import { useInnovationListQuery } from 'src/api/innovations/hooks';
import styles from './styles.module.scss';
import { Button, Container, Flex, Group, Image, Text } from '@mantine/core';
import Title from 'src/components/Title';
import Pagination from 'src/components/Pagination';
import coloredLogo from 'src/assets/svg/logo-colored.svg';
import bg from 'src/assets/svg/innovationBg.svg';
import itemImg from 'src/assets/svg/InnovationItem.svg';
import { useEffect, useRef, useState } from 'react';
import { openInnovationContactModal, openInnovationParticipantsModal } from 'src/helpers/modals';
import { IconChevronLeft } from '@tabler/icons-react';
import { useShowHidePageListQuery } from 'src/api/content-management/hooks';
import { router } from 'src/router/router';
import { Paths } from 'src/router/constants';
import { useAuthStore } from 'src/store/useAuthStore';

const Innovation = () => {
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const { data: Content, isLoading: ContentLoading } = useShowHidePageListQuery();
  const { data: innovationList, isLoading: isInnovationListLoading } = useInnovationListQuery();
  const winnersByPage = innovationList?.slice((page - 1) * 5, page * 5);
  const isShow = !ContentLoading && Content?.data.find((item) => item.name === 'Innovations');

  const productRef = useRef<any>(null);

  const scrollFunction = () => {
    if (productRef.current) {
      setTimeout(() => {
        productRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    }
  }

  useEffect(() => {
    if (isShow && isShow.is_show === false) {
      router.navigate(Paths.homepage);
    }
  }, [isShow]);

  useEffect(() => {
    const index = innovationList && innovationList.findIndex((item) => item.id === router.state.location.hash.substring(1));
    index && index > 0 && setPage(Math.ceil((index + 1) / 5));
    scrollFunction();
  }, [isInnovationListLoading]);

  useEffect(() => {
    const index = innovationList && innovationList.findIndex((item) => item.id === router.state.location.hash.substring(1));
    index && index > 0 && setPage(Math.ceil((index + 1) / 5));
    scrollFunction();

    if (!router.state.location.hash.substring(1)) {
      localStorage.removeItem('searchedText');
    }
  }, []);

  useEffect(() => {
    scrollFunction();
  }, [page]);

  const highlightText = (text: string) => {
    if (localStorage.getItem('searchedText')) {
      return (
        <Text>
          {text.toLowerCase().split(new RegExp(`(${localStorage.getItem('searchedText')?.toLowerCase()})`, 'gi')).map((part: string, index: number) => 
          part.toLowerCase() === localStorage.getItem('searchedText')?.toLowerCase() ? 
            <span key={index} style={{background: '#1E93CD99'}}>{part}</span> : part
          )}
        </Text>
      )
    }
  };

  return (
    <div className={styles.wrapper}>
      <Image src={bg} className={styles.bgImage} />

      {!isInnovationListLoading && innovationList && (
        <Container mb={80} maw={1200}>
          <Title color='dark' className={styles.title}>{t('pages.innovation.banner')}</Title>

          <Flex direction='column' align='center' gap={60} mb={40}>
            {winnersByPage?.map((item, id: number) => (
              <Flex key={id} direction='column' w='100%' id={`#${item.id}`} ref={item.id === router.state.location.hash.substring(1) ? productRef : null}>

                <Flex className={styles.item} pos='relative'>
                  <Image src={itemImg} className={styles.itemImage} pos='absolute' bottom={0} left={0} />

                  <Group h={275} mah={275} miw={275} w={275}>
                    <Image src={item.image || coloredLogo} fit='fill' className={styles.image} />
                  </Group>
                  <Flex direction='column' py={15} pl={32} gap={6} w='100%'>
                    <Flex justify='space-between'>
                      <Text color='rgb(111, 167, 91)' dir='rtl' fz={18}>
                        {item.id === router.state.location.hash.substring(1) ? highlightText(item.innovation_title) : item.innovation_title}
                      </Text>
                      {user?.entity && (
                        <Button onClick={() => openInnovationContactModal(item.id)}>
                          {t('pages.innovation.contactBtn')}
                        </Button>
                      )}
                    </Flex>

                    <Text fz={14}>{item.id === router.state.location.hash.substring(1) ? highlightText(item.innovation_path) : item.innovation_path}</Text>
                    <Text color='#b9b9b9' fz={13}>{item.date}</Text>
                    <Text color='#3ca1d4' mt={20} mb={10} fz={16}>{t('pages.innovation.about')}</Text>
                    <Text color='#26495A' fz={14} className={styles.briefText}>
                      {item.id === router.state.location.hash.substring(1) ? highlightText(item.berief_description_ar) : item.berief_description_ar}
                    </Text>
                  </Flex>
                </Flex>

                <Button
                  bg='#26495a'
                  h={48}
                  className={styles.btn}
                  onClick={() => openInnovationParticipantsModal([item.main_participant_name, ...item.participants_names])}
                >
                  {t('pages.winner.participantsName')}
                  <IconChevronLeft size={18} />
                </Button>
              </Flex>
            ))}
          </Flex>

          <Pagination
            currentPage={page}
            totalPages={innovationList ? Math.ceil(innovationList.length / 6) : 1}
            onChange={setPage}
          />
        </Container>
      )}
    </div>
  )
};

export default Innovation;
