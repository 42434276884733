import { useQuery } from '@tanstack/react-query';

import { getCities } from './api';
import { City } from './types';

export const useCitiesQuery = () => {
  return useQuery<City[]>({
    queryKey: ['cities'],
    queryFn: async () => getCities().then((res) => res.data)
  });
};
