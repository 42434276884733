import { FC } from 'react';

import { TextInput as MantineTextInput, TextInputProps } from '@mantine/core';

import styles from './TextInput.module.scss';

const TextInput: FC<TextInputProps> = (props) => (
  <MantineTextInput
    classNames={{
      input: styles.input,
      invalid: styles.invalid
    }}
    {...props}
  />
);

export default TextInput;
