import { FC } from 'react';

import { Outlet } from 'react-router-dom';

import bannerBg from 'src/assets/jpg/banner-image.jpg';
import bannerOrnament from 'src/assets/svg/banner-ornament.svg';
import Footer from 'src/components/Footer';
import Navbar from 'src/components/Navbar';

import styles from './styles.module.scss';

const FormLayout: FC = () => (
  <div className={styles.wrapper}>
    <Navbar navbarVariant="transparent" position="absolute" />
    <div className={styles.bannerWrapper}>
      <img src={bannerBg} alt="" className={styles.bannerImage} />
      <img src={bannerOrnament} alt="" className={styles.bannerOrnament} />
      <div className={styles.bannerOverlay} />
    </div>
    <div className={styles.contentWrapper}>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
    <Footer />
  </div>
);

export default FormLayout;
