import { FC } from 'react';

import { NumberInput as MantineNumberInput, NumberInputProps } from '@mantine/core';

const NumberInput: FC<NumberInputProps> = (props) => (
  <MantineNumberInput
    {...props}
  />
);

export default NumberInput;
