import { FC } from 'react';

import { Checkbox, CheckboxGroupProps } from '@mantine/core';

const CheckboxGroup: FC<CheckboxGroupProps> = ({ children, ...restProps }) => (
  <Checkbox.Group
    {...restProps}
    size="xs"
  >
    {children}
  </Checkbox.Group>
);

export default CheckboxGroup;
