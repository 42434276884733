import { FC } from 'react';

import { Image } from '@mantine/core';
import { generatePath, useNavigate } from 'react-router-dom';

import { VideoGallery } from 'src/api/video-gallery/types';
import { ReactComponent as PlayerIcon } from 'src/assets/svg/player-icon.svg';
import { Paths } from 'src/router/constants';

import styles from './styles.module.scss';

type Props = {
  item: VideoGallery;
};

const VideoCard: FC<Props> = ({ item }) => {
  const navigate = useNavigate();

  const openVideoDetails = () => {
    const path = generatePath(Paths.videoDetails, { videoId: item.id });
    navigate(path, { preventScrollReset: true });
  };

  return (
    <div className={styles.wrapper} onClick={openVideoDetails}>
      <div className={styles.posterWrapper}>
        <PlayerIcon className={styles.playIcon} />
        <Image
          src={item.cover_image_path}
          className={styles.poster}
          width="100%"
          fit="cover"
          height={235}
          withPlaceholder
        />
      </div>
      <h4 className={styles.cardTitle}>{item.summary}</h4>
    </div>
  );
};

export default VideoCard;
