import { FC } from 'react';

import { Radio, RadioGroupProps } from '@mantine/core';

const RadioGroup: FC<RadioGroupProps> = ({ children, ...restProps }) => (
  <Radio.Group
    {...restProps}
    size="xs"
  >
    {children}
  </Radio.Group>
);

export default RadioGroup;
