import { FC } from 'react';

import { clsx } from 'clsx';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import { TTabsProps } from './types';

const Tabs: FC<TTabsProps> = ({ tabs, active }) => (
  <div className={styles.tabs}>
    {tabs.map((tab) => (
      <Link
        to={tab.link}
        key={tab.id}
        className={clsx(styles.tab, { [styles.active]: active === tab.id })}
      >
        {tab.label}
      </Link>
    ))}
  </div>
);

export default Tabs;
