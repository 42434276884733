import { useQuery } from '@tanstack/react-query';

import { getTicketTypes } from './api';
import { TicketType } from './types';

export const useTicketTypesQuery = () => {
  return useQuery<TicketType[]>({
    queryKey: ['ticket-types'],
    queryFn: async () => getTicketTypes().then((res) => res.data)
  });
};
