import { FC } from 'react';

import { Flex, Stack, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { useTranslation } from 'react-i18next';

import { useCancelTicketMutation } from 'src/api/tickets/hooks';
import { ReactComponent as AlertIcon } from 'src/assets/svg/alert-complaint-cancel.svg';
import Button from 'src/components/Button';
import ModalWrapper from 'src/components/ModalWrapper';

import styles from './CancelTicketModal.module.scss';

type Props = {
  ticketId: string;
};

const CancelTicketModal: FC<ContextModalProps<Props>> = ({
  context,
  id,
  innerProps: { ticketId }
}) => {
  const { t } = useTranslation();

  const { mutate: cancelTicket, isLoading } = useCancelTicketMutation();

  const handleClose = () => {
    context.closeModal(id);
  };

  return (
    <ModalWrapper
      onClose={handleClose}
      loading={isLoading}
      classNames={{ wrapper: styles.wrapper }}
      closeIconPosition={{ top: 50, end: 45 }}
    >
      <Stack mb={40} align="center" spacing={30}>
        <AlertIcon />
        <h4 className={styles.cancelModalHeader}>
          {t('modals.cancelTicket.header')}
        </h4>
        <Text className={styles.cancelModalAdditionText}>
          {t('modals.cancelTicket.additionalHeaderText')}
        </Text>
      </Stack>
      <Flex pt={10} justify="space-between">
        <Button
          className={styles.cancelModalBtn}
          variant="secondary"
          onClick={handleClose}
        >
          {t('common.back')}
        </Button>
        <Button
          className={styles.cancelModalBtn}
          variant="danger"
          onClick={() => {
            cancelTicket({ id: ticketId });
          }}
        >
          {t('common.cancel')}
        </Button>
      </Flex>
    </ModalWrapper>
  );
};

export default CancelTicketModal;
