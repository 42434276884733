import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import queryClient from 'src/configs/query-client';

import { getLatestNews, getNews, getNewsById } from './api';
import { GetNewsResponse, News } from './types';

export const NEWS_QUERY_KEY = 'media_center_news';
export const LATEST_NEWS_QUERY_KEY = 'media_center_latest_news';
export const NEWS_DETAILS_QUERY_KEY = 'media_center_news_details';

export const useNewsQuery = (
  { page }: { page: number }
) => {
  return useQuery<GetNewsResponse>({
    queryKey: [NEWS_QUERY_KEY, page],
    queryFn: () => getNews(page),
    keepPreviousData: true,
    staleTime: 5000
  });
};

export const usePrefetchNextNewsPage = (
  data: GetNewsResponse | undefined,
  isPreviousData: boolean,
  page: number
) => {
  useEffect(() => {
    if (!isPreviousData && data?.meta.hasNext) {
      queryClient.prefetchQuery({
        queryKey: [NEWS_QUERY_KEY, page + 1],
        queryFn: () => getNews(page + 1)
      });
    }
  }, [data, isPreviousData, page]);
};

export const useLatestNewsQuery = () => {
  return useQuery<News[]>({
    queryKey: [LATEST_NEWS_QUERY_KEY],
    queryFn: () => getLatestNews().then((res) => res.data)
  });
};

export const useNewsItemQuery = (id: string) => {
  return useQuery<News | null>({
    queryKey: [NEWS_DETAILS_QUERY_KEY, id],
    queryFn: async () => {
      const response = await getNewsById(id);
      const item = response.data[0];

      return item || null;
    }
  });
};
