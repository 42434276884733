import { Container, Flex } from '@mantine/core';
import ViewWinnerHeaderBlock from '../ViewWinnerHeaderBlock';
import WinnersPageItem from '../WinnersPageItem/WinnersPageItem';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { FullWinnersInfo, WinnersInfo } from 'src/api/arbitrating/types';
import { Dispatch, SetStateAction } from 'react';
import Pagination from '../Pagination';
import Title from '../Title';

type TProps = {
  data: FullWinnersInfo | any;
  winnersByPage: WinnersInfo[] | any;
  selectedYear?: string;
  productRef?: any;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
};

const WinnersData = ({ data, winnersByPage, selectedYear, page, productRef, setPage }: TProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ViewWinnerHeaderBlock data={data} />

      <div className={styles.wrapper}>
        <Container>
          <Title color='dark'>
            {t('pages.winner.winnersAward')}
            <span className={styles.titleSpan}>{selectedYear ? selectedYear : '2023'}</span>
          </Title>

          <Flex direction='column' align='center' gap={48} mb={40}>
            {data && winnersByPage?.map((item: any, id: number) => (
              <WinnersPageItem item={item} key={id} productRef={productRef} />
            ))}
          </Flex>

          <Pagination
            currentPage={page}
            totalPages={data && data?.winners ? Math.ceil(data?.winners?.length / 5) : 1}
            onChange={setPage}
          />
        </Container>
      </div>
    </>
  );
};

export default WinnersData;
