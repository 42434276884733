import { Group, Stack, Text } from '@mantine/core';
import { openModal } from '@mantine/modals';

import Badge from 'src/components/Badge';
import i18n from 'src/configs/i18n';
import { TParticipantsApprovalData, TUserCompetitionInfo } from 'src/modules/competition/types';

export const renderParticipantApprovedStatus = (isApproved: TParticipantsApprovalData['isAprroved']) => {
  if (isApproved === null) {
    return (
      <Badge color="gray">
        {i18n.t('common.waitingApproval') as string}
      </Badge>
    );
  }

  if (typeof isApproved === 'boolean') {

    return isApproved ? (
      <Badge color="green">
        {i18n.t('common.approved') as string}
      </Badge>
    ) : (
      <Badge color="red">
        {i18n.t('common.rejected') as string}
      </Badge>
    );
  }

  return null;
};

export const openMissingRequirementsModal = (userRequestInfo: TUserCompetitionInfo) => {
  openModal({
    title: i18n.t('common.missingRequirements') as string,
    centered: true,
    size: 500,
    padding: 30,
    children: (
      <Stack>
        <Text>{i18n.t('common.fields') as string}:</Text>
        <Stack spacing={5}>
          {userRequestInfo.request_comments.fieldCommentList.map((comment) => (
            <Group key={comment.field} spacing={5}>
              <Text>{comment.field}:</Text>
              <Text>{comment.comment}</Text>
            </Group>
          ))}
        </Stack>
      </Stack>
    )
  });
};
