import { FC, ReactNode, useState } from 'react';

import { Menu } from '@mantine/core';
import { clsx } from 'clsx';

import { ReactComponent as ExpandMoreIcon } from 'src/assets/svg/expand-more-icon.svg';

import styles from './styles.module.scss';

export type MenuItem = {
  id: number | string;
  title: ReactNode;
  onClick?: () => void;
};

type Props = {
  title: string;
  position: 'bottom-end' | 'bottom-start';
  navbarVariant: 'light' | 'transparent';
  items: MenuItem[];
  withMoreIcon?: boolean;
};

const Dropdown: FC<Props> = ({
  title,
  position,
  navbarVariant,
  items,
  withMoreIcon = true
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <Menu
      opened={opened}
      onChange={setOpened}
      offset={20}
      position={position}
      classNames={{
        dropdown: styles.dropdownContent,
        item: styles.dropdownItem
      }}
    >
      <Menu.Target>
        <button
          className={clsx(styles.button, {
            [styles.open]: opened,
            [styles.dark]: navbarVariant === 'light'
          })}
        >
          <span className={styles.buttonText}>
            {title}
          </span>
          {!!withMoreIcon && <span className={styles.iconWrapper}>
            <ExpandMoreIcon className={styles.icon} />
          </span>}
        </button>
      </Menu.Target>

      <Menu.Dropdown>
        {items.map((item) => (
          <Menu.Item key={item.id} onClick={item.onClick}>
            {item.title}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default Dropdown;
