import { FC } from 'react';

import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { TFormErrorProps } from './types';

const FormError: FC<TFormErrorProps> = ({ control }) => {
  const { t } = useTranslation();
  const { isSubmitted, errors } = useFormState({ control });

  return isSubmitted && Object.keys(errors).length ? (
    <p className={styles.error}>
      {t('errorMessages.completeRequiredFields')}
      <span className={styles.required}>*</span>
    </p>
  ) : null;
};

export default FormError;
