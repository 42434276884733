import { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Dropdown from 'src/components/Navbar/Dropdown';
import { MenuItem } from 'src/components/Navbar/Dropdown/Dropdown';
import { TNavbarProps } from 'src/components/Navbar/types';
import i18n from 'src/configs/i18n';
import { Paths } from 'src/router/constants';

type TProps = Pick<TNavbarProps, 'navbarVariant'> & {
  onClick?: () => void;
};

const AboutDropdown: FC<TProps> = ({ navbarVariant, onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const items: MenuItem[] = useMemo(() => [
    {
      id: 1,
      title: i18n.t('common.briefAboutPrize') as string,
      onClick: () => {
        navigate(Paths.aboutPrize);
        if (onClick) onClick();
      }
    },
    {
      id: 2,
      title: i18n.t('common.competitionPaths') as string,
      onClick: () => {
        navigate(Paths.competitionPaths);
        if (onClick) onClick();
      }
    },
    {
      id: 3,
      title: i18n.t('common.competitionConditions') as string,
      onClick: () => {
        navigate(Paths.competitionConditions);
        if (onClick) onClick();
      }
    },
    {
      id: 4,
      title: i18n.t('common.evaluationCriteria') as string,
      onClick: () => {
        navigate(Paths.evaluationCriteria);
        if (onClick) onClick();
      }
    },
    {
      id: 5,
      title: i18n.t('common.supervisoryBoard') as string,
      onClick: () => {
        navigate(Paths.supervisoryBoard);
        if (onClick) onClick();
      }
    },
    {
      id: 6,
      title: i18n.t('common.generalSecretariat') as string,
      onClick: () => {
        navigate(Paths.generalSecretariat);
        if (onClick) onClick();
      }
    }
  ], [navigate, onClick]);

  return (
    <Dropdown
      title={t('common.aboutThePrize')}
      position="bottom-start"
      navbarVariant={navbarVariant}
      items={items}
    />
  );
};

export default AboutDropdown;
