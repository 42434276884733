import { FC, useState } from 'react';

import { Center, Loader } from '@mantine/core';

import { useNewsQuery, usePrefetchNextNewsPage } from 'src/api/news/helpers';
import Container from 'src/components/Container';
import NewsCard from 'src/components/NewsCard';
import Pagination from 'src/components/Pagination';

import styles from './styles.module.scss';

const News: FC = () => {
  const [page, setPage] = useState<number>(1);

  const { data, isLoading, isPreviousData } = useNewsQuery({ page });

  usePrefetchNextNewsPage(data, isPreviousData, page);

  return (
    <Container variant="lg">
      {isLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {data && (
        <>
          <div className={styles.cardsWrapper}>
            {data.data.map((item) => (
              <NewsCard key={item.id} news={item} />
            ))}
          </div>
          <Pagination
            currentPage={page}
            totalPages={data.meta.totalPages}
            onChange={setPage}
          />
        </>
      )}
    </Container>
  );
};

export default News;
