import { FC, useState } from 'react';

import { Center, Loader } from '@mantine/core';
import { Outlet } from 'react-router-dom';

import { usePrefetchNextVideoGalleryPage, useVideoGalleryQuery } from 'src/api/video-gallery/helpers';
import Container from 'src/components/Container';
import Pagination from 'src/components/Pagination';

import styles from './styles.module.scss';
import VideoCard from './VideoCard';

const VideoGallery: FC = () => {
  const [page, setPage] = useState<number>(1);

  const { data, isLoading, isPreviousData } = useVideoGalleryQuery({ page });

  usePrefetchNextVideoGalleryPage(data, isPreviousData, page);

  return (
    <>
      <Container variant="lg">
        {isLoading && (
          <Center py={50}>
            <Loader size="lg" />
          </Center>
        )}
        {data && (
          <>
            <div className={styles.cardsWrapper}>
              {data.data.map((item) => (
                <VideoCard key={item.id} item={item} />
              ))}
            </div>
            <Pagination
              currentPage={page}
              totalPages={data.meta.totalPages}
              onChange={setPage}
            />
          </>
        )}
      </Container>

      <Outlet />
    </>
  );
};

export default VideoGallery;
