import { FC, ReactNode } from 'react';

import { NotificationsProvider as Provider } from '@mantine/notifications';

type Props = {
  children: ReactNode;
};

const NotificationsProvider: FC<Props> = ({ children }) => (
  <Provider position="top-right">
    {children}
  </Provider>
);

export default NotificationsProvider;
