import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Popover, Button, Flex, Input, Text } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { router } from 'src/router/router';
import { Paths } from 'src/router/constants';
import { Link } from 'react-router-dom';

type TProps = {
  color: string;
};

const SearchPopover = ({ color }: TProps) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>();
  const [showError, setShowError] = useState<boolean>(false);

  const handleClick = () => {
    if (inputValue?.length) {
      router.navigate(`${Paths.results}?search=${inputValue}`);
      return;
    } else {
      setShowError(true);
    }
  };

  return (
    <Popover width={220} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <IconSearch size={20} color={color} cursor='pointer' />
      </Popover.Target>
      <Popover.Dropdown>
        <Flex direction='column' align='flex-end' gap={14}>
          <Flex w='100%' direction='column' gap={4} dir='rtl'>
            <Input
              w='100%'
              value={inputValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value);
                setShowError(false);
              }}
              placeholder={t('pages.result.lookFor')}
            />
            {showError && (
              <Text fz={9} color='red' dir='rtl' ta='start'>
                {t('errorMessages.EnterSearch')}
              </Text>
            )}
          </Flex>
          
          {inputValue?.length ? (
            <Link
              reloadDocument
              to={`${Paths.results}?search=${inputValue}`}
              style={{ height: '36px' }}
            >
              <Button w='fit-content' onClick={handleClick}>{t('pages.result.search')}</Button>
            </Link>
          ) : (
            <Button w='fit-content' onClick={() => setShowError(true)}>{t('pages.result.search')}</Button>
          )}
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export default SearchPopover;
