import { useQuery } from '@tanstack/react-query';

import { getFAQ } from './api';
import { FAQ } from './types';

export const useFAQQuery = () => {
  return useQuery<FAQ[]>({
    queryKey: ['faq'],
    queryFn: async () => getFAQ().then((res) => res.data)
  });
};
