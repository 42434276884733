import { FC, useMemo } from 'react';

import { Box } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, NavLink, useLocation, useNavigate } from 'react-router-dom';

import { useCurrentCompetitionQuery, useUserParticipatingRequestsQuery } from 'src/api/competition/hooks';
import { ReactComponent as HelpIcon } from 'src/assets/svg/faqs.svg';
import coloredLogo from 'src/assets/svg/logo-colored.svg';
import whiteLogo from 'src/assets/svg/logo-row-white.svg';
import Button from 'src/components/Button';
import Container from 'src/components/Container';
import UserMenu from 'src/components/Navbar/UserMenu';
import Tooltip from 'src/components/Tooltip';
import { isDateBeforeCurrent } from 'src/helpers/dates';
import { openLoginModal } from 'src/helpers/modals';
import { Paths } from 'src/router/constants';
import { useAuthStore } from 'src/store/useAuthStore';

import { useCurrentCompetitionUserInfo } from '../../helpers/competition';
import AboutDropdown from './AboutDropdown';
import BurgerMenu from './BurgerMenu';
import LanguageDropdown from './LanguageDropdown';
import Link from './Link';
import styles from './styles.module.scss';
import { TNavbarProps } from './types';
import { useShowHidePageListQuery } from 'src/api/content-management/hooks';
import SearchPopover from '../SearchPopover';

const Navbar: FC<TNavbarProps> = ({
  position,
  navbarVariant
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useAuthStore();
  const { data: currentCompetition } = useCurrentCompetitionQuery();
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const { data: requests } = useUserParticipatingRequestsQuery();
  const { data: Content, isLoading: ContentLoading } = useShowHidePageListQuery();

  const isShowWinners = !ContentLoading && Content?.data.find((item) => item.name === 'Winners in Latest Comp')?.is_show;
  const isShowInnovations = !ContentLoading && Content?.data.find((item) => item.name === 'Innovations')?.is_show;

  const userCurrentCompetitionRequestInfo = useCurrentCompetitionUserInfo(currentCompetition, requests);

  const isCompetitionActive = useMemo(() => {
    if (!currentCompetition) {
      return false;
    }
    return isDateBeforeCurrent(currentCompetition.end_date);
  }, [currentCompetition]);


  const isUserRegisteredToCompetition = useMemo(() => {
    if (!user) {
      return false;
    }

    return userCurrentCompetitionRequestInfo !== null;
  }, [user, userCurrentCompetitionRequestInfo]);


  const handleContactUsClick = () => {
    if (user) {
      navigate(Paths.contactUs);
    } else {
      openLoginModal({ navigateAfterSuccessPath: Paths.contactUs });
    }
  };

  const showLanguageDropdown = useMemo(() => {
    // todo: temporary solution, remove it in future
    if (pathname === Paths.homepage) {
      return true;
    }

    return false;
  }, [pathname]);

  return (
    <>
      <header className={clsx(styles.navbar, position, navbarVariant)}>
        <Container variant="xl">
          <div className={styles.wrapper}>
            {isMobile ? (
              <BurgerMenu navbarVariant={navbarVariant} showLanguageDropdown={showLanguageDropdown} />
            ) : (
              <>
                <div className={styles.startSection}>
                  {navbarVariant === 'transparent' ? (
                    <img src={whiteLogo} alt="Logo" width={169} height={33} />
                  ) : (
                    <img src={coloredLogo} alt="Logo" width={40} height={40} />
                  )}
                  <NavLink to={Paths.homepage}>
                    {({ isActive }) => (
                      <Link navbarVariant={navbarVariant} isActive={isActive}>
                        {t('common.homepage')}
                      </Link>
                    )}
                  </NavLink>
                  <div>
                    <AboutDropdown navbarVariant={navbarVariant} />
                  </div>
                  {isShowInnovations && (
                    <NavLink to={Paths.innovation} end={false}>
                      {({ isActive }) => (
                        <Link navbarVariant={navbarVariant} isActive={isActive}>
                          {t('common.innovations')}
                        </Link>
                      )}
                    </NavLink>
                  )}
                  {isShowWinners && (
                    <NavLink to={Paths.winners} end={false}>
                      {({ isActive }) => (
                        <Link navbarVariant={navbarVariant} isActive={isActive}>
                          {t('common.winners')}
                        </Link>
                      )}
                    </NavLink>
                  )}

                  <NavLink to={Paths.mediaCenter} end={false}>
                    {({ isActive }) => (
                      <Link navbarVariant={navbarVariant} isActive={isActive}>
                        {t('common.mediaCenter')}
                      </Link>
                    )}
                  </NavLink>

                  <Box onClick={handleContactUsClick}>
                    <Link
                      navbarVariant={navbarVariant}
                      isActive={pathname.startsWith(Paths.contactUs)}
                    >
                      {t('common.contactUs')}
                    </Link>
                  </Box>

                  {user && !user.entity && isCompetitionActive && !isUserRegisteredToCompetition && !isMobile && (
                    <RouterLink to={Paths.competitionRegistration}>
                      <Button className={styles.registerForPrizeButton}>
                        {t('common.registerForPrize')}
                      </Button>
                    </RouterLink>
                  )}
                </div>
                <div className={styles.endSection}>
                  {showLanguageDropdown && (
                    <LanguageDropdown navbarVariant={navbarVariant} />
                  )}
                  <SearchPopover color={navbarVariant === 'light' ? '#26495a' : '#fff'} />
                  <Tooltip label={t('common.faq')}>
                    <RouterLink to={Paths.faq} className={styles.faqSection}>
                      <HelpIcon className={clsx(styles.iconButton, {
                        [styles.dark]: navbarVariant === 'light'
                      })}
                      />
                    </RouterLink>
                  </Tooltip>
                  {user ? (
                    <UserMenu user={user} navbarVariant={navbarVariant} />
                  ) : (
                    <button
                      className={clsx(styles.loginButton, { [styles.dark]: navbarVariant === 'light' })}
                      onClick={() => openLoginModal()}
                    >
                      {t('common.logIn')}
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </Container>
      </header>
    </>
  );
};

export default Navbar;
