import { FC, ReactNode } from 'react';

import { useMediaQuery } from '@mantine/hooks';
import { clsx } from 'clsx';

import bgLines from 'src/assets/svg/union-slanted.svg';

import styles from './styles.module.scss';

type TClasses = {
  contentWrapper: string;
};

type TProps = {
  children: ReactNode;
  classes?: TClasses;
};

const LinesBackground: FC<TProps> = ({ children, classes }) => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <div className={styles.wrapper}>
      {!isMobile && <img src={bgLines} alt="" className={styles.bgLines} />}
      <div className={clsx(styles.contentWrapper, classes?.contentWrapper)}>
        {children}
      </div>
    </div>
  );
};

export default LinesBackground;
