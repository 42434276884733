import { FC, useEffect, useMemo } from 'react';

import { Box, LoadingOverlay } from '@mantine/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useGetTicketByIdQuery,
  useUpdateTicketMutation
} from 'src/api/tickets/hooks';
import { TTicketForm } from 'src/api/tickets/types';
import LinesBackground from 'src/components/LinesBackground';
import ManageTicket from 'src/components/ManageTicket';
import Title from 'src/components/Title';
import { parseApiErrors } from 'src/helpers/forms';
import { openFormErrorModal } from 'src/helpers/modals';
import { normalizeUpdateTicket } from 'src/helpers/ticket';

import styles from './TicketDetails.module.scss';

const TicketDetails: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const form = useForm<TTicketForm>({
    defaultValues: {
      ticketType_id: null,
      title: '',
      description: '',
      attachemt: null
    }
  });

  const { data: ticket, isLoading: getTicketIsLoading } = useGetTicketByIdQuery(id || '');

  const { isLoading: updateTicketIsLoading, mutate: updateTicket } = useUpdateTicketMutation({
    onSuccess: () => form.reset(),
    onError: (error) => parseApiErrors(error, {
      form,
      onGlobalError: (message) => openFormErrorModal({ message })
    })
  });

  useEffect(() => {
    if (ticket) {
      form.reset({
        ticketType_id: ticket.tickit_type.id.toString() || null,
        title: ticket.title || '',
        description: ticket.description || '',
        attachemt: ticket.attachemt_path
      });
    }
  }, [form, ticket, navigate]);

  const onSubmit: SubmitHandler<TTicketForm> = (data) => {
    const updateTicketData = normalizeUpdateTicket({ ...data, id });
    updateTicket(updateTicketData);
  };

  const canUpdateTicket = useMemo(() => {
    if (!ticket) {
      return false;
    }
    const ticketStatus = ticket.ticket_status.id;

    return ticketStatus === 1 || ticketStatus === 3;
  }, [ticket]);

  const isLoading = updateTicketIsLoading || getTicketIsLoading;

  return (
    <LinesBackground>
      <Box w={700}>
        <Box mb={30}>
          <Title color="dark" fontSize="small" underlineTopSpacing={10}>
            {t('pages.contactUs.title')}
          </Title>
        </Box>
        <Box className={styles.contactUsBox} pos="relative">
          <LoadingOverlay visible={isLoading} />

          <ManageTicket
            form={form}
            onSubmit={onSubmit}
            disabled={isLoading || !canUpdateTicket}
            allowSubmit={canUpdateTicket}
            submitText={t('common.editTicket')}
          />
        </Box>
      </Box>
    </LinesBackground>
  );
};

export default TicketDetails;
