import { useQuery } from "@tanstack/react-query";
import { TShowHideList } from "./types";
import { getShowHidePageList } from "./api";

export const useShowHidePageListQuery = () => {
  return useQuery<TShowHideList>({
    queryKey: ['show-hide-key'],
    queryFn: getShowHidePageList
  });
};
