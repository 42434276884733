import { FC } from "react";
import styles from './styles.module.scss';
import coloredLogo from 'src/assets/svg/logo-colored.svg';
import bgImg from 'src/assets/svg/winner-item.svg';
import { FormatPlace } from "src/helpers/formatPlace";
import { useTranslation } from "react-i18next";

type Props = {
  data: any;
  id: number;
}

const WinnerItem: FC<Props> = ({ data, id }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.backBlock}>
        <h3>{data.rank_In_Word || (FormatPlace(id + 1) + 'winner')}</h3>
      </div>
      <div className={styles.mainBlock}>
        <img src={data.winner_image || coloredLogo} alt="winner-image"  />
        <p className={styles.green}>{data.innovation_name}</p>
        <p className={styles.spaceBetweenEl}>{data.innovation_path}</p>
        <p>{data.winner_name}</p>
        <p>{data.participatingType}</p>
      </div>

      <img src={bgImg} alt='img' className={styles.additionalImg} />
    </div>
  );
};

export default WinnerItem;
