import { FC } from 'react';

import { Center, Loader } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useSupervisoryBoardQuery } from 'src/api/supervisory-board/hooks';
import linesBg from 'src/assets/svg/supervisory-board-lines-bg.svg';
import Container from 'src/components/Container';
import PersonCard from 'src/components/PersonCard';
import Title from 'src/components/Title';

import styles from './styles.module.scss';

const SupervisoryBoard: FC = () => {
  const { t } = useTranslation();

  const { data, isLoading, isSuccess } = useSupervisoryBoardQuery();

  return (
    <div className={styles.wrapper}>
      <img src={linesBg} alt="" className={styles.lines} />
      <Container variant="sm">
        <div className={styles.content}>
          <Title
            color="eden"
            fontSize={24}
            spacing={60}
            placing="center"
          >
            {t('common.supervisoryBoard')}
          </Title>
          {isLoading && (
            <Center py={50}>
              <Loader size="lg" />
            </Center>
          )}
          {isSuccess && (
            <div className={styles.cardsWrapper}>
              {data.map((item) => (
                <PersonCard
                  key={item.id}
                  imageSrc={item.imagepath}
                  name={item.name}
                  position={item.position}
                />
              ))}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default SupervisoryBoard;
