import { FC } from 'react';

import { Center, Loader } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import { useFAQQuery } from 'src/api/faq/hooks';
import Accordion from 'src/components/Accordion';
import Container from 'src/components/Container';
import HTMLParser from 'src/components/HTMLParser';
import Title from 'src/components/Title';

import styles from './styles.module.scss';

const FAQ: FC = () => {
  const { t } = useTranslation();
  const { data, isLoading, isSuccess } = useFAQQuery();
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <div className={styles.wrapper}>
      <Container>
        <Title color="dark" isMobile={isMobile}>
          {t('common.faq')}
        </Title>
        {isLoading && (
          <Center py={50}>
            <Loader size="lg" />
          </Center>
        )}
        {isSuccess && (
          <div className={styles.contentWrapper}>
            {data.map((item, index) => (
              <Accordion
                title={item.question}
                key={index}
              >
                <HTMLParser content={item.answer} />
              </Accordion>
            ))}
          </div>
        )}
      </Container>
    </div>
  );
};

export default FAQ;
