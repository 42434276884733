import { useMutation } from "@tanstack/react-query";
import { updateInterestedUser, userInterestedContact } from "./api";
import { ErrorResponse, SuccessResponse } from "src/types/api";
import { UserInterestedRegisterData } from "../user/types";
import { TInterestedUser, TInterestedUserContact } from "./types";
import { showErrorNotification, showSuccessNotification } from "src/helpers/notifications";
import { closeInnovationContactModal } from "src/helpers/modals";

export const useUpdateInterestedUser = (props?: {
    onSuccess?: (response: SuccessResponse, data: TInterestedUser) => void;
    onError?: (error: ErrorResponse) => void;
  }) => {
  return useMutation<SuccessResponse, ErrorResponse, UserInterestedRegisterData>({
    mutationFn: async (data) => updateInterestedUser(data),
    onSuccess: async (response, data) => {
      showSuccessNotification({ message: 'Data updated succesfully' })

      if (props?.onSuccess) {
        props.onSuccess(response, data);
      }
    },
    onError: props?.onError
  });
};

export const useUserInterestedContact = (props?: {
  onSuccess?: (response: SuccessResponse, data: TInterestedUserContact) => void;
  onError?: (error: ErrorResponse) => void;
}) => {
return useMutation<SuccessResponse, ErrorResponse, TInterestedUserContact>({
  mutationFn: async (data) => userInterestedContact(data),
  onSuccess: async (response, data) => {
    showSuccessNotification({ message: 'تم إرسال إيميل التواصل بنجاح' })

    if (props?.onSuccess) {
      props.onSuccess(response, data);
    }
  },
  onError: async () => {
    await showErrorNotification({ message: 'Something went wrong' });
    await closeInnovationContactModal();
  }
});
};
