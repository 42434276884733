import { FC } from 'react';

import { Outlet } from 'react-router-dom';

import Banner from 'src/components/Banner';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';

import styles from './styles.module.scss';

const MainLayout: FC = () => (
  <div className={styles.wrapper}>
    <Header navbarCheckpoint={335} />
    <div className={styles.bannerWrapper}>
      <Banner />
    </div>
    <div className={styles.content}>
      <Outlet />
    </div>
    <Footer />
  </div>
);

export default MainLayout;
