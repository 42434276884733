import { FC } from 'react';

import { Box, Flex, LoadingOverlay, SimpleGrid } from '@mantine/core';
import { PDF_MIME_TYPE } from '@mantine/dropzone';
import { useMediaQuery } from '@mantine/hooks';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useInnovationTypesQuery } from 'src/api/innovation-types/hooks';
import Button from 'src/components/Button';
import Checkbox from 'src/components/Checkbox';
import CheckboxGroup from 'src/components/CheckboxGroup';
import { StepProps } from 'src/components/CompetitionRegistrationForm/types';
import FileUpload from 'src/components/FileUpload';
import Select from 'src/components/Select';
import Textarea from 'src/components/Textarea';
import TextInput from 'src/components/TextInput';
import { useNormalizedItemsData } from 'src/hooks/useNormalizedItemsData';

const Step3: FC<StepProps> = ({
  goToNext,
  goToPrevious,
  form,
  isLoading,
  isDisabled,
  competition
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width:1024px');

  const { data: innovationTypes, isLoading: isInnovationTypesLoading } = useInnovationTypesQuery();

  const competitionPathsData = useNormalizedItemsData(competition?.paths, 'title');
  const innovationTypesData = useNormalizedItemsData(innovationTypes);

  const isStepLoading = isLoading || isInnovationTypesLoading;

  return (
    <>
      <LoadingOverlay visible={isStepLoading} />

      <Box mb={40}>
        <Controller
          control={form.control}
          name="innovation_data.innovation_name"
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <TextInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              label={t('formLabels.innovationName')}
              placeholder={t('placeholders.innovationName')}
              required
              disabled={isStepLoading || isDisabled}
            />
          )}
        />
      </Box>

      <Box mb={40}>
        <Controller
          control={form.control}
          name="innovation_data.innovation_type_id"
          render={({
            field: { value, onChange },
            fieldState: { error } 
          }) => (
            <CheckboxGroup
              value={value}
              error={error?.message}
              onChange={onChange}
              label={t('formLabels.innovationType')}
              required
            >
              <SimpleGrid cols={isMobile || isTablet ? 1 : 2}>
                {innovationTypesData.map((item) => (
                  <Checkbox
                    key={item.value}
                    value={item.value}
                    label={item.label}
                    disabled={isStepLoading || isDisabled}
                  />
                ))}
              </SimpleGrid>
            </CheckboxGroup>
          )}
        />
      </Box>

      <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 40 : 120} mb={40}>
        <Controller
          control={form.control}
          name="innovation_data.path_id"
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <Select
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              data={competitionPathsData}
              label={t('formLabels.innovationPath')}
              placeholder={t('placeholders.innovationPath')}
              required
              clearable
              disabled={isStepLoading || isDisabled}
            />
          )}
        />
      </SimpleGrid>

      <Box mb={40}>
        <Controller
          control={form.control}
          name="innovation_data.description"
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <Textarea
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              label={t('formLabels.innovationDescription')}
              placeholder={t('placeholders.innovationDescription')}
              helperText={t('formTexts.innovationDescription')}
              error={!!error}
              required
              rows={4}
              disabled={isStepLoading || isDisabled}
              maxCounter={500}
              info={t('feature.requests.helperInfo.innovationDescription')}
            />
          )}
        />
      </Box>

      <Box mb={40}>
        <Controller
          control={form.control}
          name="innovation_data.innovation_attachment"
          rules={{ required: t('errorMessages.required') }}
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <FileUpload
              value={value}
              onChange={onChange}
              label={t('formLabels.innovationAttachment')}
              required
              isError={!!error}
              helperText={t('formTexts.pdf25MB')}
              accept={PDF_MIME_TYPE}
              disabled={isStepLoading || isDisabled}
              maxSize={25 * 1000 * 1000}
            />
          )}
        />
      </Box>

      <Flex justify="space-between" h={40}>
        <Button
          onClick={goToPrevious}
          variant="secondary"
        >
          {t('common.previous')}
        </Button>

        <Button onClick={goToNext}>
          {t('common.next')}
        </Button>
      </Flex>
    </>
  );
};

export default Step3;
