import { FC, useMemo, useState } from 'react';

import { Button, LoadingOverlay } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGendersQuery } from 'src/api/genders/hooks';
import { useNationalitiesQuery } from 'src/api/nationalities/hooks';
import { useRegisterInterestedUserMutation, useRegisterUserMutation } from 'src/api/user/hooks';
import { UserInterestedRegisterData, UserRegisterData } from 'src/api/user/types';
import LinesBackground from 'src/components/LinesBackground';
import { parseApiErrors } from 'src/helpers/forms';
import { openFormErrorModal } from 'src/helpers/modals';
import { useNormalizedItemsData } from 'src/hooks/useNormalizedItemsData';
import { Paths } from 'src/router/constants';

import SignupForm from './SignupForm';
import styles from './styles.module.scss';
import InterestedSignupForm from './InterestedSignupForm';
import { useEntityTypeQuery } from 'src/api/entity-type/hooks';

type TProps = {
  isInterested?: boolean;
};

const Signup: FC<TProps> = ({
  isInterested = false
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [navigateTo, setNavigateTo] = useState<Paths>();

  const defaultFormValues: UserRegisterData = useMemo(() => ({
    first_name: '',
    second_name: '',
    last_name: '',
    mobile_number: '',
    nationality_id: null,
    email: '',
    confirm_email: '',
    password: '',
    password_confirmation: '',
    gender_id: null,
    birthday: null,
    user_identity: ''
  }), []);

  const defaultInterestedFormValues: UserInterestedRegisterData = useMemo(() => ({
    first_name: '',
    second_name: '',
    last_name: '',
    email: '',
    confirmed_email: '',
    mobile_number: '',
    password: '',
    confirmed_password: '',
    interests: [],
    entity: t('pages.signup.interested.individual') ? 1 : 2,
    company_name: ''
  }), []);

  const form = useForm<UserRegisterData>({
    defaultValues: defaultFormValues
  });

  const interestedForm = useForm<UserInterestedRegisterData>({
    defaultValues: defaultInterestedFormValues
  });

  const { mutate: register, isLoading: isRegisterLoading } = useRegisterUserMutation({
    onSuccess: async (response) => {
      if (navigateTo) {
        navigate(navigateTo);
      }
      // showNotification({
      //   message: response.message,
      //   color: 'teal',
      //   icon: <IconCheck size={20} />
      // });
    },
    onError: (error) => parseApiErrors(error, {
      form,
      onGlobalError: (message) => openFormErrorModal({ message })
    })
  });

  const { mutate: registerInterested, isLoading: isRegisterInterestedLoading } = useRegisterInterestedUserMutation({
    onSuccess: async (response) => {
      if (navigateTo) {
        navigate(navigateTo);
      }
      showNotification({
        message: response.message,
        color: 'teal',
        icon: <IconCheck size={20} />
      });
    },
    onError: (error) => parseApiErrors(error, {
      onGlobalError: (message) => openFormErrorModal({ message })
    })
  });

  const handleSubmit = (navigateTo: Paths) => {
    form.handleSubmit((data) => {
      setNavigateTo(navigateTo);
      register(data);
    })();
  };

  const handleInterestedSubmit = (navigateTo: Paths) => {
    interestedForm.handleSubmit((data) => {
      setNavigateTo(navigateTo);
      registerInterested(data);
    })();
  };

  const { data: genders } = useGendersQuery();
  const { data: nationalities } = useNationalitiesQuery();
  const { data: entityType } = useEntityTypeQuery(); //TODO (api doesn't work)

  const isDataLoaded = genders && nationalities;

  const isLoading = isRegisterLoading || !isDataLoaded;

  const nationalitiesData = useNormalizedItemsData(nationalities);

  const gendersData = useNormalizedItemsData(genders);

  return (
    <LinesBackground>
      <h2 className={styles.title}>{isInterested ? t('pages.signup.interestedTitle') : t('pages.signup.title')}</h2>
      <div className={styles.box}>
        <LoadingOverlay visible={isLoading} />

        {isInterested ? (
          <InterestedSignupForm
            interestedForm={interestedForm}
            isLoading={isRegisterInterestedLoading}
            handleInterestedSubmit={handleInterestedSubmit}
          />
        ) : (
          <SignupForm
            form={form}
            isLoading={isLoading}
            nationalitiesData={nationalitiesData}
            gendersData={gendersData}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
    </LinesBackground>
  );
};

export default Signup;
