import { FC } from 'react';

import { Box, Flex, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRateTicketMutation } from 'src/api/tickets/hooks';
import { TTicket } from 'src/api/tickets/types';
import Badge from 'src/components/Badge';
import Button from 'src/components/Button';
import Form from 'src/components/Form';
import ModalWrapper from 'src/components/ModalWrapper';
import Rating from 'src/components/Rating';
import Textarea from 'src/components/Textarea';
import { TRateTicketForm } from 'src/types/ticket';

import styles from './RateTicketModal.module.scss';

type Props = {
  ticket: TTicket;
};

const RateTicketModal: FC<ContextModalProps<Props>> = ({
  context,
  id,
  innerProps: { ticket }
}) => {
  const { t } = useTranslation();

  const form = useForm<TRateTicketForm>({
    defaultValues: {
      rate: 0,
      comment: ''
    }
  });

  const handleClose = () => {
    context.closeModal(id);
  };

  const { mutate: rateTicket, isLoading } = useRateTicketMutation();

  const handleSubmit: SubmitHandler<TRateTicketForm> = (data) => {
    rateTicket({ id: ticket.id, ...data });
  };

  return (
    <ModalWrapper
      onClose={handleClose}
      loading={isLoading}
      classNames={{ wrapper: styles.wrapper }}
      closeIconPosition={{ top: 44, end: 40 }}
    >
      <Flex align="center" mb={40}>
        <Text size={14} color="#26495A" mr={5}>
          {t('feature.tickets.ticketNumber')}
        </Text>
        <Text size={14} weight={600} color="#1E93CD" mr={40}>
          {ticket.ticket_number}
        </Text>
        <Badge color="moss">
          {ticket.ticket_status.name}
        </Badge>
      </Flex>
      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <Box mb={40}>
          <Controller
            control={form.control}
            name="rate"
            rules={{
              min: {
                value: 1,
                message: t('errorMessages.required')
              }
            }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <Rating
                value={value}
                onChange={onChange}
                error={error?.message}
                label={t('formLabels.ticketRate')}
                required
              />
            )}
          />
        </Box>

        <Box mb={50}>
          <Controller
            control={form.control}
            name="comment"
            rules={{ required: t('errorMessages.required') }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <Textarea
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.ticketComment')}
                placeholder={t('placeholders.ticketComment')}
                required
                rows={7}
              />
            )}
          />
        </Box>

        <Button type="submit" className={styles.submitButton}>
          {t('common.rate')}
        </Button>
      </Form>
    </ModalWrapper>
  );
};

export default RateTicketModal;
