import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { News } from 'src/api/news/types';
import linesBg from 'src/assets/svg/news-lines-bg.svg';
import Button from 'src/components/Button';
import Container from 'src/components/Container';
import NewsCard from 'src/components/NewsCard';
import Title from 'src/components/Title';
import { Paths } from 'src/router/constants';

import styles from './styles.module.scss';

type Props = {
  news: News[];
};

const LatestNews: FC<Props> = ({ news }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <img src={linesBg} alt="" className={styles.linesBg} />
      <Container>
        <div className={styles.content}>
          <Title className={styles.title}>{t('common.latestNews')}</Title>
          <div className={styles.newsWrapper}>
            {news.map((news) => (
              <NewsCard key={news.id} news={news} />
            ))}
          </div>
          <Link to={Paths.news}>
            <Button className={styles.button}>{t('common.moreNews')}</Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default LatestNews;
