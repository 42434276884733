import { FC } from 'react';

import { Box, TypographyStylesProvider } from '@mantine/core';
import { clsx } from 'clsx';
import { sanitize } from 'dompurify';

import styles from './HTMLParses.module.scss';

type Props = {
  content: string;
  className?: string;
};

const HTMLParser: FC<Props> = ({
  content,
  className
}) => (
  <TypographyStylesProvider color="white">
    <Box
      className={clsx(styles.root, className)}
      dangerouslySetInnerHTML={{ __html: sanitize(content) }}
    />
  </TypographyStylesProvider>
);

export default HTMLParser;
