import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Divider, Flex, Text } from '@mantine/core';

import additionalLogo from 'src/assets/svg/logo-white.svg';
import { Paths } from 'src/router/constants';

import styles from './PreviousCompetition.module.scss';

const PreviousCompetition = () => {
  const [showItems, setShowItems] = useState(false);
  const winnerYear = localStorage.getItem('winnerCompetitionYear');
  const { pathname } = useLocation();
  const years = ['2023']; //todo
  const hiddenBlockByYear = `hiddenBlock${years.length}`;

  useEffect(() => {
    if (pathname !== Paths.winnersCompetition) {
      localStorage.setItem('winnerCompetitionYear', '0');
    }
  }, [pathname]);

  return (
    <Flex
      className={styles.wrapper}
      onMouseEnter={() => setTimeout(() => setShowItems(true), 300)}
      onMouseLeave={() => setShowItems(false)}
    >
      <Flex direction='column' align='center' gap={8}>
        <img src={additionalLogo} alt='logo' width={34} />
        <Text color='#fff' fz={13}>المسابقات السابقة</Text>
      </Flex>

      <Flex gap={24} className={`${styles.hiddenBlock} ${styles[hiddenBlockByYear]}`}>
        <Divider orientation='vertical' ml={24} size={4} color='#6FA75B' h={40} m='auto' />

        <Flex gap={32} align='center' className={showItems ? styles.showItems : styles.hideItems}>
          {years.map((year) => (
            <Link
              reloadDocument
              to={Paths.winnersCompetition}
              className={`${styles.year} ${winnerYear === year && styles.selected}`}
              onClick={() => localStorage.setItem('winnerCompetitionYear', year)}
              key={year}
            >
              {year}
            </Link>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
};

export default PreviousCompetition;
