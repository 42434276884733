import { FC, useState } from 'react';

import { Input, Text, Flex } from '@mantine/core';
import { FileWithPath, DropzoneProps } from '@mantine/dropzone';
import { IconFileUpload } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import Dropzone from 'src/components/Dropzone';

import styles from './FileUpload.module.scss';

type Props = {
  value: File | string | null;
  onChange: (value: File | null) => void;
  label?: string;
  required?: boolean;
  helperText?: string;
  isError?: boolean;
  dragFileText?: string;
} & Pick<DropzoneProps, 'accept' | 'maxSize' | 'disabled'>;

const FileUpload: FC<Props> = ({
  value,
  onChange,
  label,
  required,
  isError,
  disabled,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [isRejectError, setIsRejectError] = useState<boolean>(false);

  const handleDrop = (files: FileWithPath[]) => {
    setIsRejectError(false);
    onChange(files[0]);
  };

  return (
    <>
      {label && (
        <Input.Label required={required}>
          {label}
        </Input.Label>
      )}
      {value ? (
        <div className={styles.fileItem}>
          <Flex gap={15} align="center">
            <Text className={styles.fileName} lineClamp={1}>
              {typeof value === 'string'
                ? value.slice(value.indexOf('_') + 1)
                : value.name}
            </Text>
            <a
              className={styles.viewButton}
              href={typeof value === 'string' ? value : URL.createObjectURL(value)}
              target="_blank" rel="noreferrer"
            >
              {t('common.viewFile')}
            </a>
            {!disabled && (
              <button
                className={styles.deleteButton}
                onClick={() => onChange(null)}
              >
                {t('common.delete')}
              </button>
            )}
          </Flex>
        </div>
      ) : (
        <Dropzone
          onDrop={handleDrop}
          onReject={() => setIsRejectError(true)}
          idleIcon={<IconFileUpload size={60} stroke={1} color="#aebabf" />}
          isError={isError || isRejectError}
          disabled={disabled}
          {...restProps}
        />
      )}
    </>
  );
};

export default FileUpload;
