import { FC } from 'react';

import { Center, Loader } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useGeneralSecretariatQuery } from 'src/api/general-secretariat/hooks';
import listOrnament from 'src/assets/svg/secretariat-list-ornament.svg';
import personOrnament from 'src/assets/svg/secretariat-person-ornament.svg';
import Container from 'src/components/Container';
import PersonCard from 'src/components/PersonCard';
import Title from 'src/components/Title';

import MainPersonCard from './MainPersonCard';
import styles from './styles.module.scss';

const GeneralSecretariat: FC = () => {
  const { t } = useTranslation();

  const { data, isLoading, isSuccess } = useGeneralSecretariatQuery();

  return (
    <div className={styles.wrapper}>
      <img src={personOrnament} alt="" className={styles.mainPersonLeftOrnament} />
      <img src={personOrnament} alt="" className={styles.mainPersonRightOrnament} />
      <img src={listOrnament} alt="" className={styles.listTopOrnament} />
      <img src={listOrnament} alt="" className={styles.listBottomOrnament} />
      <Container variant="sm">
        <div className={styles.content}>
          <Title
            color="eden"
            fontSize={24}
            spacing={70}
            placing="center"
          >
            {t('common.generalSecretariat')}
          </Title>
          {isLoading && (
            <Center py={50}>
              <Loader size="lg" />
            </Center>
          )}
          {isSuccess && (
            <>
              <div className={styles.mainPersonWrapper}>
                <MainPersonCard
                  imageSrc={data[0].image_path}
                  name={data[0].name}
                  position={data[0].position}
                />
              </div>
              <div className={styles.listWrapper}>
                {data.slice(1).map((item) => (
                  <PersonCard
                    key={item.id}
                    imageSrc={item.image_path}
                    name={item.name}
                    position={item.position}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default GeneralSecretariat;
