import { useMutation } from '@tanstack/react-query';

import { showSuccessNotification } from 'src/helpers/notifications';
import { Paths } from 'src/router/constants';
import { router } from 'src/router/router';
import { ErrorResponse, SuccessResponse } from 'src/types/api';

import { participatingApi } from './api';
import {
  ParticipatingSendOTPCodeParams,
  ParticipatingVerifyOTPCodePayload,
  ParticipatingApproveParticipatingPayload
} from './types';

export const useParticipatingSentOTPCodeMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, ParticipatingSendOTPCodeParams>({
    mutationFn: async (params) => participatingApi.sendOTPCode(params)
  });
};

export const useParticipatingVerifyOTPCodeMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, ParticipatingVerifyOTPCodePayload>({
    mutationFn: async (payload) => participatingApi.verifyOTPCode(payload)
  });
};

export const useParticipatingApproveParticipatingMutation = () => {
  return useMutation<SuccessResponse, ErrorResponse, ParticipatingApproveParticipatingPayload>({
    mutationFn: async (payload) => participatingApi.approveParticipating(payload),
    onSuccess: (data) => {
      router.navigate(Paths.homepage);
      showSuccessNotification({ message: data?.message || '' });
    }
  });
};
