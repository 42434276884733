import { FC } from 'react';

import { clsx } from 'clsx';

import { accountSections, TAccountSectionName } from '../helpers';
import styles from './styles.module.scss';

type TProps = {
  activeSection: TAccountSectionName | undefined;
};

const AccountNav: FC<TProps> = ({ activeSection }) => (
  <div className={styles.nav}>
    {accountSections.map((item) => (
      <div
        key={item.id}
        className={clsx(styles.navLinkWrapper, {
          [styles.active]: activeSection === item.id
        })}
      >
        <div className={styles.navLinkLine} />
        <p className={styles.navLink}>
          {item.title}
        </p>
      </div>
    ))}
  </div>
);

export default AccountNav;
