import { useQuery } from '@tanstack/react-query';

import { getParticipatingTypes } from './api';
import { ParticipatingType } from './types';

export const useParticipatingTypesQuery = () => {
  return useQuery<ParticipatingType[]>({
    queryKey: ['participating-types'],
    queryFn: async () => getParticipatingTypes().then((res) => res.data)
  });
};
