import { FC, useEffect, useState } from 'react';

import { Center, Loader, ActionIcon } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import ReactPlayer from 'react-player';
import { useNavigate, useParams } from 'react-router-dom';

import { useVideoGalleryItemQuery } from 'src/api/video-gallery/helpers';
import ModalPage from 'src/components/ModalPage';
import { useModalPage } from 'src/hooks/useModalPage';
import { Paths } from 'src/router/constants';

import styles from './styles.module.scss';

const VideoDetails: FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ videoId: string }>();
  const { isOpen, handleClose } = useModalPage();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const { data, isLoading } = useVideoGalleryItemQuery(params.videoId || '');

  useEffect(() => {
    if (data === null) {
      navigate(Paths.videoGallery);
    }
  }, [data, navigate]);

  return (
    <ModalPage isOpen={isOpen} isVideo>
      {isLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {data && (
        <div className={styles.content}>
          <ActionIcon className={styles.closeIcon} onClick={handleClose}>
            <IconX />
          </ActionIcon>
          <ReactPlayer
            url={data.video_path}
            config={{ file: { attributes: { poster: data.cover_image_path } } }}
            width="100%"
            volume={0.5}
            controls
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            playing={isPlaying}
          />
          <h3 className={styles.title}>
            {data.summary}
          </h3>
        </div>
      )}
    </ModalPage>
  );
};

export default VideoDetails;
