import { FC } from 'react';
import { Text } from '@mantine/core';

interface TProps {
  item: string | null;
  searchedText: string;
}

const ResultsItem: FC<TProps> = ({ item, searchedText }) => (
  <>
    {item && item.toLowerCase().includes(searchedText) ? (
      <Text>
        {item.toLowerCase().split(new RegExp(`(${searchedText.toLowerCase()})`, 'gi')).map((part: string, index: number) => 
        part.toLowerCase() === searchedText.toLowerCase() ? 
          <span key={index} style={{background: '#1E93CD99'}}>{part}</span> : part
        )}
      </Text>
    ) : ''}
  </>
);

export default ResultsItem;
