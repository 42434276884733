import { FC, useMemo } from 'react';

import { FieldErrors, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Stepper, { TStepperItem } from 'src/components/Stepper';

import { TFormStepperProps } from './types';

const checkKeysForErrors = (errors: FieldErrors, keys: string[]) => {
  for (const key of keys) {
    if (key in errors) {
      return true;
    }
  }
};

const step1Keys = ['employer', 'city_id', 'participating_role_id', 'cv'];
const step2GroupKeys = ['number_of_particepants', 'partcipants_data', 'total_percentage'];
const step3Keys = ['innovation_data'];
const step4Keys = [
  'number_of_applications',
  'application_data',
  'is_agreed_work_originality',
  'is_agreed_for_regukations_and_rules',
  'is_agreed_for_sharing_his_data',
  'is_agreed_for_terms_and_conditions'
];

const FormStepper: FC<TFormStepperProps> = ({
  activeStep,
  form
}) => {
  const { t } = useTranslation();

  const { errors } = useFormState({ control: form.control });

  const participantType = useWatch({
    control: form.control,
    name: 'participating_type_id'
  });

  const step1 = checkKeysForErrors(errors, step1Keys);
  const step2 = participantType === '2' ? checkKeysForErrors(errors, step2GroupKeys) : false;
  const step3 = checkKeysForErrors(errors, step3Keys);
  const step4 = checkKeysForErrors(errors, step4Keys);

  const steps = useMemo((): TStepperItem[] => {
    return [
      {
        label: t('common.personalInfo'),
        isError: step1
      },
      {
        label: t('pages.competitionRegistration.participantsData'),
        isError: step2
      },
      {
        label: t('pages.competitionRegistration.innovationData'),
        isError: step3
      },
      {
        label: t('pages.competitionRegistration.impactOfInnovation'),
        isError: step4
      }
    ];
  }, [step1, step2, step3, step4, t]);

  return <Stepper items={steps} activeStep={activeStep} />;
};

export default FormStepper;
