import { FC, useCallback } from 'react';

import { Box, Drawer, Burger, clsx, Flex, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, NavLink, useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as HelpIcon } from 'src/assets/svg/faqs.svg';
import smallLogo from 'src/assets/svg/logo-colored.svg';
import whiteSmallLogo from 'src/assets/svg/logo-row-white-small.svg';
import { openLoginModal } from 'src/helpers/modals';
import { Paths } from 'src/router/constants';
import { useAuthStore } from 'src/store/useAuthStore';

import AboutDropdown from '../AboutDropdown';
import LanguageDropdown from '../LanguageDropdown';
import Link from '../Link';
import UserMenu from '../UserMenu';
import styles from './styles.module.scss';
import { useShowHidePageListQuery } from 'src/api/content-management/hooks';

type TBurgerMenuProps = {
  navbarVariant: 'light' | 'transparent';
  showLanguageDropdown: boolean;
};

const BurgerMenu: FC<TBurgerMenuProps> = ({
  navbarVariant,
  showLanguageDropdown
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [opened, { close, toggle }] = useDisclosure(false);
  const { user } = useAuthStore();
  const navigate = useNavigate();
  const { data: Content, isLoading: ContentLoading } = useShowHidePageListQuery();

  const isShowWinners = !ContentLoading && Content?.data.find((item) => item.name === 'Winners in Latest Comp')?.is_show;
  const isShowInnovations = !ContentLoading && Content?.data.find((item) => item.name === 'Innovations')?.is_show;

  const handleContactUsClick = useCallback(() => {
    close();

    if (user) {
      navigate(Paths.contactUs);
    } else {
      openLoginModal({ navigateAfterSuccessPath: Paths.contactUs });
    }
  }, [user, navigate, close]);

  return (
    <Box w="100%">
      <Flex gap={20} align="center" justify="space-between">
        <Group>
          <Burger
            opened={opened}
            onClick={toggle}
            color={navbarVariant === 'transparent' ? 'white' : 'black'}
            transitionDuration={1000}
            pr={20}
          />
          {showLanguageDropdown && (
            <LanguageDropdown navbarVariant={navbarVariant} />
          )}
        </Group>
        <Group>
          {user ? (
            <UserMenu user={user} navbarVariant={navbarVariant} />
          ) : (
            <button
              className={clsx(styles.loginButton, { [styles.dark]: navbarVariant === 'light' })}
              onClick={() => openLoginModal()}
            >
              {t('common.logIn')}
            </button>
          )}
          {navbarVariant === 'transparent' ? (
            <img src={whiteSmallLogo} className={styles.closedLogo} alt="Logo" width={40} height={40} />
          ) : (
            <img src={smallLogo} className={styles.closedLogo} alt="Logo" width={40} height={40} />
          )}
        </Group>
      </Flex>

      <Drawer
        opened={opened}
        onClose={close}
        size="100%"
        withCloseButton={false}
        transitionDuration={500}
        classNames={{ drawer: styles.content }}
      >
        <div className={styles.startSection}>
          <Burger opened={opened} onClick={toggle} color="white" transitionDuration={1000} />
          <img src={whiteSmallLogo} alt="Logo" width={40} height={40} />
          <NavLink to={Paths.homepage} onClick={close}>
            {({ isActive }) => (
              <Link navbarVariant="transparent" isActive={isActive}>
                {t('common.homepage')}
              </Link>
            )}
          </NavLink>
          <AboutDropdown navbarVariant="transparent" onClick={close} />
          {isShowInnovations && (
            <NavLink to={Paths.innovation} end={false} onClick={close}>
              {({ isActive }) => (
                <Link navbarVariant={navbarVariant} isActive={isActive}>
                  {t('common.innovations')}
                </Link>
              )}
            </NavLink>
          )}
          {isShowWinners && (
            <NavLink to={Paths.winners} end={false} onClick={close}>
              {({ isActive }) => (
                <Link navbarVariant={navbarVariant} isActive={isActive}>
                  {t('common.winners')}
                </Link>
              )}
            </NavLink>
          )}
          <NavLink to={Paths.mediaCenter} end={false} onClick={close}>
            {({ isActive }) => (
              <Link navbarVariant="transparent" isActive={isActive}>
                {t('common.mediaCenter')}
              </Link>
            )}
          </NavLink>
          <Box onClick={handleContactUsClick}>
            <Link
              navbarVariant="transparent"
              isActive={pathname.startsWith(Paths.contactUs)}
            >
              {t('common.contactUs')}
            </Link>
          </Box>
          <RouterLink to={Paths.faq} className={styles.faqSection}>
            <div className={styles.faq}> {t('common.faq')}</div>
            <HelpIcon className={styles.iconButton} />
          </RouterLink>
        </div>
      </Drawer>
    </Box>
  );
};

export default BurgerMenu;
