import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import LinesBackground from 'src/components/LinesBackground';
import Title from 'src/components/Title';
import { TAccountInterestedSectionName, TAccountSectionName } from 'src/pages/Account/helpers';

import AccountNav from './AccountNav';
import CompetitionsInfo from './CompetitionsInfo';
import PersonalInfo from './PersonalInfo';
import styles from './styles.module.scss';
import SupportTickets from './SupportTickets';
import InterestedPersonalInfo from './InterestedPersonalInfo';
import { useAuthStore } from 'src/store/useAuthStore';
import InterestedAccountNav from './AccountNav/InterestedAccountNav';

const Account: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuthStore();

  const [activeSection, setActiveSection] = useState<TAccountSectionName>('personal-info');
  const [activeInterestedSection, setActiveInterestedSection] = useState<TAccountInterestedSectionName>('personal-info');

  return (
    <LinesBackground classes={{ contentWrapper: styles.wrapper }}>
      <div className={styles.contentWrapper}>
        <div className={styles.navWrapper}>
          <div className={styles.navBlock}>
          {user?.entity ? (
            <InterestedAccountNav activeSection={activeInterestedSection} />
          ) : (
            <AccountNav activeSection={activeSection} />
          )}
          </div>
        </div>
        <div className={styles.content}>
          <Title
            color="eden"
            fontSize="small"
            spacing={0}
            underlineTopSpacing={10}
          >
            {t('common.personalAccount')}
          </Title>
          {user?.entity ? (
            <InterestedPersonalInfo setActiveSection={setActiveInterestedSection} />
          ) : (
            <>
              <PersonalInfo setActiveSection={setActiveSection} />
              <CompetitionsInfo setActiveSection={setActiveSection} />
            </>
          )}
          <SupportTickets setActiveSection={setActiveSection} setActiveInterestedSection={setActiveInterestedSection} />
        </div>
      </div>
    </LinesBackground>
  );
};

export default Account;
