import privateApi from 'src/configs/private-api';
import { getFormData } from 'src/helpers/forms';

import {
  TGetTicketByIdResponse,
  TGetTicketResponse,
  TTicketRequest,
  TTicketResponse,
  TUpdateTicketRequest,
  TRateTicketRequest,
  TRateTicketResponse
} from './types';

export const postTicket = async (data: TTicketRequest): Promise<TTicketResponse> => {
  return privateApi.post('/ticket', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const getTickets = async (page: number): Promise<TGetTicketResponse> => {
  return privateApi.get('/ticket', {
    params: {
      page_size: 5,
      page_number: page
    }
  }).then((res) => res.data);
};

export const getTicketById = async (id: string): Promise<TGetTicketByIdResponse> => {
  return privateApi.get('/ticket', {
    params: { id }
  }).then((res) => res.data);
};

export const updateTicket = async (data: TUpdateTicketRequest): Promise<TTicketResponse> => {
  return privateApi.put('/ticket', getFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => res.data);
};

export const cancelTicket = async (id: string): Promise<TTicketResponse> => {
  return privateApi.delete(`/ticket`, {
    params: { id }
  }).then((res) => res.data);
};

export const rateTicket = async (data: TRateTicketRequest): Promise<TRateTicketResponse> => {
  return privateApi.put('/ticket/rate', data).then((res) => res.data);
};
