type Item = {
  id: string | number;
} & Record<string, any>;

export type NormalizedItem = {
  value: string;
  label: string;
};

export const useNormalizedItemsData = (
  items: Item[] | undefined,
  labelKey: string = 'name'
): NormalizedItem[] => {
  if (!items || !items.length) {
    return [];
  }

  return items.map((item) => ({
    value: `${item.id}`,
    label: item[labelKey]
  }));
};
