import { FC } from 'react';

import { clsx } from 'clsx';

import { prefixStyle } from 'src/helpers/styles';

import styles from './styles.module.scss';
import { TTitleProps } from './types';

const Title: FC<TTitleProps> = ({
  children,
  withUnderline = true,
  className,
  color = 'light',
  fontSize = 'medium',
  spacing = 'medium',
  placing,
  underlineWidth,
  underlineTopSpacing,
  isMobile
}) => (
  <div
    className={clsx(styles.wrapper, {
      [prefixStyle('spacing', spacing)]: spacing,
      [prefixStyle('placing', placing)]: placing,
      [styles.isMobile]: isMobile
    }, className)}
  >
    <h3
      className={clsx(styles.title, {
        [prefixStyle('color', color)]: color,
        [prefixStyle('fontSize', fontSize)]: fontSize
      })}
    >
      {children}
    </h3>
    {withUnderline && (
      <div className={clsx(styles.underline, {
        [prefixStyle('underlineWidth', underlineWidth)]: underlineWidth,
        [prefixStyle('underlineTopSpacing', underlineTopSpacing)]: underlineTopSpacing
      })}
      />
    )}
  </div>
);

export default Title;
