import { FC } from 'react';

import { Box, Flex, Input, LoadingOverlay, SimpleGrid, Stack } from '@mantine/core';
import { PDF_MIME_TYPE } from '@mantine/dropzone';
import { useMediaQuery } from '@mantine/hooks';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCitiesQuery } from 'src/api/cities/hooks';
import { useParticipatingRolesQuery } from 'src/api/participating-roles/hooks';
import Button from 'src/components/Button';
import { StepProps } from 'src/components/CompetitionRegistrationForm/types';
import DatePicker from 'src/components/DatePicker';
import FileUpload from 'src/components/FileUpload';
import Select from 'src/components/Select';
import TextInput from 'src/components/TextInput';
import { useNormalizedItemsData } from 'src/hooks/useNormalizedItemsData';
import { useAuthStore } from 'src/store/useAuthStore';

const Step1: FC<StepProps> = ({
  form,
  goToNext,
  isLoading,
  isDisabled
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 600px)');

  const { user } = useAuthStore();
  const { data: cities, isLoading: isCitiesLoading } = useCitiesQuery();
  const { data: participatingRoles, isLoading: isParticipatingRolesLoading } = useParticipatingRolesQuery();

  const participatingRolesData = useNormalizedItemsData(participatingRoles);
  const citiesData = useNormalizedItemsData(cities);

  const isStepLoading = isLoading || isCitiesLoading || isParticipatingRolesLoading;

  return (
    <>
      <LoadingOverlay visible={isStepLoading} />

      <div>
        {!isMobile && <Input.Label>
          {t('formLabels.fullName')}
        </Input.Label>}
        <Stack spacing={isMobile ? 0 : 5}>
          <SimpleGrid cols={isMobile ? 1 : 3} spacing={isMobile ? 10 : 28} mb={{ base: 10, lg: 40 }}>
            <div>
              {isMobile && <Input.Label>
                {t('formLabels.firstName')}
              </Input.Label>}
              <TextInput
                value={user?.first_name || ''}
                disabled
              />
            </div>

            <div>
              {isMobile && <Input.Label>{t('formLabels.middleName')}</Input.Label>}
              <TextInput
                value={user?.second_name || ''}
                disabled
              />
            </div>

            <div>
              {isMobile && <Input.Label>{t('formLabels.lastName')}</Input.Label>}
              <TextInput
                value={user?.last_name || ''}
                disabled
              />
            </div>
          </SimpleGrid>
        </Stack>

        <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120} mb={{ base: 10, lg: 40 }}>
          <TextInput
            value={user?.user_identity || ''}
            label={t('formLabels.idOrIqamaNumber')}
            disabled
          />

          <Select
            value={user?.nationality?.name || ''}
            data={[user?.nationality?.name || '']}
            label={t('formLabels.nationality')}
            disabled
          />
        </SimpleGrid>

        <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120} mb={{ base: 10, lg: 40 }}>
          <DatePicker
            value={user?.birthday ? new Date(user.birthday) : null}
            label={t('formLabels.birthDate')}
            disabled
          />

          <Select
            value={user?.gender?.name || ''}
            data={[user?.gender?.name || '']}
            label={t('formLabels.gender')}
            disabled
          />
        </SimpleGrid>

        <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120} mb={{ base: 10, lg: 40 }}>
          <TextInput
            value={user?.email || ''}
            label={t('formLabels.email')}
            type="email"
            disabled
          />

          <TextInput
            value={user?.mobile || ''}
            label={t('formLabels.phoneNumber')}
            type="tel"
            disabled
          />
        </SimpleGrid>

        <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120} mb={{ base: 10, lg: 40 }}>
          <Controller
            control={form.control}
            name="employer"
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.employer')}
                placeholder={t('placeholders.employer')}
                required
                disabled={isStepLoading || isDisabled}
              />
            )}
          />

          <Controller
            control={form.control}
            name="city_id"
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <Select
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                data={citiesData}
                label={t('formLabels.city')}
                placeholder={t('placeholders.city')}
                required
                searchable
                clearable
                disabled={isStepLoading || isDisabled}
              />
            )}
          />
        </SimpleGrid>

        <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120} mb={{ base: 10, lg: 40 }}>
          <Controller
            control={form.control}
            name="participating_role_id"
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <Select
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                data={participatingRolesData}
                label={t('formLabels.roleInInnovation')}
                placeholder={t('placeholders.roleInInnovation')}
                required
                clearable
                disabled={isStepLoading || isDisabled}
              />
            )}
          />
        </SimpleGrid>

        <Box mb={50}>
          <Controller
            control={form.control}
            name="cv"
            rules={{ required: t('errorMessages.required') }}
            render={({
              field: { value, onChange },
              fieldState: { error }
            }) => (
              <FileUpload
                value={value}
                onChange={onChange}
                label={t('formLabels.CVOrResume')}
                required
                isError={!!error}
                helperText={t('formTexts.pdf25MB')}
                dragFileText={t('formTexts.dragCvOrResumeFile')}
                accept={PDF_MIME_TYPE}
                maxSize={25 * 1000 * 1000}
                disabled={isStepLoading || isDisabled}
              />
            )}
          />
        </Box>

        <Flex justify="flex-end" h={40}>
          <Button onClick={goToNext}>{t('common.next')}</Button>
        </Flex>
      </div>
    </>
  );
};

export default Step1;
