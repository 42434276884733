import { Dispatch, FC, SetStateAction, useMemo } from 'react';

import { Center, Flex, Loader, Table, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

import {
  useCurrentCompetitionQuery,
  useParticipantRequestApprovesQuery,
  useUserParticipatingRequestsQuery
} from 'src/api/competition/hooks';
import { useCurrentCompetitionUserInfo } from 'src/helpers/competition';
import { isDateAfterCurrent } from 'src/helpers/dates';
import { renderParticipantApprovedStatus } from 'src/modules/competition/helpers';
import Link from 'src/pages/Account/Link';
import Title from 'src/pages/Account/Title';
import { Paths } from 'src/router/constants';

import { TAccountSectionName } from '../helpers';
import styles from './styles.module.scss';
import { colors } from 'src/modules/competition/constants';

type Props = {
  setActiveSection: Dispatch<SetStateAction<TAccountSectionName>>;
};

const CompetitionsInfo: FC<Props> = ({ setActiveSection }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 600px)');

  const { data: requests, isLoading: isRequestsLoading } = useUserParticipatingRequestsQuery();
  const { data: competition, isLoading: isCurrentCompetitionLoading } = useCurrentCompetitionQuery();

  const userRequestInfo = useCurrentCompetitionUserInfo(competition, requests);

  const { data: requestApprovals, isFetching: isRequestApprovalsLoading } = useParticipantRequestApprovesQuery({
    requestId: userRequestInfo?.id || '',
    enabled: !!userRequestInfo?.id
  });

  const isCurrentCompetitionExpired = useMemo(() => {
    if (competition) {
      return isDateAfterCurrent(competition.end_date);
    } else {
      return true;
    }
  }, [competition]);

  const isEmpty = !requests?.length;

  const isLoading = isRequestsLoading || isCurrentCompetitionLoading || isRequestApprovalsLoading;

  return (
    <InView
      as="div"
      threshold={0.5}
      className={styles.wrapper}
      onChange={(inView) => inView && setActiveSection('competition-status')}
    >
      <Flex
        justify="space-between"
        mb={isEmpty ? 62 : 40}
        className={styles.headerWrapper}
      >
        <Title>{t('pages.account.competitionStatus')}</Title>

        {userRequestInfo === null && !isCurrentCompetitionExpired ? (
          <Link onCLick={() => navigate(Paths.competitionRegistration)}>
            {t('pages.account.registerToCompetition')}
          </Link>
        ) : null}

        {userRequestInfo ? (
          <Link onCLick={() => navigate(Paths.competitionRegistration)}>
            {t('pages.account.viewCompetition')}
          </Link>
        ) : null}
      </Flex>

      {isLoading ? (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      ) : (
        <>
          {isEmpty ? (
            <p className={styles.emptyText}>
              {t('pages.account.competitionEmpty')}
            </p>
          ) : (
            <>
              {!!isMobile
                ? (
                  <Flex direction="column">
                    {requests?.map((request) => (
                      <Flex key={request.id} direction="column">
                        <Flex justify="space-between" mb={15}>
                          <Text className={styles.requestNumberColumnHead}>
                            {t('feature.requests.number')}
                          </Text>
                          <Text align="end">
                            {t('feature.requests.numberText')}
                            <Text span className={styles.requestNumber}>
                              {request.request_number}
                            </Text>
                          </Text>
                        </Flex>
                        <Flex justify="space-between" mb={15}>
                          <Text className={styles.requestNumberColumnHead}>
                            {t('common.path')}
                          </Text>
                          <Text align="end">
                            {request.path?.name}
                          </Text>
                        </Flex>
                        <Flex justify="space-between" mb={15}>
                          <Text className={styles.requestNumberColumnHead}>
                            {t('feature.requests.status')}
                          </Text>
                          <Text align="end">
                            {request.status?.name}
                          </Text>
                        </Flex>
                        <Flex justify="space-between" mb={15}>
                          <Text className={styles.requestNumberColumnHead}>
                            {t('feature.requests.registrationPhase')}
                          </Text>
                          <Text align="end">
                            {request.evaluation_stage?.name}
                          </Text>
                        </Flex>
                        <Flex justify="space-between" mb={15}>
                          <Text className={styles.requestNumberColumnHead}>
                            {t('feature.requests.notes')}
                          </Text>
                          <Text align="end">
                            {request.request_comments?.generalComment || '-'}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>)
                : <Table
                  className={styles.table}
                  verticalSpacing={14}
                  horizontalSpacing="sm"
                  fontSize={10}
                >
                  <thead>
                    <tr className={styles.tableHeadingRow}>
                      <th className={styles.requestNumberColumnHead}>{t('feature.requests.number')}</th>
                      <th>{t('common.path')}</th>
                      <th>{t('feature.requests.status')}</th>
                      <th>{t('feature.requests.registrationPhase')}</th>
                      <th>{t('feature.requests.notes')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {requests?.map((request) => (
                      <tr className={styles.tableRow} key={request.id}>
                        <td>
                          <Text>
                            {t('feature.requests.numberText')}
                            <Text span className={styles.requestNumber}>
                              {request.request_number}
                            </Text>
                          </Text>
                        </td>
                        <td>{request.path?.name}</td>
                        <td style={{color: `${request?.status?.id && colors[request?.status?.id]}`}}>
                          {request.status?.name}
                        </td>
                        <td>{request.evaluation_stage?.name}</td>
                        <td>{request.request_comments?.generalComment || '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>}

              {requestApprovals ? (
                <>
                  {!!isMobile ? <Flex direction="column" mt={40}>
                    <h3 className={styles.approvalTableTitle}>{t('feature.requests.participantsApproval')}</h3>
                    {requestApprovals.partcipants_data.map((participant, index) => (
                      <Flex key={participant.user_identity} direction="column" mt={15}>
                        <Flex justify="space-between" mb={10}>
                          <Text className={styles.requestNumberColumnHead}>{t('feature.requests.participantName')}</Text>
                          <Text align='end'>{`${participant.first_name} ${participant.second_name} ${participant.last_name}`}</Text>
                        </Flex>
                        <Flex justify="space-between" mb={10}>
                          <Text className={styles.requestNumberColumnHead}>{t('feature.requests.participantPercentage')}</Text>
                          <Text>{participant.participation_percentage}%</Text>
                        </Flex>
                        <Flex justify="space-between" mb={10}>
                          <Text className={styles.requestNumberColumnHead}>{t('common.status')}</Text>
                          <Text>{renderParticipantApprovedStatus(participant.isAprroved)}</Text>
                        </Flex>
                        <Flex justify="space-between" mb={10}>
                          <Text className={styles.requestNumberColumnHead}>{t('feature.requests.approvalFile')}</Text>
                          {participant.isAprroved ? (
                            <a
                              className={styles.viewButton}
                              href={participant.approve_file_path || ''}
                              target="_blank" rel="noreferrer"
                            >
                              {t('common.viewFile')}
                            </a>
                          ) : '-'}
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                    : <><h3 className={styles.approvalTableTitle}>
                      {t('feature.requests.participantsApproval')}
                    </h3>

                      <Table
                        className={styles.table}
                        verticalSpacing={14}
                        horizontalSpacing="sm"
                        fontSize={10}
                      >
                        <thead>
                          <tr className={styles.tableHeadingRow}>
                            <th>{t('feature.requests.participantName')}</th>
                            <th className={styles.centered}>{t('feature.requests.participantPercentage')}</th>
                            <th className={styles.centered}>{t('common.status')}</th>
                            <th className={styles.centered}>{t('feature.requests.approvalFile')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {requestApprovals.partcipants_data.map((participant, index) => (
                            <tr className={styles.tableRow} key={participant.user_identity || index}>
                              <td>
                                {`${participant.first_name} ${participant.second_name} ${participant.last_name}`}
                              </td>
                              <td className={styles.centered}>
                                {participant.participation_percentage}%
                              </td>
                              <td className={styles.centered}>
                                {renderParticipantApprovedStatus(participant.isAprroved)}
                              </td>
                              <td className={styles.centered}>
                                {participant.isAprroved ? (
                                  <a
                                    className={styles.viewButton}
                                    href={participant.approve_file_path || ''}
                                    target="_blank" rel="noreferrer"
                                  >
                                    {t('common.viewFile')}
                                  </a>
                                ) : '-'}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table></>}
                </>
              ) : null}
            </>
          )}
        </>
      )}
    </InView>
  );
};

export default CompetitionsInfo;
