import { FC } from 'react';

import { Center, Loader, Image, Text } from '@mantine/core';

import { useAboutPrizeQuery } from 'src/api/about-prize/hooks';
import prizeGoalsImage from 'src/assets/png/plane-photo.png';
import aboutAwardLinesSmall from 'src/assets/svg/about-award-lines-bg-small.svg';
import aboutAwardLines from 'src/assets/svg/about-award-lines-bg.svg';
import aboutAwardOrnamentBg from 'src/assets/svg/about-award-ornament-bg.svg';
import Container from 'src/components/Container';
import HTMLParser from 'src/components/HTMLParser';
import Title from 'src/components/Title';

import styles from './styles.module.scss';

const AboutPrize: FC = () => {
  const { data, isLoading, isSuccess } = useAboutPrizeQuery();

  return (
    <div className={styles.wrapper}>
      {isLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {isSuccess && (
        <>
          <div className={styles.aboutAwardWrapper}>
            <img src={aboutAwardOrnamentBg} alt="" className={styles.aboutAwardOrnamentBg} />
            <Container variant="lg">
              <div className={styles.aboutAwardContent}>
                <Image
                  src={data.brief.image_path}
                  alt="About Award"
                  width={382}
                  height={242}
                  fit="cover"
                  withPlaceholder
                />
                <div>
                  <Title spacing="small">
                    {data.brief.title}
                  </Title>
                  <p className={styles.aboutAwardText}>
                    {data.brief.text}
                  </p>
                </div>
              </div>
            </Container>
          </div>
          <div className={styles.prizeVisionWrapper}>
            <img src={aboutAwardLines} alt="" className={styles.prizeVisionLines} />
            <Container variant="lg">
              <div className={styles.prizeVisionContent}>
                <Title color="darkGreen">
                  {data.vision.title}
                </Title>
                <Text>{data.vision.text}</Text>
              </div>
            </Container>
          </div>
          <div className={styles.prizeGoalsWrapper}>
            <img src={aboutAwardOrnamentBg} alt="" className={styles.prizeGoalsOrnamentBg} />
            <div className={styles.prizeGoalsContentWrapper}>
              <Container variant="lg">
                <div className={styles.prizeGoalsContent}>
                  <img
                    alt="Prize Goal"
                    src={prizeGoalsImage}
                    className={styles.prizeGoalsPhoto}
                  />
                  <div className={styles.prizeGoals}>
                    <Title spacing="small">
                      {data.goals.title}
                    </Title>
                    <HTMLParser
                      className={styles.prizeGoalsText}
                      content={data.goals.text}
                    />
                  </div>
                </div>
              </Container>
            </div>
            <img src={aboutAwardLinesSmall} alt="" className={styles.prizeGoalsLines} />
          </div>
        </>
      )}
    </div>
  );
};

export default AboutPrize;
