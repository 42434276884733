import { ComponentPropsWithoutRef, FC } from 'react';

import { Box, Flex, Text } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import clsx from 'clsx';

import Tooltip from 'src/components/Tooltip';

import styles from './Textarea.module.scss';

type Props = {
  label?: string;
  error?: string | boolean;
  info?: string;
  required?: boolean;
  helperText?: string;
  maxCounter?: number;
} & ComponentPropsWithoutRef<'textarea'>;

const Textarea: FC<Props> = ({
  value,
  label,
  info,
  required,
  error,
  helperText,
  maxCounter,
  ...restProps
}) => (
  <Box>
    {label ? (
      <Flex mb={8} align="flex-end">
        <p className={styles.label}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </p>

        {info && (
          <Tooltip label={info} width={400}>
            <Box h={20} ml={8}>
              <IconInfoCircle size={20} color="#26495a" />
            </Box>
          </Tooltip>
        )}
      </Flex>
    ) : null}
    <textarea
      className={clsx(styles.textarea, { [styles.textareaError]: error })}
      value={value}
      {...restProps}
    />
    {error && typeof error === 'string' ? (
      <p className={styles.error}>{error}</p>
    ) : null}
    {helperText || (maxCounter && typeof value === 'string') ? (
      <Flex justify="space-between">
        {helperText && (
          <Text className={clsx(styles.helperText, { [styles.helperTextError]: error })}>
            {helperText}
          </Text>
        )}
        {maxCounter && typeof value === 'string' ? (
          <Text size={12} className={clsx({ [styles.counterError]: error })}>
            <span>{value.length}</span>/<span>{maxCounter}</span>
          </Text>
        ) : null}
      </Flex>
    ) : null}
  </Box>
);

export default Textarea;
