import { FC, ReactNode } from 'react';

import { QueryClientProvider as Provider } from '@tanstack/react-query';

import queryClient from 'src/configs/query-client';

type Props = {
  children: ReactNode;
};

const QueryClientProvider: FC<Props> = ({ children }) => (
  <Provider client={queryClient}>
    {children}
  </Provider>
);

export default QueryClientProvider;
