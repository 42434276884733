import { FC, ReactNode } from 'react';

import { Tooltip as MantineTooltip } from '@mantine/core';

import styles from './styles.module.scss';

type Props = {
  children: ReactNode;
  label: string;
  width?: number;
};

const Tooltip: FC<Props> = ({
  children,
  label,
  width
}) => (
  <MantineTooltip
    label={label}
    withArrow
    classNames={{ tooltip: styles.tooltip }}
    multiline
    width={width}
  >
    {children}
  </MantineTooltip>
);
export default Tooltip;
