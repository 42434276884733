import { FC, ReactNode } from 'react';

import { Stack, Input, Text, Divider, Group } from '@mantine/core';
import { Dropzone as MantineDropzone, DropzoneProps } from '@mantine/dropzone';
import { IconUpload, IconX } from '@tabler/icons-react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './Dropzone.module.scss';

type Props = Omit<DropzoneProps, 'children'> & {
  label?: string;
  required?: boolean;
  helperText?: string;
  isError?: boolean;
  idleIcon?: ReactNode;
  dragFileText?: string;
};

const Dropzone: FC<Props> = ({
  label,
  required,
  helperText,
  isError,
  idleIcon,
  disabled,
  dragFileText,
  ...restProps
}) => {
  const { t } = useTranslation();

  return (
    <>
      {label && (
        <Input.Label required={required}>
          {label}
        </Input.Label>
      )}
      <MantineDropzone
        classNames={{
          root: clsx(styles.root, {
            [styles.error]: isError,
            [styles.disabled]: disabled
          })
        }}
        disabled={disabled}
        {...restProps}
      >
        <Stack spacing={20} align="center">
          <MantineDropzone.Idle>
            {idleIcon || <IconUpload size={60} stroke={1} color="#aebabf" />}
          </MantineDropzone.Idle>
          <MantineDropzone.Accept>
            <IconUpload size={60} stroke={1} />
          </MantineDropzone.Accept>
          <MantineDropzone.Reject>
            <IconX size={60} stroke={1} />
          </MantineDropzone.Reject>

          <Stack align="center" spacing={5}>
            <Text size={10} color="#aebabf">
              {dragFileText || t('formTexts.dragFile')}
            </Text>
            <Group spacing={5}>
              <Divider w={80} />
              <Text size={10} color="#26495a">
                {t('common.or')}
              </Text>
              <Divider w={80} />
            </Group>
            <Text size={10} color="#26495a">
              {t('formTexts.chooseFile')}
            </Text>
          </Stack>
        </Stack>
      </MantineDropzone>
      {helperText && (
        <Text size={11} className={clsx(styles.helperText, { [styles.helperTextError]: isError })}>
          {helperText}
        </Text>
      )}
    </>
  );
};

export default Dropzone;
