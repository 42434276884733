export enum Paths {
  homepage = '/',
  error = '/error',
  resetPassword = '/reset-password',
  signup = '/signup',
  interestedSignup = '/signup-interested',
  account = '/account',
  faq = '/faq',
  contactUs = '/contact-us',
  termsAndConditions = '/terms-and-conditions',
  privacyPolicy = '/privacy-policy',
  aboutPrize = '/about-prize',
  competitionRegistration = '/competition-registration',
  competitionPaths = '/competition-paths',
  supervisoryBoard = '/supervisory-board',
  generalSecretariat = '/general-secretariat',
  mediaCenter = '/media-center',
  news = '/media-center/news',
  newsDetails = '/media-center/news/:newsId',
  photoGallery = '/media-center/photo-gallery',
  videoGallery = '/media-center/video-gallery',
  videoDetails = '/media-center/video-gallery/:videoId',
  ticketDetails = '/ticket/:id',
  approveParticipating = '/approve-participating',
  competitionConditions = '/competition-conditions',
  evaluationCriteria = '/evaluation-criteria',
  winners = '/winners',
  innovation = '/innovation',
  winnersCompetition = '/winners-competition',
  results = '/results'
}
