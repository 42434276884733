import { MouseEvent, useMemo } from 'react';

import { Image } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useRegulationsQuery } from 'src/api/regulations/hooks';
import { useSocialMediaAccountsQuery } from 'src/api/social-media-accounts/hooks';
import KACST_LOGO from 'src/assets/images/KACST-logo.svg';
import RDIA_LOGO from 'src/assets/images/RDIA-logo.svg';
import FooterPartner4 from 'src/assets/png/footer-partner4.png';
import FooterPartner1 from 'src/assets/svg/footer-partner1.svg';
import FooterPartner3 from 'src/assets/svg/footer-partner3.svg';
import FooterPartner5 from 'src/assets/svg/footer-partner5.svg';
import footerLogo from 'src/assets/svg/logo-row-white.svg';
import Container from 'src/components/Container';
import { renderIconByType } from 'src/components/Footer/helpers';
import { Paths } from 'src/router/constants';

import { openLoginModal } from '../../helpers/modals';
import { useAuthStore } from '../../store/useAuthStore';
import styles from './styles.module.scss';

const Footer = () => {
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { data: regulations } = useRegulationsQuery();
  const { data: socialMediaAccounts } = useSocialMediaAccountsQuery();
  const { user } = useAuthStore();

  const isRtl = useMemo(() => language === 'ar', [language]);

  const handleContactUsClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (user) {
      navigate(Paths.contactUs);
    } else {
      openLoginModal({ navigateAfterSuccessPath: Paths.contactUs });
    }
  };

  return (
    <footer className={styles.footer}>
      <Container variant="xl">
        <div className={styles.footerWrapper}>
          <div className={styles.footerTop}>
            <div className={styles.footerTopContent}>
              <Link to={Paths.homepage} className={styles.mainLogo}>
                <img src={footerLogo} alt="FooterLogo" width={270} height={52} />
              </Link>

              <div className={styles.divider} />

              <div className={styles.topRightSide}>
                <Image
                  src={RDIA_LOGO}
                  width={200}
                  height={50}
                  fit="contain"
                  alt="partner"
                />
                <Image
                  src={FooterPartner1}
                  width={80}
                  height={50}
                  fit="contain"
                  alt="partner"
                />
                <Image
                  src={KACST_LOGO}
                  width={130}
                  height={50}
                  fit="contain"
                  alt="partner"
                />
                <Image
                  src={FooterPartner3}
                  width={220}
                  height={40}
                  fit="contain"
                  alt="partner"
                />
                <Image
                  src={FooterPartner4}
                  width={100}
                  height={55}
                  fit="contain"
                  alt="partner"
                />
                <Image
                  src={FooterPartner5}
                  width={150}
                  height={50}
                  fit="contain"
                  alt="partner"
                />
              </div>
            </div>
          </div>

          <div className={styles.footerNav}>
            <div className={styles.social}>
              {socialMediaAccounts?.map((account) => (
                <a
                  key={account.id}
                  target="_blank"
                  href={account.account_link}
                  rel="noreferrer"
                >
                  {renderIconByType(account.socialMedia_type.title)}
                </a>
              ))}
            </div>

            <div className={styles.links}>
              <a target="_blank" href={isRtl ? regulations?.file_ar_path : regulations?.file_en_path} rel="noreferrer">
                <p className={styles.footerLink}>{t('common.regulations')}</p>
              </a>
              <Link to={Paths.privacyPolicy}>
                <p className={styles.footerLink}>{t('common.privacyPolicy')}</p>
              </Link>
              <Link to={Paths.termsAndConditions}>
                <p className={styles.footerLink}>{t('common.termsAndConditions')}</p>
              </Link>
              <Link to={Paths.faq}>
                <p className={styles.footerLink}>{t('common.faq')}</p>
              </Link>
              <Link to={Paths.contactUs} onClick={handleContactUsClick}>
                <p className={styles.footerLink}>{t('common.contactUs')}</p>
              </Link>
            </div>

            <p className={styles.footerText}>
              {`${t('common.rightsReserved')} ${new Date().getFullYear()}`}
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
