import { useQuery } from '@tanstack/react-query';

import { getGeneralSecretariat } from './api';
import { GeneralSecretariatItem } from './types';

export const useGeneralSecretariatQuery = () => {
  return useQuery<GeneralSecretariatItem[]>({
    queryKey: ['general-secretariat'],
    queryFn: async () => getGeneralSecretariat().then((res) => res.data)
  });
};
