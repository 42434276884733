import { FC } from 'react';

import { Image,clsx} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import styles from './styles.module.scss';
import { TMessageProps } from './types';

const Message: FC<TMessageProps> = ({
  message,
  messageAuthor,
  photo,
  photoPosition,
  photoAlt
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <div className={clsx(styles.wrapper, photoPosition)}>
      <div className={styles.messagePhotoWrapper}>
        <div className={clsx(styles.messagePhotoBackground, photoPosition)} />
        <Image
          src={photo}
          className={clsx(styles.messagePhoto, photoPosition)}
          fit="cover"
          height={isMobile ? 300 : 400}
          width={isMobile ? 300 : 400}
          alt={photoAlt}
          withPlaceholder
        />
      </div>
      <div className={clsx(styles.messageWrapper, photoPosition)}>
        <span className={clsx(styles.quote, styles.startQuote)}>&ldquo;</span>
        <p className={styles.message}>
          {message}
          <span className={clsx(styles.quote, styles.endQuote)}>&rdquo;</span>
        </p>
        <p className={styles.messageAuthor}>{messageAuthor}</p>
      </div>
    </div>
  );
};

export default Message;
