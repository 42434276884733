import { TTicketForm, TTicketRequest, TUpdateTicketRequest } from 'src/api/tickets/types';

export const normalizeTicket = (data: TTicketForm): TTicketRequest => {
  const { ticketType_id, title, description, attachemt } = data;
  return {
    ticketType_id: ticketType_id ? +ticketType_id : undefined,
    title,
    description,
    attachemt
  };
};

export const normalizeUpdateTicket = (
  data: TTicketForm & { id: string | undefined }
): TUpdateTicketRequest => {
  const { id, ticketType_id, title, description, attachemt } = data;
  return {
    id: id || '',
    ticket_type_id: ticketType_id ? +ticketType_id : undefined,
    title,
    description,
    attachemt
  };
};
