import { FC } from 'react';

import { Box, SimpleGrid, Stack } from '@mantine/core';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { applicationDefaultValues } from 'src/components/CompetitionRegistrationForm/constants';
import Select from 'src/components/Select';
import { TCompetitionRegistrationForm } from 'src/modules/competition/types';

import ApplicationInfo from './ApplicationInfo';

type Props = {
  form: UseFormReturn<TCompetitionRegistrationForm>;
  isDisabled: boolean;
};

const ApplicationForm: FC<Props> = ({ form, isDisabled }) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'application_data'
  });

  const handleNumberChange = (amount: string | null) => {
    if (!amount) {
      return;
    }
    const oldVal = fields.length;
    if (+amount > oldVal) {
      for (let i = oldVal; i < +amount; i++) {
        append(applicationDefaultValues);
      }
    } else {
      for (let i = oldVal; i > +amount; i--) {
        remove(i - 1);
      }
    }
  };

  return (
    <Box mb={40}>
      <SimpleGrid cols={2} spacing={120} mb={40}>
        <Controller
          control={form.control}
          name="number_of_applications"
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <Select
              value={value}
              onChange={(value) => {
                onChange(value);
                handleNumberChange(value);
              }}
              onBlur={onBlur}
              error={error?.message}
              data={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
              label={t('formLabels.applicationsNumber')}
              placeholder={t('placeholders.applicationsNumber')}
              required
              disabled={isDisabled}
            />
          )}
        />
      </SimpleGrid>

      {fields.length ? (
        <Stack spacing={15}>
          {fields.map((field, index) => (
            <ApplicationInfo
              key={field.id}
              applicationIndex={index}
              form={form}
              isDisabled={isDisabled}
            />
          ))}
        </Stack>
      ) : null}
    </Box>
  );
};

export default ApplicationForm;
