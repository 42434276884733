import { FC } from 'react';

import { PasswordInput as MantinePasswordInput, TextInputProps } from '@mantine/core';

import styles from './PasswordInput.module.scss';

const PasswordInput: FC<TextInputProps> = (props) => (
  <MantinePasswordInput
    classNames={{
      input: styles.input,
      innerInput: styles.innerInput,
      invalid: styles.invalid
    }}
    {...props}
  />
);

export default PasswordInput;
