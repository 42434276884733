import { Box, Flex } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { generatePath } from 'react-router-dom';

import { TTicket } from 'src/api/tickets/types';
import Badge, { BadgeProps } from 'src/components/Badge';
import Button from 'src/components/Button';
import Tooltip from 'src/components/Tooltip';
import i18n from 'src/configs/i18n';
import { openCancelTicketModal, openRateTicketModal } from 'src/helpers/modals';
import { TicketStatus } from 'src/modules/tickets/constants';
import { Paths } from 'src/router/constants';
import { router } from 'src/router/router';

import styles from './styles.module.scss';

const withComment = (ticket: TTicket, badgeColor: BadgeProps['color']) => {
  const { ticket_status, admin_status_comment } = ticket;
  const hasStatusComment = admin_status_comment && ticket_status.id !== TicketStatus.New;

  return (
    <Flex align="center" justify="start" gap={10}>
      {hasStatusComment ? (
        <Tooltip label={admin_status_comment}>
          <Box h={20}>
            <IconInfoCircle size={20} color="#26495a" />
          </Box>
        </Tooltip>
      ) : (
        <Box w={20}></Box>
      )}

      <Badge color={badgeColor}>{ticket_status.name}</Badge>
    </Flex>
  );
};

export const getTicketStatusBadge = (ticket: TTicket) => {
  const { id } = ticket.ticket_status;

  switch (id) {
    case TicketStatus.New: {
      return withComment(ticket, 'green');
    }

    case TicketStatus.UnderReview: {
      return withComment(ticket, 'pear');
    }

    case TicketStatus.MissingInfo: {
      return withComment(ticket, 'red');
    }

    case TicketStatus.Closed: {
      return withComment(ticket, 'moss');
    }

    default: {
      return null;
    }
  }
};

export const getTicketOption = (ticket: TTicket) => {
  const { id } = ticket.ticket_status;

  switch (id) {
    case 1: {
      return (
        <Button className={styles.cancelBtn} onClick={() => openCancelTicketModal(ticket.id)}>
          {i18n.t('common.cancel') as string}
        </Button>
      );
    }

    case 3: {
      return (
        <Button
          className={styles.updateBtn}
          onClick={() => {
            if (ticket.ticket_status.id === 3) {
              router.navigate(generatePath(Paths.ticketDetails, { id: ticket.id }));
            }
          }}
        >
          {i18n.t('common.update') as string}
        </Button>
      );
    }

    case 4: {
      if (ticket.is_rated) {
        return null;
      }

      return (
        <Button className={styles.updateBtn} onClick={() => openRateTicketModal(ticket)}>
          {i18n.t('common.rate') as string}
        </Button>
      );
    }

    default:
      return null;
  }
};
