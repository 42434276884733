import { FC } from 'react';

import { Outlet } from 'react-router-dom';

import NavTabs from './NavTabs';
import styles from './styles.module.scss';

const MediaCenter: FC = () => (
  <div className={styles.wrapper}>
    <NavTabs />
    <div className={styles.contentWrapper}>
      <Outlet />
    </div>
  </div>
);

export default MediaCenter;
