import { FC } from 'react';

import { NumberInputProps, Stack, Text, Input, Flex } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import NumberInput from 'src/components/NumberInput';

import styles from './PercentageInput.module.scss';

type Props = {
  label: string;
  withLabel?: boolean;
} & Pick<NumberInputProps, 'value' | 'onChange' | 'disabled' | 'min' | 'max' | 'error'>;

const PercentageInput: FC<Props> = ({
  label,
  withLabel = true,
  min = 1,
  max = 99,
  error,
  onChange,
  ...restProps
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <Stack spacing={5}>
      <Flex direction={isMobile ? 'column' : 'row'} align={isMobile ? 'end' : 'center'} gap={15} justify='flex-end'>
        {!!withLabel ? <Text align='end' fz={12}>{label}</Text> : null}
        <NumberInput
          hideControls
          rightSection={<Text fz={12}>%</Text>}
          min={min}
          max={max}
          precision={2}
          removeTrailingZeros
          step={0.05}
          classNames={{
            root: styles.root,
            input: styles.input
          }}
          onChange={(value) => {
            if (onChange) {
              onChange(value || 0);
            }
          }}
          onBlur={(event) => {
            const value = +event.target.value;
            if (onChange) {
              if (value < min) {
                onChange(min);
              }

              if (value > max) {
                onChange(max);
              }
            }
          }}
          {...restProps}
        />
      </Flex>
      {error && (
        <Input.Error
          styles={{ error: { textAlign: 'end' } }}
        >
          {error}
        </Input.Error>
      )}
    </Stack>
  );
};

export default PercentageInput;
