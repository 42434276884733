export const emailRegExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export const passwordRegExp = /^(?!.* )(?=.*[0-9])(?=.*[A-Z])(?=.*?[a-z])(?=.*?[#./?!@$%^&*-]).{8,}$/;

export const nameRegExp = /^[a-zA-Z\u0600-\u06FF]* *$/;

export const familyNameRegExp = /^([a-zA-Z\u0600-\u06FF]+\s)?[a-zA-Z\u0600-\u06FF]+ *$/;

export const idRegExp = /^[0-9]{10}$/;

export const codeRegExp = /^[0-9]{4}$/;

export const phoneNumberRegExp = /^9665[0-9]{8}$/;
