import { useQuery } from '@tanstack/react-query';

import { getParticipatingRoles } from './api';
import { ParticipatingRoles } from './types';

export const useParticipatingRolesQuery = () => {
  return useQuery<ParticipatingRoles[]>({
    queryKey: ['participating-roles'],
    queryFn: async () => getParticipatingRoles().then((res) => res.data)
  });
};
