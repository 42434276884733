import { FC } from 'react';

import { Box, Input, SimpleGrid, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/Button';
import DatePicker from 'src/components/DatePicker';
import Form from 'src/components/Form';
import PasswordInput from 'src/components/PasswordInput';
import Select from 'src/components/Select';
import TextInput from 'src/components/TextInput';
import {
  emailRegExp,
  familyNameRegExp,
  idRegExp,
  nameRegExp,
  passwordRegExp,
  phoneNumberRegExp
} from 'src/constants/regExp';
import { USER_MAX_AGE, USER_MIN_AGE } from 'src/constants/shared';
import FormError from 'src/pages/Signup/FormError';
import { Paths } from 'src/router/constants';

import styles from './styles.module.scss';
import { NormalizedItem } from 'src/hooks/useNormalizedItemsData';
import { UserRegisterData } from 'src/api/user/types';

type TProps = {
  form: UseFormReturn<UserRegisterData, any>;
  isLoading: boolean;
  nationalitiesData: NormalizedItem[];
  gendersData: NormalizedItem[];
  handleSubmit: (navigateTo: Paths) => void;
};

const SignupForm: FC<TProps> = ({
  form,
  isLoading,
  nationalitiesData,
  gendersData,
  handleSubmit
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Form>
      <FormError control={form.control} />
      <div>
        <Box maw={isMobile ? 'auto' : 250}  mb={isMobile ? 20 :40}>
          <Controller
            control={form.control}
            name="user_identity"
            rules={{
              required: t('errorMessages.required'),
              pattern: {
                value: idRegExp,
                message: t('errorMessages.idOrIqamaNumber')
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.idOrIqamaNumber')}
                required
                placeholder={t('placeholders.idOrIqamaNumber')}
                disabled={isLoading}
              />
            )}
          />
        </Box>

        <Stack spacing={isMobile ? 0 : 5}>
          <Input.Label required>
            {t('formLabels.fullName')}
          </Input.Label>

          <SimpleGrid cols={isMobile ? 1 : 3} spacing={isMobile ? 10 : 28} mb={{ base: 10, lg: 40 }}>
            <Controller
              control={form.control}
              name="first_name"
              rules={{
                required: t('errorMessages.required'),
                pattern: {
                  value: nameRegExp,
                  message: t('errorMessages.onlyName')
                }
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  placeholder={t('placeholders.firstName')}
                  required
                  disabled={isLoading}
                />
              )}
            />

            <Controller
              control={form.control}
              name="second_name"
              rules={{
                required: t('errorMessages.required'),
                pattern: {
                  value: nameRegExp,
                  message: t('errorMessages.onlyName')
                }
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  placeholder={t('placeholders.middleName')}
                  required
                  disabled={isLoading}
                />
              )}
            />

            <Controller
              control={form.control}
              name="last_name"
              rules={{
                required: t('errorMessages.required'),
                pattern: {
                  value: familyNameRegExp,
                  message: t('errorMessages.onlyName')
                }
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error }
              }) => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  placeholder={t('placeholders.lastName')}
                  required
                  disabled={isLoading}
                />
              )}
            />
          </SimpleGrid>
        </Stack>

        <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 10 : 120}  mb={isMobile ? 20 :40}>
          <Controller
            control={form.control}
            name="mobile_number"
            rules={{
              required: t('errorMessages.required'),
              pattern: {
                value: phoneNumberRegExp,
                message: t('errorMessages.invalidPhoneNumber')
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.phoneNumber')}
                placeholder={t('placeholders.phoneNumber')}
                type="tel"
                required
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={form.control}
            name="nationality_id"
            rules={{
              required: t('errorMessages.required')
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <Select
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                data={nationalitiesData}
                label={t('formLabels.nationality')}
                placeholder={t('placeholders.nationality')}
                required
                searchable
                clearable
                disabled={isLoading}
              />
            )}
          />
        </SimpleGrid>

        <SimpleGrid cols={2} spacing={isMobile ? 10 : 120} mb={isMobile ? 20 :40}>
          <Controller
            control={form.control}
            name="email"
            rules={{
              required: t('errorMessages.required'),
              pattern: {
                value: emailRegExp,
                message: t('errorMessages.invalidEmail')
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
              formState: { isSubmitted }
            }) => (
              <TextInput
                value={value}
                onChange={(value) => {
                  onChange(value);

                  if (isSubmitted && form.getValues('confirm_email')) {
                    form.trigger('confirm_email');
                  }
                }}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.email')}
                placeholder={t('placeholders.email')}
                required
                type="email"
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={form.control}
            name="confirm_email"
            rules={{
              required: t('errorMessages.required'),
              validate: (value) => {
                if (form.getValues('email') !== value) {
                  return t('errorMessages.emailsNotMatch');
                }

                return true;
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.confirmEmail')}
                placeholder={t('placeholders.email')}
                required
                type="email"
                disabled={isLoading}
              />
            )}
          />
        </SimpleGrid>

        <SimpleGrid cols={2} spacing={isMobile ? 10 : 120} mb={isMobile ? 20 :40}>
          <Controller
            control={form.control}
            name="password"
            rules={{
              required: t('errorMessages.required'),
              pattern: {
                value: passwordRegExp,
                message: t('errorMessages.invalidPassword')
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
              formState: { isSubmitted }
            }) => (
              <PasswordInput
                value={value}
                onChange={(value) => {
                  onChange(value);

                  if (isSubmitted && form.getValues('password_confirmation')) {
                    form.trigger('password_confirmation');
                  }
                }}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.password')}
                placeholder={t('placeholders.password')}
                required
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={form.control}
            name="password_confirmation"
            rules={{
              required: t('errorMessages.required'),
              validate: (value) => {
                if (form.getValues('password') !== value) {
                  return t('errorMessages.passwordsNotMatch');
                }

                return true;
              }
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <PasswordInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.confirmPassword')}
                placeholder={t('placeholders.password')}
                required
                disabled={isLoading}
              />
            )}
          />
        </SimpleGrid>

        <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 20 : 120}  mb={isMobile ? 20 :50} >
          <Controller
            control={form.control}
            name="gender_id"
            rules={{
              required: t('errorMessages.required')
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <Select
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                data={gendersData}
                label={t('formLabels.gender')}
                placeholder={t('placeholders.gender')}
                required
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={form.control}
            name="birthday"
            rules={{
              required: t('errorMessages.required')
            }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <DatePicker
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                label={t('formLabels.birthDate')}
                placeholder={t('placeholders.birthDate')}
                required
                minDate={USER_MAX_AGE}
                maxDate={USER_MIN_AGE}
                initialLevel="year"
                initialMonth={USER_MIN_AGE}
                disabled={isLoading}
              />
            )}
          />
        </SimpleGrid>
      </div>
      <div className={styles.formActions}>
        <Button
          className={styles.submitButton}
          type="submit"
          onClick={() => handleSubmit(Paths.competitionRegistration)}
          disabled={isLoading}
        >
          {t('pages.signup.buttons.startRegisteringForTheCompetition')}
        </Button>

        <div className={styles.dividerWrapper}>
          <div className={styles.divider} />
          <span className={styles.dividerText}>
            {t('common.or')}
          </span>
          <div className={styles.divider} />
        </div>

        <Button
          className={styles.submitButton}
          type="submit"
          variant="secondary"
          onClick={() => handleSubmit(Paths.homepage)}
          disabled={isLoading}
        >
          {t('pages.signup.buttons.registerAndReturnHome')}
        </Button>
      </div>
    </Form>
  );
};

export default SignupForm;
