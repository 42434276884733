import { FC, useMemo } from 'react';

import { Center } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUserParticipatingRequestsQuery } from 'src/api/competition/hooks';
import bannerBg from 'src/assets/jpg/banner-image.jpg';
import bannerOrnament from 'src/assets/svg/banner-ornament.svg';
import bannerLogo from 'src/assets/svg/logo-column-white.svg';
import Button from 'src/components/Button';
import Container from 'src/components/Container';
import { useCurrentCompetitionUserInfo } from 'src/helpers/competition';
import { isDateBeforeCurrent } from 'src/helpers/dates';
import { openLoginModal } from 'src/helpers/modals';
import { TCompetition } from 'src/modules/competition/types';
import { Paths } from 'src/router/constants';
import { useAuthStore } from 'src/store/useAuthStore';

import styles from './styles.module.scss';
import Timer from './Timer';

type Props = {
  competition: TCompetition | null | undefined;
};

const TimerBanner: FC<Props> = ({ competition }) => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const navigate = useNavigate();

  const { data: requests } = useUserParticipatingRequestsQuery();

  const userCurrentCompetitionRequestInfo = useCurrentCompetitionUserInfo(competition, requests);

  const handleButtonClick = () => {
    if (user) {
      navigate(Paths.competitionRegistration);
    } else {
      openLoginModal({ navigateAfterSuccessPath: Paths.competitionRegistration });
    }
  };

  const isCompetitionActive = useMemo(() => {
    if (!competition?.end_date) {
      return false;
    }

    return isDateBeforeCurrent(competition?.end_date);
  }, [competition?.end_date]);

  const isUserRegisteredToCompetition = useMemo(() => {
    if (!user) {
      return false;
    }

    return userCurrentCompetitionRequestInfo !== null;
  }, [user, userCurrentCompetitionRequestInfo]);

  return (
    <div className={styles.wrapper}>
      <img src={bannerBg} alt="" className={styles.image} />
      <img src={bannerOrnament} alt="" className={styles.ornament} />
      <div className={styles.overlay} />
      <Container fullHeight>
        {competition ? (
          <div className={styles.contentWrapper}>
            <img src={bannerLogo} alt="logo" width={332} height={194} className={styles.bannerLogo} />
            <div className={styles.divider} />
            <div className={styles.timerWrapper}>
              <div className={styles.title}>
                <span>{competition.title}</span>
                <span>{competition.description}</span>
              </div>
              {isCompetitionActive && (
                <>
                  <Timer endTime={competition.end_date} />

                  {!isUserRegisteredToCompetition && !user?.entity && (
                    <Button className={styles.button} onClick={handleButtonClick}>
                      {t('common.registerNow')}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <Center pos="relative" h="100%">
            <img src={bannerLogo} alt="logo" width={332} height={194} />
          </Center>
        )}
      </Container>
    </div>
  );
};

export default TimerBanner;
