import { useState } from 'react';
import { Center, Loader } from '@mantine/core';

import { useWinnerListByYearQuery } from 'src/api/arbitrating/hooks';
import WinnersData from 'src/components/WinnersData/WinnersData';

const CompetitionOfTheYear = () => {
  const [page, setPage] = useState<number>(1);
  const year = localStorage.getItem('winnerCompetitionYear');

  const { data: winnerByYear, isLoading } = useWinnerListByYearQuery(year || '0');

  const winnersByPage = winnerByYear?.winners.slice((page - 1) * 5, page * 5);
  
  return (
    <>
      {isLoading && !year ? (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      ) : (
        <WinnersData
          data={winnerByYear}
          winnersByPage={winnersByPage}
          page={page}
          setPage={setPage}
          selectedYear={year || '0'}
        />
      )}
    </>
  );
};

export default CompetitionOfTheYear;
