import { Flex } from '@mantine/core';

import bannerOrnament from 'src/assets/svg/banner-ornament.svg';
import additionalLogo from 'src/assets/svg/logo-white.svg';

import styles from './styles.module.scss';
import { useWinnerListByYearQuery } from 'src/api/arbitrating/hooks';

const WinnersBanner = () => {
  const selectedYear = localStorage.getItem('winnerCompetitionYear');
  const { data: winnerByYear, isLoading } = useWinnerListByYearQuery(selectedYear || '0');

  const arabicMonths = [
    'يناير', 'فبراير', 'مارس', 'أبريل',
    'مايو', 'يونيو', 'يوليو', 'أغسطس',
    'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'
  ];

  const startDate = new Date(winnerByYear?.competitionStartDate || 0);
  const endDate = new Date(winnerByYear?.competitionEndDate || 0);

  const SMonthIndex = startDate.getMonth();
  const EMonthIndex = endDate.getMonth();

  
  const SMonth = arabicMonths[SMonthIndex];
  const EMonth = arabicMonths[EMonthIndex];

  const SYear = startDate.getFullYear();
  const EYear = endDate.getFullYear();

  return (
    <div className={styles.wrapper}>
      <div className={styles.overlay} />
      <Flex className={styles.contentWrapper} align='center' justify='center' gap={80}>
        <Flex w={460} h={430} justify='center' align='center' bg='#D9D9D9' className={styles.imageWrapper}>
          <img src={winnerByYear?.competitionImagePath || additionalLogo} alt="logo" className={winnerByYear?.competitionImagePath ? styles.MainImage : styles.additionalLogo} width={300} height={150} />
        </Flex>

        <Flex className={styles.mainText} gap={12} direction='column' align='center'>
          <h1>مسابقة الإبتكار لعام <span>{selectedYear}</span></h1>
          <h2>تاريخ المسابقة</h2>
          <h3 dir='rtl'>{SMonth} {SYear} <p>-</p> {EMonth} {EYear}</h3>
        </Flex>
      </Flex>

      <img src={bannerOrnament} alt="" className={styles.ornament} />
    </div>
  );
};

export default WinnersBanner;
