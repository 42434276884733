import { FC, FormEvent } from 'react';

import { TFormProps } from './types';

const Form: FC<TFormProps> = ({
  children,
  className,
  onSubmit
}) => {
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <form className={className} onSubmit={handleSubmit} noValidate>
      {children}
    </form>
  );
};

export default Form;
