import { User } from 'src/api/user/types';

export const normalizeUserForForm = (user: User) => ({
  first_name: user.first_name,
  second_name: user.second_name,
  last_name: user.last_name,
  nationality_id: user.nationality?.id.toString(),
  mobile_number: user.mobile,
  gender_id: user.gender?.id.toString(),
  email: user.email,
  confirm_email: user.email,
  birthday: new Date(user.birthday || 0),
  user_identity: user.user_identity,
  interested_competition_path: user.preferedPath?.map(path => path.id.toString())
});

export const normalizeInterestedUserForForm = (user: User) => ({
  first_name: user.first_name,
  second_name: user.second_name,
  last_name: user.last_name,
  mobile_number: user.mobile_number,
  email: user.email,
  confirmed_email: user.email,
  interests: user.tags,
  entity: user.entity_id,
  company_name: user.company_name
});

export const getUserFullName = (user: User | null) => {
  if (!user) {
    return '';
  }

  return `${user.first_name} ${user.second_name} ${user.last_name}`;
};
