import { FC, useCallback, useEffect, useMemo } from 'react';

import { UseFormReturn } from 'react-hook-form';

import {
  useCreateParticipatingRequestMutation,
  useSubmitParticipatingRequestMutation,
  useUpdateParticipatingRequestMutation
} from 'src/api/competition/hooks';
import CompetitionRegistrationForm from 'src/components/CompetitionRegistrationForm';
import { handleApiErrorResponse, normalizeCompetitionRegistrationRequest } from 'src/helpers/competition';
import { isDateAfterCurrent } from 'src/helpers/dates';
import { RequestStatus } from 'src/modules/competition/constants';
import { TCompetition, TCompetitionRegistrationForm, TUserCompetitionInfo } from 'src/modules/competition/types';

type Props = {
  competition: TCompetition;
  userRequestInfo: TUserCompetitionInfo | null | undefined;
  form: UseFormReturn<TCompetitionRegistrationForm>;
  isInfoLoading: boolean;
};

const InitialForm: FC<Props> = ({
  competition,
  userRequestInfo,
  form,
  isInfoLoading
}) => {
  const {
    mutate: createRequest,
    isLoading: isCreateRequestLoading,
    isError: isCreateError
  } = useCreateParticipatingRequestMutation({
    onError: (error) => handleApiErrorResponse(error, form)
  });

  useEffect(() => {
    if (userRequestInfo === null) {
      const values = form.getValues();
      const normalizedData = normalizeCompetitionRegistrationRequest({
        data: values,
        competitionId: competition.id || ''
      });

      createRequest(normalizedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competition, userRequestInfo]);


  const { mutate: updateRequest, isLoading: isUpdateRequestLoading } = useUpdateParticipatingRequestMutation({
    onError: (error) => handleApiErrorResponse(error, form)
  });

  const { mutate: submitRequest, isLoading: isSubmitRequestLoading } = useSubmitParticipatingRequestMutation({
    onError: (error) => handleApiErrorResponse(error, form)
  });

  const handleSaveForm = useCallback(async () => {
    if (userRequestInfo) {
      const values = form.getValues();
      const normalizedData = normalizeCompetitionRegistrationRequest({
        data: values,
        competitionId: competition.id || ''
      });

      updateRequest({ ...normalizedData, request_id: userRequestInfo.id });
    }
  }, [form, competition.id, userRequestInfo, updateRequest]);

  const handleSubmit = useCallback(() => {
    form.handleSubmit(
      async () => {
        if (userRequestInfo) {
          const values = form.getValues();

          const normalizedData = normalizeCompetitionRegistrationRequest({
            data: values,
            competitionId: competition.id || ''
          });

          submitRequest({ ...normalizedData, request_id: userRequestInfo.id });
        }
      },
      () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })();
  }, [form, competition.id, userRequestInfo, submitRequest]);

  const isDisabled = useMemo(() => {
    if (isDateAfterCurrent(competition.end_date) || isCreateError) {
      return true;
    }

    if (userRequestInfo && userRequestInfo.status?.id === RequestStatus.Submitted) {
      return true;
    }

    return false;
  }, [competition, isCreateError, userRequestInfo]);

  const isLoading = isInfoLoading || isCreateRequestLoading || isSubmitRequestLoading || isUpdateRequestLoading;

  return (
    <CompetitionRegistrationForm
      form={form}
      competition={competition}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onSaveForm={handleSaveForm}
      onSubmit={handleSubmit}
    />
  );
};

export default InitialForm;
