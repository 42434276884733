import { FC } from 'react';

import { Flex, Stack, clsx } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'src/components/Button';
import Checkbox from 'src/components/Checkbox';
import { StepProps } from 'src/components/CompetitionRegistrationForm/types';
import { Paths } from 'src/router/constants';

import ApplicationForm from './ApplicationForm';
import styles from './styles.module.scss';

const Step4: FC<StepProps> = ({
  goToPrevious,
  form,
  isLoading,
  onSubmit,
  isDisabled,
  submitText
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <>
      <ApplicationForm form={form} isDisabled={isDisabled} />

      <Stack mb={40} ml={isMobile ? 20 : 0}>
        <Controller
          control={form.control}
          name="is_agreed_work_originality"
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <Checkbox
              checked={value}
              onChange={onChange}
              isError={!!error}
              label={t('formLabels.agreedWorkOriginality')}
              disabled={isLoading || isDisabled}
              required
            />
          )}
        />

        <Controller
          control={form.control}
          name="is_agreed_for_sharing_his_data"
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <Checkbox
              checked={value}
              onChange={onChange}
              isError={!!error}
              label={t('formLabels.shareAgree')}
              disabled={isLoading || isDisabled}
              required
            />
          )}
        />

        <Controller
          control={form.control}
          name="is_agreed_for_terms_and_conditions"
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <Checkbox
              checked={value}
              onChange={onChange}
              isError={!!error}
              label={(
                <>
                  {`${t('formLabels.agreeToThe')} `}
                  <Link className={styles.link} to={Paths.termsAndConditions} target="_blank">
                    {t('formLabels.termsAndConditions')}
                  </Link>
                </>
              )}
              disabled={isLoading || isDisabled}
              required
            />
          )}
        />

        <Controller
          control={form.control}
          name="is_agreed_for_regukations_and_rules"
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <Checkbox
              checked={value}
              onChange={onChange}
              isError={!!error}
              label={(
                <>
                  {`${t('formLabels.agreeToThe')} `}
                  <Link className={styles.link} to={Paths.privacyPolicy} target="_blank">
                    {t('formLabels.rulesAndRegulations')}
                  </Link>
                </>
              )}
              disabled={isLoading || isDisabled}
              required
            />
          )}
        />
      </Stack>

      <Flex justify={isMobile ? 'flex-start' : 'space-between'} h={40} pos="relative">
        <Button
          onClick={goToPrevious}
          variant="secondary"
        >
          {t('common.previous')}
        </Button>

        {isDisabled ? null : (
          <Button
            className={clsx(styles.submitButton, { [styles.isMobile]: isMobile })}
            onClick={onSubmit}
          >
            {submitText || t('common.register')}
          </Button>
        )}
      </Flex>
    </>
  );
};

export default Step4;
