import { FC, useMemo, useState } from 'react';

import { Center, Loader } from '@mantine/core';

import { usePhotoGalleryQuery } from 'src/api/photo-gallery/helpers';
import Container from 'src/components/Container';
import Pagination from 'src/components/Pagination';
import PhotoAlbumDetails from 'src/pages/PhotoGallery/PhotoAlbumDetails';

import PhotoCard from './PhotoCard';
import styles from './styles.module.scss';

const PhotoGallery: FC = () => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [openIndex, setOpenIndex] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  const { data, isLoading } = usePhotoGalleryQuery();

  const handleCardClick = (id: string) => {
    if (data) {
      const itemIndex = data.data.findIndex((item) => item.id === id);

      setOpenIndex(itemIndex);
      setIsDetailsModalOpen(true);
    }
  };

  const pageCards = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.data.slice((page - 1) * 9, page * 9);
  }, [page, data]);

  return (
    <Container variant="lg">
      {isLoading && (
        <Center py={50}>
          <Loader size="lg" />
        </Center>
      )}
      {data && (
        <>
          <div className={styles.cardsWrapper}>
            {pageCards.map((item) => (
              <PhotoCard
                key={item.id}
                item={item}
                onClick={handleCardClick}
              />
            ))}
          </div>
          <Pagination
            currentPage={page}
            totalPages={Math.ceil(data.data.length / 9)}
            onChange={setPage}
          />

          <PhotoAlbumDetails
            isOpen={isDetailsModalOpen}
            onClose={() => setIsDetailsModalOpen(false)}
            data={data}
            openIndex={openIndex}
          />
        </>
      )}
    </Container>
  );
};

export default PhotoGallery;
