import { FC } from 'react';

import { clsx } from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import { TStepperProps } from './types';

const Stepper: FC<TStepperProps> = ({ items, activeStep }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navigateToStep = (step: number) => {
    if (step === activeStep) {
      return;
    }

    navigate(pathname, { state: { activeStep: step } });
  };

  return (
    <div className={styles.stepperWrapper}>
      {items.map((item, index) => {
        const currentStep = index + 1;

        return (
          <div
            key={currentStep}
            className={clsx(styles.step, {
              [styles.error]: item.isError,
              [styles.active]: activeStep >= currentStep
            })}
          >
            <div
              className={styles.stepIconContainer}
              onClick={() => navigateToStep(currentStep)}
            >
              <span className={styles.stepIcon}>{currentStep}</span>
            </div>
            <div className={styles.stepLabel}>{item.label}</div>
            {currentStep !== 1 && (
              <span className={styles.stepConnector} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
