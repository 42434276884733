import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { Button } from '@mantine/core';
import { Paths } from 'src/router/constants';
import { useTranslation } from 'react-i18next';

import kingAndPrinceMessagesBg from 'src/assets/svg/king-and-prince-bg.svg';
import WinnerItem from './WinnerItem/WinnerItem';
import Container from '../Container';
import Title from '../Title';
import { useTopWinnerListQuery } from 'src/api/arbitrating/hooks';

const WinnerBlock = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useTopWinnerListQuery();

  return (
    <div className={styles.wrapper}>
      {!isLoading && (
        <>
          <img src={kingAndPrinceMessagesBg} alt="bgImage" className={styles.bgImg} />

          <Container>
            <Title color='dark'>{t('common.winners')}</Title>

            <div className={styles.itemContainer}>
              {data?.map((itemData, id: number) => (
                <WinnerItem data={itemData} id={id} key={id} />
              ))}
            </div>

            <Link to={Paths.winners} className={styles.link}>
              <Button className={styles.button}>{t('common.more')}</Button>
            </Link>
          </Container>
        </>
      )}
    </div>
  );
};

export default WinnerBlock;

const staticData = [
  {
    image: '',
    innovation_name: 'test',
    innovation_path: 'Signals and radio communications',
    fullName: 'Bushra Bushra Bushra',
    participatiing_type: 'Individual'
  },
  {
    image: '',
    innovation_name: 'test',
    innovation_path: 'Signals and radio communications',
    fullName: 'Bushra Bushra Bushra',
    participatiing_type: 'Individual'
  },
  {
    image: '',
    innovation_name: 'test',
    innovation_path: 'Signals and radio communications',
    fullName: 'Bushra Bushra Bushra',
    participatiing_type: 'Individual'
  },
];
