import { useTranslation } from "react-i18next";
import Container from "../Container";
import Title from "../Title";
import styles from './styles.module.scss';
import { FullWinnersInfo } from "src/api/arbitrating/types";

type TProps = {
  data: FullWinnersInfo;
};

const ViewWinnerHeaderBlock = ({ data }:TProps) => {
  const { t } = useTranslation();
  
  const tableData = [
    {id: 1, color: '#1e93cd', content: data?.numberOfParticipants, title: t('pages.winner.participantsNumber')},
    {id: 2, color: '#0f4439', content: data?.numberOfinnovations, title: t('pages.winner.innovationsNumber')},
    {id: 3, color: '#6fa75b', content: `${data?.femalePercentage}%`, title: t('pages.winner.femalesPercentage')},
    {id: 4, color: '#208b53', content: `${data?.malePercentage}%`, title: t('pages.winner.malesPercentage')},
  ];

  return (
    <div className={styles.wrapper}>
      <Container>
        <Title color='dark'>{t('pages.winner.stats')}</Title>
        <div className={styles.content}>
          {tableData.map((item) => (
            <div key={item.id} className={styles.item}>
              <div className={styles.triangle} style={{borderLeft: `30px solid ${item.color}`}}></div>
              <h3 style={{color: item.color}}>{item.content}</h3>
              <p style={{color: item.color}}>{item.title}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
};

export default ViewWinnerHeaderBlock;
