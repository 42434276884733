import { FC } from 'react';

import { usePagination } from '@mantine/hooks';
import { IconDots } from '@tabler/icons-react';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { TPaginationProps } from './types';

const Pagination: FC<TPaginationProps> = ({
  currentPage,
  totalPages,
  onChange
}) => {
  const { t } = useTranslation();
  const { range, previous, next, setPage } = usePagination({
    total: totalPages,
    page: currentPage,
    onChange
  });

  return (
    <div className={styles.paginationWrapper}>
      <div className={styles.pagination}>
        <button
          className={styles.navButton}
          onClick={previous}
          disabled={currentPage === 1}
        >
          {t('common.previous')}
        </button>
        {range.map((item, idx) => {
          const key = `${item}_${idx}`;

          if (item === 'dots') {
            return (
              <div
                key={key}
                className={styles.ellipsis}
              >
                <IconDots />
              </div>
            );
          } else {
            return (
              <button
                key={key}
                className={clsx(styles.pageButton, {
                  [styles.active]: item === currentPage
                })}
                onClick={() => setPage(item)}
              >
                {item}
              </button>
            );
          }
        })}
        <button
          className={styles.navButton}
          onClick={next}
          disabled={currentPage === totalPages}
        >
          {t('common.next')}
        </button>
      </div>
    </div>
  );
};

export default Pagination;
