import { FC } from 'react';


import { CompetitionPath } from 'src/api/competition-paths/types';
import cardOrnament from 'src/assets/svg/competition-card-ornament.svg';
import { ReactComponent as Sight } from 'src/assets/svg/sight.svg';
import HTMLParser from 'src/components/HTMLParser';

import styles from './styles.module.scss';

type Props = {
  item: CompetitionPath;
};

const CompetitionCard: FC<Props> = ({ item }) => (
  <div className={styles.competitionCard}>
    {item.image_path ? (
      <img src={item.image_path} alt="" className={styles.cardBgImg} />
    ) : null}
    <div className={styles.cardOverlay} />
    <img src={cardOrnament} alt="" className={styles.ornamentTop} />
    <img src={cardOrnament} alt="" className={styles.ornamentBottom} />
    <div className={styles.titleBlock}>
      <Sight className={styles.sight} />
      <h3 className={styles.title}>{item.title}</h3>
      <div className={styles.divider} />
    </div>
    <div className={styles.content}>
      <HTMLParser content={item.body} />
    </div>
  </div>
);

export default CompetitionCard;
