import privateApi from 'src/configs/private-api';
import publicApi from 'src/configs/public-api';
import { SuccessResponse } from 'src/types/api';

import {
  User,
  UserEditData,
  UserLoginData,
  UserLoginResponse,
  UserRegisterData,
  UserInterestedRegisterData,
  TForgetPasswordRequest,
  TForgetPasswordResponse,
  TResetPasswordRequest,
  TResetPasswordResponse,
  UpdateInterestedUserData
} from './types';

export const userRegister = async (data: UserRegisterData): Promise<SuccessResponse> => {
  return publicApi.post('/users', data).then((res) => res.data);
};

export const userInterestedRegister = async (data: UserInterestedRegisterData): Promise<SuccessResponse> => {
  return publicApi.post('/interested-user', data).then((res) => res.data);
};

export const updateInterestedUser = async (data: UpdateInterestedUserData): Promise<SuccessResponse> => {
  return privateApi.put('/interested-user', data).then((res) => res.data);
};

export const userLogin = async (data: UserLoginData): Promise<UserLoginResponse> => {
  return publicApi.post('/users/login', data).then((res) => res.data);
};

export const getUserData = async (): Promise<User> => {
  return privateApi.get('/users').then((res) => res.data);
};

export const userLogout = async (): Promise<SuccessResponse> => {
  return privateApi.post('/users/logout').then((res) => res.data);
};

export const updateUser = async (data: UserEditData): Promise<SuccessResponse> => {
  return privateApi.put('/users', data).then((res) => res.data);
};

export const sendInterestedUserVerify = async (email: string, token: string): Promise<SuccessResponse> => {
  return publicApi.post('/interested-user/verify', {
    email,
    token
  }).then((res) => res.data);
};

export const getInterestedUserData = async (): Promise<User> => {
  return privateApi.get('/interested-user').then((res) => res.data);
};

export const forgetPassword = async (data: TForgetPasswordRequest): Promise<TForgetPasswordResponse> => {
  return publicApi.post('/users/password/forget', data).then((res) => res.data);
};

export const resetPassword = async (data: TResetPasswordRequest): Promise<TResetPasswordResponse> => {
  return publicApi.post('/users/password/reset', data).then((res) => res.data);
};
