import { FC } from 'react';

import styles from './styles.module.scss';

type TProps = {
  children: string;
  onCLick?: () => void;
};

const Link: FC<TProps> = ({ children, onCLick }) => (
  <button className={styles.link} onClick={onCLick}>
    {children}
  </button>
);

export default Link;
