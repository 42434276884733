import { FC } from 'react';

import bannerBg from 'src/assets/jpg/banner-image.jpg';
import bannerOrnament from 'src/assets/svg/banner-ornament.svg';
import bannerLogo from 'src/assets/svg/logo-column-white.svg';
import additionalLogo from 'src/assets/svg/logo-white.svg';

import styles from './styles.module.scss';

type TProps = {
  additionalTitle?: string;
};

const Banner: FC<TProps> = ({ additionalTitle }) => (
  <div className={styles.wrapper}>
    <img src={bannerBg} alt="" className={styles.image} />
    <img src={bannerOrnament} alt="" className={styles.ornament} />
    <div className={styles.overlay} />
    <div className={styles.contentWrapper}>
      {additionalTitle ? (
        <>
          <img src={additionalLogo} alt="logo" className={styles.additionalLogo} width={300} height={150} />
          <h3 className={styles.titleText}>{additionalTitle} <span>2023</span></h3>
        </>
      ) : (
        <img src={bannerLogo} alt="logo" className={styles.logo} width={332} height={194} />
      )}
    </div>
  </div>
);

export default Banner;
