import { serialize } from 'object-to-formdata';
import { UseFormReturn } from 'react-hook-form';

import { ErrorResponse } from 'src/types/api';

export const getFormData = (object: Record<string, any>) => {
  return serialize(object, {
    indices: true,
    dotsForObjectNotation: true
  });
};

export const parseApiErrors = (error: ErrorResponse, args?: {
  form?: UseFormReturn<any>;
  onGlobalError?: (message: string) => void;
}) => {
  if (error.status !== 422) {
    return;
  }

  error.errors.forEach((err) => {
    if (err.type === 'error' && args?.onGlobalError) {
      args.onGlobalError(err.error);
      return;
    }

    if (err.type !== 'error' && args?.form) {
      args.form.setError(err.type, {
        message: err.error,
        type: 'api-validation'
      });
      return;
    }
  });
};
