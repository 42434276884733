import { FC, ReactNode } from 'react';

import { Badge as MantineBadge } from '@mantine/core';
import { clsx } from 'clsx';

import styles from './Badge.module.scss';

export type BadgeProps = {
  children: ReactNode;
  color: 'pear' | 'green' | 'moss' | 'red' | 'gray';
  fullWidth?: boolean;
};

const Badge: FC<BadgeProps> = ({ children, color, fullWidth }) => (
  <MantineBadge
    fullWidth={fullWidth}
    classNames={{
      root: clsx(styles.root, styles[`color-${color}`])
    }}
    radius={5}
  >
    {children}
  </MantineBadge>
);

export default Badge;
