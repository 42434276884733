import { FC } from 'react';

import { Outlet } from 'react-router-dom';

import WinnersBanner from 'src/components/WinnersBanner';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';

import styles from './styles.module.scss';

const WinnerLayout: FC = () => (
  <div className={styles.wrapper}>
    <Header navbarCheckpoint={335} />
    <div className={styles.bannerWrapper}>
      <WinnersBanner />
    </div>
    <div className={styles.content}>
      <Outlet />
    </div>
    <Footer />
  </div>
);

export default WinnerLayout;
