import { FC, useState } from 'react';

import { Menu, Avatar, Group } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLogoutUserMutation } from 'src/api/user/hooks';
import { User } from 'src/api/user/types';
import { ReactComponent as ExpandMoreIcon } from 'src/assets/svg/expand-more-icon.svg';
import { ReactComponent as UserIcon } from 'src/assets/svg/user-icon.svg';
import { TNavbarProps } from 'src/components/Navbar/types';
import { Paths } from 'src/router/constants';

import styles from './UserMenu.module.scss';

type Props = {
  user: User;
} & Pick<TNavbarProps, 'navbarVariant'>;

const UserMenu: FC<Props> = ({
  user,
  navbarVariant
}) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const { mutate: logout } = useLogoutUserMutation();

  return (
    <Menu
      opened={opened}
      onChange={setOpened}
      shadow="md"
      width={180}
      position="bottom-end"
      offset={10}
      classNames={{
        dropdown: styles.dropdownContent,
        item: styles.dropdownItem,
        divider: styles.divider
      }}
    >
      <Menu.Target>
        <button>
          <Group
            spacing={8}
            className={clsx(styles.button, {
              [styles.open]: opened,
              [styles.dark]: navbarVariant === 'light'
            })}
          >
            <div className={styles.iconWrapper}>
              <ExpandMoreIcon className={styles.icon} />
            </div>
            <div className={styles.buttonText}>
              {user.first_name}
            </div>
            <Avatar
              size={24}
              radius="xl"
              classNames={{
                placeholder: styles.avatarPlaceholder
              }}
            >
              <UserIcon className={styles.avatarIcon} />
            </Avatar>
          </Group>
        </button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          icon={<UserIcon className={styles.itemIcon} />}
          component={Link}
          to={Paths.account}
        >
          {t('common.personalAccount')}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          icon={<IconLogout size={19} />}
          className={styles.dangerItem}
          onClick={() => logout()}
        >
          {t('common.logout')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default UserMenu;
