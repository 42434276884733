import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import 'src/configs/i18n';
import { router } from 'src/router/router';

import './index.scss';

const container = document.getElementById('root')!;

const root = createRoot(container);

root.render(<RouterProvider router={router} />);
