import publicApi from 'src/configs/public-api';

import { GetPhotoGalleryResponse } from './types';

export const getPhotoGallery = async (): Promise<GetPhotoGalleryResponse> => {
  return publicApi.get('/photo', {
    params: {
      page_size: 99999,
      page_number: 1
    }
  }).then((res) => res.data);
};
