export const prefixStyle = (prefix: string, style: string | number | undefined ) => {
  if (!style) {
    return '';
  }

  if (typeof style === 'number') {
    return `${prefix}${style}`;
  }

  const styleName = style[0].toUpperCase() + style.slice(1);
  return `${prefix}${styleName}`;
};
