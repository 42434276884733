import publicApi from 'src/configs/public-api';
import { FullWinnersInfo, TopWinnersPayload } from './types';

export const getTopWinnerList = async (): Promise<TopWinnersPayload[]> => {
  return publicApi.get('/winner/top').then((res) => res.data);
};

export const getAllWinnerList = async (): Promise<FullWinnersInfo> => {
  return publicApi.get('/winnerstatistics').then((res) => res.data);
};

export const getWinnerListByYear = async (year: string): Promise<FullWinnersInfo> => {
  return publicApi.get(`/winnerstatistics?year=${year}`).then((res) => res.data);
};
