import { useQuery } from '@tanstack/react-query';

import { getInnovationList } from './api';
import { InnovationType } from './types';

export const useInnovationListQuery = () => {
  return useQuery<InnovationType[]>({
    queryKey: ['innovation-list'],
    queryFn: getInnovationList
  });
};
