import { FC } from 'react';

import { Box, Flex } from '@mantine/core';
import { PDF_MIME_TYPE } from '@mantine/dropzone';
import { useMediaQuery } from '@mantine/hooks';
import { Controller, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useTicketTypesQuery } from 'src/api/ticket-types/hooks';
import { TTicketForm } from 'src/api/tickets/types';
import Button from 'src/components/Button';
import FileUpload from 'src/components/FileUpload';
import Form from 'src/components/Form';
import Select from 'src/components/Select';
import Textarea from 'src/components/Textarea';
import TextInput from 'src/components/TextInput';
import { useNormalizedItemsData } from 'src/hooks/useNormalizedItemsData';
import styles from 'src/pages/ContactUs/styles.module.scss';

type Props = {
  onSubmit: (data: TTicketForm) => void;
  form: UseFormReturn<TTicketForm>;
  disabled: boolean;
  allowSubmit?: boolean;
  submitText: string;
};

const ManageTicket: FC<Props> = ({
  onSubmit,
  form,
  disabled,
  allowSubmit = true,
  submitText
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 600px)');

  const { data: ticketTypes, isLoading: isTicketTypesLoading } = useTicketTypesQuery();

  const ticketTypesData = useNormalizedItemsData(ticketTypes);

  const handleSubmit: SubmitHandler<TTicketForm> = (data) => {
    if (allowSubmit) {
      onSubmit(data);
    }
  };

  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <Box mb={40}>
        <Box maw={{ lg: 250 }}>
          <Controller
            control={form.control}
            name="ticketType_id"
            rules={{ required: t('errorMessages.required') }}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <Select
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                data={ticketTypesData}
                label={t('formLabels.injurySuggestionType')}
                placeholder={t('placeholders.injurySuggestionType')}
                required
                disabled={disabled || isTicketTypesLoading}
              />
            )}
          />
        </Box>
      </Box>

      <Box mb={40}>
        <Controller
          control={form.control}
          name="title"
          rules={{ required: t('errorMessages.required') }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <TextInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              label={t('formLabels.injurySuggestionTitle')}
              placeholder={t('placeholders.injurySuggestionTitle')}
              required
              disabled={disabled}
            />
          )}
        />
      </Box>

      <Box mb={40}>
        <Controller
          control={form.control}
          name="description"
          rules={{ required: t('errorMessages.required') }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <Textarea
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              label={t('formLabels.injurySuggestionDescription')}
              placeholder={t('placeholders.injurySuggestionDescription')}
              required
              rows={8}
              disabled={disabled}
            />
          )}
        />
      </Box>

      <Box>
        <Controller
          control={form.control}
          name="attachemt"
          render={({
            field: { value, onChange },
            fieldState: { error }
          }) => (
            <FileUpload
              value={value}
              onChange={onChange}
              label={t('formLabels.attachFile')}
              isError={!!error}
              helperText={t('formTexts.pdf25MB')}
              accept={PDF_MIME_TYPE}
              maxSize={25 * 1000 * 1000}
              disabled={disabled}
            />
          )}
        />
      </Box>

      {allowSubmit ? (
        <Flex justify="center">
          <Box mt={50} w='100%'>
            <Button
              type="submit"
              className={styles.submitButton}
              disabled={disabled}
              fullWidth={isMobile}
            >
              {submitText}
            </Button>
          </Box>
        </Flex>
      ) : null}
    </Form>
  );
};

export default ManageTicket;
