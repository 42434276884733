import { FC } from 'react';

import { Box, Input, SimpleGrid } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Controller, UseFormReturn, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Nationality } from 'src/api/nationalities/types';
import FormAccordion from 'src/components/FormAccordion';
import Select from 'src/components/Select';
import TextInput from 'src/components/TextInput';
import { useNormalizedItemsData } from 'src/hooks/useNormalizedItemsData';
import { TCompetitionRegistrationForm } from 'src/modules/competition/types';

type Props = {
  participantIndex: number;
  form: UseFormReturn<TCompetitionRegistrationForm>;
  nationalities: Nationality[];
  isDisabled: boolean;
};

const ParticipantInfo: FC<Props> = ({
  participantIndex,
  form,
  nationalities,
  isDisabled
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 600px)');

  const nationalitiesData = useNormalizedItemsData(nationalities);

  const formState = useFormState({
    control: form.control
  });

  const participantState = form.getFieldState(
    `partcipants_data.${participantIndex}`,
    formState
  );

  return (
    <FormAccordion
      title={t('formTexts.participantInformation', { number: participantIndex + 2 })}
      isError={!!participantState.error}
    >
      <Box mb={isMobile ? 20 : 40}>
        <Input.Label required mb={6}>
          {t('formLabels.fullName')}
        </Input.Label>

        <SimpleGrid cols={isMobile ? 1 : 3} spacing={18}>
          <Controller
            control={form.control}
            name={`partcipants_data.${participantIndex}.first_name`}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                placeholder={t('placeholders.firstName')}
                required
                disabled={isDisabled}
              />
            )}
          />

          <Controller
            control={form.control}
            name={`partcipants_data.${participantIndex}.second_name`}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                placeholder={t('placeholders.middleName')}
                required
                disabled={isDisabled}
              />
            )}
          />

          <Controller
            control={form.control}
            name={`partcipants_data.${participantIndex}.last_name`}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error }
            }) => (
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                placeholder={t('placeholders.lastName')}
                required
                disabled={isDisabled}
              />
            )}
          />
        </SimpleGrid>
      </Box>

      <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 20 : 90} mb={isMobile ? 20 : 40}>
        <Controller
          control={form.control}
          name={`partcipants_data.${participantIndex}.user_identity`}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <TextInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              label={t('formLabels.idOrIqamaNumber')}
              placeholder={t('placeholders.idOrIqamaNumber')}
              required
              disabled={isDisabled}
            />
          )}
        />

        <Controller
          control={form.control}
          name={`partcipants_data.${participantIndex}.nationality_id`}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <Select
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              data={nationalitiesData}
              label={t('formLabels.nationality')}
              placeholder={t('placeholders.nationality')}
              required
              searchable
              clearable
              disabled={isDisabled}
            />
          )}
        />
      </SimpleGrid>

      <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? 20 : 90}>
        <Controller
          control={form.control}
          name={`partcipants_data.${participantIndex}.email`}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error }
          }) => (
            <TextInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error?.message}
              label={t('formLabels.email')}
              placeholder={t('placeholders.email')}
              type="email"
              required
              disabled={isDisabled}
            />
          )}
        />
      </SimpleGrid>
    </FormAccordion>
  );
};

export default ParticipantInfo;
