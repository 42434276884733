import { FC, memo, ReactNode } from 'react';

import { Box } from '@mantine/core';
import { clsx } from 'clsx';

import { TNavbarProps } from 'src/components/Navbar/types';

import styles from './styles.module.scss';

type Props = {
  children: ReactNode;
  isActive: boolean;
} & Pick<TNavbarProps, 'navbarVariant'>;

const Link: FC<Props> = ({
  children,
  navbarVariant,
  isActive
}) => (
  <Box
    className={clsx(styles.link, {
      [styles.dark]: navbarVariant === 'light',
      [styles.active]: isActive
    })}
  >
    {children}
  </Box>
);

export default memo(Link);
