import { useQuery } from '@tanstack/react-query';

import { getSupervisoryBoard } from './api';
import { SupervisoryBoardItem } from './types';

export const useSupervisoryBoardQuery = () => {
  return useQuery<SupervisoryBoardItem[]>({
    queryKey: ['supervisory-board'],
    queryFn: async () => getSupervisoryBoard().then((res) => res.data)
  });
};
